module.exports = {
  aws_bucket: 'Lecture : s3:ListBucket',
  aws_key: "ID de clé d'accès",
  aws_object: 'Lecture : s3:GetObject',
  aws_permission: 'Lecture',
  aws_secret: "Clé d'accès secrète",
  box_report_1: '<code>Inclure des ID de ressource</code>',
  box_report_2:
    "<code>Actualisez automatiquement votre rapport de coûts et d'utilisation quand des frais de mois précédents liés à des factures clôturées sont détectés.</code>",
  bucket: 'Compartiment',
  config: 'Configuration',
  container: 'Conteneur',
  container_check: 'Acces au conteneur avec cette chaine',
  help: 'Aide pour cette étape',
  help_bucket_1:
    "Pour obtenir les informations relatives au compartiment (Bucket), rendez-vous dans votre console d'administration AWS :",
  help_bucket_2: 'Ajouter ou visualiser un compartiment',
  help_bucket_3:
    "Nous vous recommandons l'utilisation de la région <b>us-east-1</b> (Virginie du nord) qui présente les meilleurs avantages tarifaires.",
  help_bucket_4:
    "La valeur attendue (ex: us-east-1) d'une région peut être retrouvée dans <code>l'url</code> du navigateur ou bien dans le menu déroulant <code>Global</code> (en haut à droite) de la console AWS.",
  help_bucket_5:
    "Si vous n'avez pas encore de compartiment au niveau de votre S3, utilisez le bouton <b>Créer un compartiment</b>.",
  help_bucket_6:
    'Pour que notre système arrive à retrouver votre compartiment, il faut obligatoirement nous indiquez la région (valeur) associée à ce compartiment.',
  help_bucket_7:
    "Pour rappel, nous avons besoin d'une permission en <b>lecure</b> sur le compartiment <code>S3</code> choisi. Retournez à la première étape en cas d'erreur sur cette partie.",
  help_connection_1:
    'Pour obtenir une chaine de connexion, rendez-vous sur votre compte Azure :',
  help_connection_2: 'Ajouter ou configurer un Cost Management',
  help_connection_3:
    "Notre application a besoin d'un rapport <b>Cost Management</b> sauvegardé sur un <b>Compte de Stockage</b> : créez ou choisissez un <code>Conteneur</code> et un <b>Annuaire</b> lors de la création du rapport.",
  help_connection_4:
    "Lorsque votre <b>Cost Management</b> est correctement créé, allez sur votre <b>Compte de Stockage</b>. Nous avons besoin de la première <code>Chaine de connexion</code> disponible depuis le menu <b>Clés d'accès</b>.",
  help_connection_5:
    'Lors de la création du <b>Cost Management</b>, vous avez choisi un nom de <code>Conteneur</code>. Nous avons besoin de connaitre ce nom. Sinon, le nom des conteneurs sont disponibles via le menu <b>conteneurs</b> depuis la section <b>Service BLOB</b>.',
  help_connector_1:
    "Le nom du connecteur est facultatif. Nous utiliserons ce nom en interne pour vous donner une meilleur visibilité. Cela n'impactera pas votre compte.",
  help_connector_2:
    'Avant de sauvegarder ce connecteur, rendez-vous sur votre compte AWS :',
  help_connector_3: 'Créer ou configurer un rapport pour Lota',
  help_connector_4:
    'Lors de la création du rapport, assurez-vous de cocher <b>obligatoirement</b> les cases :',
  help_iam_1:
    "Pour obtenir des accès API, rendez-vous dans votre console d'administration AWS :",
  help_iam_2: 'Ajouter ou configurer un utilisateur pour Lota',
  help_iam_3:
    "Notre application a besoin d'un <b>Accès par programmation</b> avec des <b>autorisations/stratégies</b> en lecture :<br /><code>AmazonS3ReadOnlyAccess</code>",
  help_iam_4:
    "Pour restreindre l'accès en lecture de manière plus spécifique, voici les <b>actions</b> minimum à définir :<br /><code>s3:GetObject</code> et <code>s3:ListBucket</code>.",
  help_iam_5:
    'Exemple de stratégie pour le compartiment <b>lota.cloud-cur</b> :',
  help_iam_6: 'Sinon pour les experts, utiliser un',
  help_iam_7:
    "associé à un compartiment (Bucket) spécifique au lieu d'un accès complet 'AmazonS3ReadOnlyAccess'.",
  iam_message:
    "Au minimum, nous avons besoin d'un accès en <b>lecture</b> sur <code>s3:GetObject</code> et <code>s3:ListBucket</code>.",
  iam_title: 'IAM (Utilisateur)',
  report: 'Rapport',
  report_download: 'Téléchargement',
  string: 'Chaine de connexion',
  string_check: 'Cohérence de la chaine',
  string_message:
    "Nous avons besoin d'une connexion à un compte Azure. Nous recommandons les comptes 'EA' pour une meilleure utilisation de notre application.",
  string_title: 'Connexion',
  warn_action:
    "Vérifier l'action de l'IAM (Json): <code>'Action': [ 's3:Get*', 's3:List*' ]</code>",
  warn_resource:
    "Vérifier la ressource de l'IAM (Json): <code>'Resource': '*'</code",
  warn_file:
    'Vos documents doivent être hébergés directement sur le <code>S3</code> (<b>Glacier</b> non pris en compte)',
  createConnector: 'créer le connecteur',
  aws: {
    introductionTitle: 'Introduction',
    introduction:
      'Afin de permettre à notre application de se connecter avec vos données de facturation, il vous faudra activer ou mettre en place :',
    introduction1:
      'Un compartiment de stockage (ou bucket) qui permettra le stockage et la lecture de vos Cost & Usage Reports.',
    introduction2:
      'Un utilisateur IAM, avec accès par programmation pour se connecter à votre compartiment',
    introduction3:
      'L’export de la facturation via la fonctionnalité CUR d’Amazon',
    introduction4:
      '(Ces configurations nécessitent un accès administrateur sur la plateforme AWS.)',
    bucket: 'Compartiment',
    bucketTitle: 'Configurez votre compartiment',
    bucketText: 'Rendez-vous dans la console AWS, dans la page <b>S3</b>.',
    bucketText1:
      'Nom du bucket : pensez à le noter car il vous sera demandé par la suite.',
    bucketText2:
      'Région : nous vous recommandons d’utiliser la région USA Est car c’est la moins chère chez Amazon (code région: us-east-1)',
    bucketLine:
      'Cliquez sur <b>Créer un compartiment</b>, puis renseignez les champs dans le dialogue de création.',
    bucketLine1:
      'Cliquez sur <b>Suivant</b>, dans la page de configuration du compartiment vous pouvez ajouter des balises. Ajoutez la balise <b>finops:lota</b> pour pouvoir suivre l’activité des exports de facturation et cliquez sur <b>Suivant</b>. ',
    bucketLine2:
      'Dans la section <b>Bloquer l’accès public</b> laisser l’option <b>Bloquer tout l’accès public</b> par défaut, cela permettra de protéger votre compartiment des intrusions !',
    bucketLine3:
      'Cliquez sur <b>Suivant</b> pour vérifier les informations de votre compartiment, et cliquez sur <b>Créer un compartiment</b> pour valider l’opération.',
    user: 'Utilisateur',
    userTitle: 'Configurez votre utilisateur',
    userText1:
      'Depuis la console AWS, rendez-vous dans la page <b>utilisateurs</b> et cliquez sur <b>ajouter un utilisateur</b>. Nommez le <b>lotacloud</b> et cochez la case <b>accès par programmation</b>.',
    userText2:
      'Dans la partie <b>Définir des autorisations</b>, cliquez sur <b>Attacher directement des stratégies existantes</b>. Cela devrait vous proposer une liste de stratégies pré-fabriquées. Dans la barre de recherche, tapez <b>S3</b> et sélectionnez ensuite dans le tableau la ligne <b>AmazonS3ReadOnlyAccess</b>. Cette stratégie va permettre à notre utilisateur <b>lotacloud</b> de se connecter par programmation à votre bucket de stockage afin de lire vos fichiers.',
    userText3:
      'Si vous souhaitez appliquer une stratégie via <b></b>l’utilisation d’un JSON</b>, voici ce que nous vous recommandons (pensez à remplacer le nom du compartiment dans la stratégie):',
    userJsonButton: 'Télécharger le JSON',
    userLine1:
      'Cliquez à présent sur <b>Suivant (balises)</b>, afin de configurer les balises de coûts. Vous pouvez ajouter une balises <b>finops:lota</b> pour suivre l’utilisation de notre utilisateur.',
    userLine2:
      'Enfin, cliquez sur <b>Suivant (vérification)</b> afin de vérifier les caractéristiques de l’utilisateur. Cliquez sur <b>Créer un utilisateur pour valider</b>.',
    costReport: 'Cost & Usage Reports',
    costReportTitle: 'Configurez vos Cost & Usage Reports',
    costReportText1:
      'Rendez-vous dans la console AWS, dans la section <b>Cost Management</b> et cliquez sur <b>Cost & Usage Reports</b> dans la partie gauche. Cliquez ensuite sur <b>Créer un rapport</b>.',
    costReportText2:
      'Renseignez un nom pour votre rapport, appelez-le par exemple <b>lotacloud</b> puis cochez la case <b>inclure les ID de ressources</b> afin de nous permettre d’obtenir un rapport avec un détail par ressources. Laissez la case <b>Actualisez automatiquement votre rapport de coûts</b> cochée par défaut. Cliquez sur <b>Suivant</b>.',
    costReportText3:
      'Vous devez configurer le compartiment S3 dans lequel sera sauvegardé votre rapport. Cliquez sur <b>configurer</b> et sélectionnez le <i>bucket</i> que nous avons créer précédemment puis cliquez sur <b>Suivant</b> avant de valider la stratégie qui permettra a AWS d’écrire sur votre compartiment.',
    costReportText4:
      'Dans la section <b>granularité temporelle</b>, cochez la case <b>quotidienne</b> puis dans la section <b>gestion des versions de rapports</b> cochez la case <b>remplacer le rapport existant</b>. Laissez le format de compression en <b>GZIP</b> par défaut et cliquez sur suivant afin de vérifier et valider les informations de votre nouveau rapport. Cliquez enfin sur <b>Vérifier et terminer</b>.',
    bucket_name: 'Nom du compartiment',
    bucket_region: 'Région du compartiment',
    IAM_Access_Key_ID: "Clé d'accès IAM",
    IAM_Secret_Key_ID: 'clé secrète IAM',
    CUR: 'Nom du rapport',
    validate: 'Validation',
    validationText:
      'Vous devez à present disposer des éléments nécessaires à l’ajout d’un connecteur AWS. Veuillez saisir ci-dessous les informations collectées aux étapes précédentes.',
  },
  google: {
    exportBilling: 'Export vers Big Query',
    serviceAccount: 'Clé de compte de service',
    validate: 'Validation',
    prerequisitesLabel: 'Prérequis',
    prerequisitesText:
      "Avant de démarrer la procédure d'exportation de vos données de facturation, veuillez vérifiez les éléments suivants :",
    prerequisite1:
      "Vous disposez de l'autorisation nécessaire pour <b>créer un projet</b> pour le stockage de vos données sur Big Query.",
    prerequisite2:
      "Vous disposez de l'autorisation nécessaire pour <b>modifier / associer le projet cible</b> avec un compte de facturation.",
    prerequisite3:
      "Vous disposez de l'autorisation nécessaire pour <b>activer les APIs</b> Big Query Data Transfer et Cloud Billing.",
    prerequisite4:
      "Vous disposez de l'autorisation nécessaire pour <b>créer un compte de service</b> pour notre application.",
    prerequisiteDocs:
      "Pour de plus amples informations sur l'exportation de vos données, l'utilisation et la facturation de Big Query, veuillez vous reporter à la documentation Google Cloud.",
    seeGoogleDocs: 'voir la documentation google cloud',
    exportBillingLabel: 'Exporter vos données de facturation',
    exportBillingText:
      "Afin de vous permettre d'analyser vos dépenses au niveau le plus fin, nous vous demandons d'activer l'export des données de facturation sur un ensemble de données Big Query. Voici en résumé les actions que vous devrez faire pour activer l'export :",
    exportBilling1:
      "Créer un projet dans lequel les données Cloud Billing seront stockées, et activer la facturation sur le projet (si ce n'est pas déjà fait)",
    exportBilling2:
      'Configurer les autorisations sur le projet et sur le compte de facturation Cloud',
    exportBilling3:
      "Activer l'API du service de transfert de données BigQuery (obligatoire pour exporter vos données de tarification)",
    exportBilling4:
      'Créer un ensemble de données BigQuery dans lequel stocker les données.',
    exportBilling5:
      "Activer l'exportation Cloud Billing des données de coût et des données de tarification à écrire dans l'ensemble de données",
    showGoogleTutorial: 'Exporter les données de facturation sur Big Query',
    exportBigQuery: 'Export vers Cloud Storage',
    exportBigQueryLabel: 'Exporter les données Big Query vers un fichier',
    exportBigQueryText:
      'Pour simplifier la récupération de vos données mais aussi pour limiter les coûts de requêtes, nous vous demandons de créer un export de vos données Big Query vers un espace de stockage Cloud Storage. Nous vous recommandons de créer un bucket spécifiquement pour cette opération. Nous vous invitons à suivre la procédure décrite dans la section suivante :',
    exportBigQueryDoc: 'voir la documentation google cloud',
    serviceAccountLabel: 'Créer une clé de compte de service',
    serviceAccountText:
      "Afin de permettre à notre application d'accéder aux données exportées précédement sur le bucket Cloud Storage, nous vous demandons de créer une clé de compte de service que nous utiliserons pour collecter les rapports. Cliquez sur le bouton ci-dessous pour accéder à la page de gestion des comptes de service.",
    serviceAccountDoc: 'voir la documentation Google Cloud',
    process: 'Procédure',
    exportBigQuery1: 'Ouvrez la page <b>BigQuery</b> dans Cloud Console.',
    exportBigQuery2:
      'Dans le panneau <b>Explorateur</b>, développez votre projet et votre ensemble de données, puis <b>sélectionnez la table qui contient vos données de facturation</b>.',
    exportBigQuery3:
      'Dans le panneau de détails, cliquez sur <b>Exporter</b> et sélectionnez <b>Exporter vers GCS</b>.',
    exportBigQuery4:
      "Pour le champ <b>Sélectionner l'emplacement Google Cloud Storage</b>, recherchez le bucket (ou compartiment) dans lequel vous souhaitez exporter les données. Nous vous rappelons qu'il est préférable de créer un bucket spécifiquement pour l'opération.",
    exportBigQuery5:
      "Pour le champ <b>Format d'exportation</b>, choisissez le format des données exportées : <b>JSON</b> (délimité par un retour à la ligne).",
    exportBigQuery6:
      'Dans le champ <b>Compression</b>, acceptez la valeur par défaut (None) ou choisissez <b>GZIP</b>.',
    exportBigQuery7: 'Cliquez sur <b>Exporter</b> pour exporter la table.',
    exportBigQueryEndTExt:
      "Pour vérifier la progression de la tâche, consultez en haut de la <b>fenêtre de navigation</b> l'historique de la tâche pour une tâche d'exportation.",
    serviceAccountPage: 'Page de gestion des comptes de service',
    serviceAccount1:
      'Dans le champ <b>Compte de service</b>, sélectionnez <b>Nouveau compte de service</b>.',
    serviceAccount2:
      'Dans le champ <b>Nom de compte de service</b>, renseignez le nom <b>lota</b>.',
    serviceAccount3:
      "Dans le champ <b>Rôle</b>, sélectionnez <b>Cloud Storage</b> puis <b>Lecteur des objets de l'espace de stockage</b>.",
    serviceAccount4:
      'Pour le <b>Type de clé</b>, sélectionnez <b>JSON</b> puis cliquez sur <b>Créer</b>. Téléchargez le fichier contenant la clé au format JSON et conservez-la en lieu sûr.',
    validateLabel: 'Félicitations !',
    validateText:
      "Vous avez terminée la procédure d'export de vos données de facturation Google Cloud. Pour valider la connexion avec notre application, il ne reste plus qu'à créer votre connecteur. En 3 étapes :",
    validateConnectorName: 'Saisissez le nom de votre connecteur :',
    validateBucketName:
      'Saisissez le nom du bucket Cloud Storage qui contient les fichiers exportés en étape 2 :',
    validateServiceAccount:
      'Téléchargez le fichier contenant la clé du compte de service que nous avons créé en étape 3 :',
    uploadJsonHere: 'Téléchargez votre fichier JSON ici',
    addGoogleCloudConnector: 'Ajouter un connecteur Google Cloud',
    success:
      'Votre connecteur a bien été créé ! Nous avons testé la connexion avec votre espace de stockage Cloud Storage et nous procéderons à un import de vos données dans les prochaines heures.',
    failure:
      "Nous n'avons pas réussi à nous connecter sur votre espace de stockage ! Veuillez vérifier les informations saisies ainsi que la validité de la clé de compte de service. Si le problème persiste, contactez-nous à support@lota.cloud.",
    introductionLabel: 'Introduction',
    introductionText:
      "L'objectif de cette procédure est de vous permettre d'exploiter vos données de facturation à travers notre plateforme. Pour obtenir un accès en lecture sur vos données de facturation Cloud Billing, vous devrez <b>activer l'exportation de vos données de facturation</b> vers BigQuery puis <b>créer un export</b> de ces données sur un espace de stockage. Enfin, pour nous permettre de nous y connecter, vous devrez nous fournir une <b>clé de compte de service</b> que nous utiliserons pour l'authentification.",
    serviceAccountKeyFile: 'Votre clé de compte de service',
    projectId: 'Project ID',
    privateKeyId: 'Private Key ID',
    clientEmail: 'Client Email',
    clientId: 'Client ID',
    serviceAccountKeyError:
      "Le fichier téléchargé n'est pas conforme, veuillez vérifier qu'il s'agit bien d'un fichier JSON et qu'il n'est pas corrompu.",
    requiredField: 'Ce champs est requis.',
    max26Characters: 'Ce champs ne doit pas dépasser 26 charactères.',
    starting: 'Démarrage',
    step: 'Étape',
  },
  oracle: {
    addOracleConnector: 'Ajouter un connecteur oracle',
    introductionLabel: 'Introduction',
    introductionText:
      "L'objectif de cette procédure est de vous permettre d'exploiter vos données de facturation OCI et d'automatiser la mise à jour de vos données dans l'interface Lota.cloud. Pour obtenir vos données, nous aurons besoin de créer un utilisateur, un groupe et de leur donner les droits nécessaire à la lecture de fichier de facturation sur l'infrastructure Oracle.",
    prerequisitesLabel: 'Prérequis',
    prerequisitesText:
      "Avant de démarrer la procédure d'exportation de vos données de facturation, veuillez vérifiez les éléments suivants :",
    prerequisite1:
      "Vous disposez des droits d'administrateur sur le compte Oracle que vous souhaitez connecter.",
    prerequisite2: "Vous disposez d'un accès sur Cloud Shell OCI.",
    scriptExecutionLabel: 'Exécution du script',
    scriptExecutionText:
      'Pour lire vos données de facturation, Lota.cloud se connecte au bucket contenant vos rapports de dépenses. Nous utiliserons un utilisateur IAM auxquels les droits spécifiques seront accordés (lecture uniquement).',
    scriptExecution1: 'Création d’un utilisateur «finops-lota».',
    scriptExecution2: 'Création d’un groupe «finops-lota-group».',
    scriptExecution3: 'Ajout de l’utilisateur «finops-lota» dans le groupe.',
    scriptExecution4:
      ' Ajout des policies au groupe (lecture des fichiers de facturation et monitoring).',
    scriptExecution5:
      ' Partage de la clé publique comme moyen d’authentification de l’utilisateur.',
    scriptExecution6: 'Sélection de la région (home region).',
    scriptExecution7:
      'Sauvegarde et affichage des informations de connexion dans la console.',
    downloadScript: 'Télécharger le script',
    scriptContentLabel: 'Contenu du script',
    scriptlaunchLabel: 'Lancement du script',
    scriptlaunchText:
      'Glisser - déposer le fichier lota-cloud-oracle-setup dans Cloud Shell précédemment téléchargé et exécutez-le en saisissant la commande suivante dans Cloud Shell : bash lota-cloud-oracle-setup',
    enterLoginDetailsLabel: 'Saisie des identifiants',
    enterLoginDetailsText:
      'Le script précédemment exécuté devrait vous renvoyer les informations de connexion sauvegardées dans le fichier lota.config. Nous utiliserons ces informations pour nous connecter au bucket de facturation Oracle Cloud.',
    cleaningLabel: 'Nettoyage',
    cleaningText:
      "Vous pouvez supprimer le script lota-cloud-oracle-setup si vous le souhaitez mais nous vous recommandons de ne pas supprimer le fichier de configuration tant que votre connecteur n'est pas complètement actif (données téléchargées).",
    requiredField: 'Ce champs est requis.',
    max26Characters: 'Ce champs ne doit pas dépasser 26 charactères.',
    validate: 'Validation',
    congratulationLabel: 'Félicitations !',
    congratulationText:
      "Vous avez terminé la procédure d'ajout d'un connecteur Oracle Cloud Infrastructure. Notre système d'import prend le relai pour importer et normaliser vos données. Vous pourrez consulter vos données dans les prochaines heures.",
    connectorName: 'Nom du connecteur',
  },
  connectionString: 'Chaine de connexion',
}
