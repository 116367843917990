module.exports = {
  alerts: 'Alertes',
  alertReport: "Rapport d'alerte",
  awsIndex: 'Amazon Web Services',
  billing: 'Facturation',
  comparator: 'Comparateur',
  create: 'Créer un connecteur',
  dashboard: 'Accueil',
  'custom-dashboard': 'Tableaux de bord',
  'inventory-empty-view': 'Inventaire de ressources',
  'inventory-detail': 'Détails de la ressource',
  tags: 'Tags',
  'tags-coverage': 'Mes étiquettes',
  'tags-dashboard': 'Synthèse',
  'tags-distribution': 'Répartition',
  'tags-configuration': 'Configuration',
  'tags.list': 'Liste des tags',
  'tags.configuration': 'Configuration des tags',
  'recommendations-list': 'Recommandations',
  recommendations: 'Recommandations',
  recommendation: 'Détails de la recommandation',
  'recommendation-group': "Détails d'un groupe de recommandation",
  optimization: 'Optimisation',
  'optimization-overview': 'Optimisation',
  'unused-resources': 'Ressources inutilisées',
  'oversized-resources': 'Ressources surdimensionnées',
  'reserved-instances': "Réservation d'instances",
  googleCloud: 'Google Cloud Platform',
  days: 'j',
  demo: 'Créer un compte',
  error: 'Erreur',
  explorer: 'Explorateur',
  budget: 'Budget',
  home: 'Accueil',
  theme: 'Thème :',
  offerAdmin: 'Version Admin',
  offerExpire: "Version d'essai expirée",
  offerExpireXs: 'Offre expirée',
  report: 'Rapport',
  settings: 'Paramètres',
  share: 'Partage',
  subscription: 'Souscrire',
  trialEndsIn: 'Essai : ',
  downloadImportStatus: 'Import de vos données...',
  normalizeImportStatus: 'Normalisation de vos données...',
  aggregateImportStatus: 'Agrégation de vos données...',
  doneImportStatus: 'Données à jour !',
  checkImportStatus: 'Vérification de vos données...',
  errorImportStatus: 'La vérification de vos données à échouée.',
  catalogConfig: 'Configuration des produits',
  catalog: 'Liste des prix',
  'catalog-public-cloud': 'Catalogue du Cloud',
  new: 'Nouveau !',
  'budget-table': 'Mes budgets',
  'budget-detail': "Détail d'un budget",
  'settings-application': 'Mon application',
  'settings-connectors': 'Mes connecteurs',
  'settings-data': 'Mes données',
  'settings-user': 'Mes utilisateurs',
  'create-user': 'Ajouter un utilisateur',
  'show-user': "Informations de l'utilisateur",
  'coming-soon': 'Bientôt disponible',
  inventory: 'Inventaire',
  budgetsGroup: 'Sélectionnez un groupe de budgets',
  overview: 'Synthèse',
  periods: 'Périodes',
  optimizations: 'Optimisations',
  parameters: 'Paramètres',
  from: 'Du',
  to: 'Au',
  allocatedExpenses: 'Dépenses allouées',
  achievedExpenses: 'Dépenses réalisées',
  targetedExpenses: 'Dépenses prévues',
  selectFilterDescription:
    'Choisissez un de vos filtres ou créez-en un nouveau',
  waitingForRenewal: 'en attente de renouvellement',
  waitingForUpdate: 'en attente de mise à jour',
  cancelCurrentActions: 'Annuler les actions en cours',
  currentActions: 'Actions en cours',
  management: 'Organisation',
  strategies: 'Stratégies',
  BestPractices: 'Bonnes pratiques',
  strategy: 'Stratégie',
  clickOnCategory: "Cliquez sur une catégorie pour l'appliquer",
  at: 'au',
  atx: 'aux',
  productsSelected: 'produits sélectionnés',
  productSelected: 'produit sélectionné',
  'explorers-cloud': 'Explorateur Cloud',
  'explorers-kubernetes': 'Kubernetes',
  'explorers-carbon': 'Carbon',
  explorers: 'Explorateurs',
  dimension: 'Dimension',
  dimensions: 'Dimensions',
  'dimensions-type': 'Mes dimensions',
  'dimension-overview': 'Dimension > Synthèse',
  'dimension-subdimensions': 'Dimension > Sous-dimensions',
  'dimension-explorers': 'Dimension > Explorateurs',
  'dimension-budgets': 'Dimension > Budgets',
  'dimension-alerts': 'Dimension > Alertes',
  'dimension-filters': 'Dimension > Filtres',
  'dimension-users': 'Dimension > Utilisateurs',
  'dimension-edit': 'Dimension > Éditer',
  'dimension-budget-create': 'Dimension > Budget > Créer',
  benefits: 'Réservations et Savings Plans',
  notAvailable: 'Non disponible',
}
