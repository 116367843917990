import moment from 'moment'

const state = () => ({
  betaMode: false,
})

const getters = {
  betaMode(state) {
    return state.betaMode
  },
}

const mutations = {
  SET_BETA_MODE(state, value) {
    state.betaMode = value
  },
}

const actions = {
  toggleBetaMode({ commit, state }) {
    commit('SET_BETA_MODE', !state.betaMode)
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
