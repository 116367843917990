module.exports = {
  active: 'Currently active offer',
  myProfile: 'My profile',
  myConnectors: 'My connectors',
  myIntegrations: 'My integrations',
  myUsers: 'My users',
  myData: 'My data',
  myPlan: 'My plan',
  myApplication: 'My Application',
  myCategories: 'My categories',
  myPayments: 'My payments',
  download: 'Download',
  normalize: 'Normalize',
  aggregate: 'Aggregate',
  upToDate: 'Data is up to date !',
  dataError: 'An error has occured ',
  contactSupport: 'Feel free to contact the support team',
  error: 'Error',
  inProgress: 'En cours',
  licenses: 'Licenses',
  licensesUsed: 'licenses used',
  neverSeen: 'Never seen',
  setUserInformation: 'Set user information',
  userInformationDescription:
    'Enter basic information about the user you want to invite in your company. The user will receive an email with a link to create a password.',
  assignLicense: 'Assign a license',
  assignLicenseDescription:
    'Select the license type you want to assign to the user. The permissions of users with a "Control" license can then be modified to allow or restrict certain actions on the application.',
  chooseALicense: 'Choose a license',
  noLicenses: 'No licenses are provisioned, please contact the support team.',
  assignPermissionSet: 'Assign a permission set',
  assignPermissionSetDescription:
    'Select the role you want to assign to the user. The permissions of users with a "Control" license can then be modified to allow or restrict certain actions on the application. Editor role can create and edit resources such as budgets and recommendations. User role may not be able to create or edit resources.',
  chooseARole: 'Choose a role',
  validate: 'Validate',
  save: 'Save',
  close: 'Close',
  slotsAvailable: 'slots available',
  userProfile: 'User profile',
  resetPassword: 'Reset password',
  deleteUser: 'Delete user',
  permissions: 'Permissions',
  permissionsDescription:
    'You can set the permissions for this user. This will allow the user to access and modify certain resources in the application. Only users with a Control license can have special permissions.',
  availableRoles: 'Available roles:',
  userRole:
    'User: default role for new users, allow access to most resources but restrict editing.',
  editorRole:
    'Editor: allow editing and creating resources such as budgets, recommendations, etc.',
  unauthorizedRoles:
    'This user cannot have special permission because it is a viewer.',
  deleteAccount: 'Delete account?',
  deleteAccountDescription:
    'Are you sure you want to delete this account? This action cannot be undone.',
  delete: 'Delete',
  profileUpdated: 'Profile updated',
  permissionsUpdated: 'Permissions updated',
  passwordResetSent:
    'A password reset link has been sent to the associated email',
  userDeleted: 'User deleted',
  myFilters: 'My filters',
  filtersListTitle: 'Your filters list',
  manageFilters: 'Manage my filters',
  filtersListDescription: 'In this section you can easily manage your filters',
  searchFilter: 'Search a filter',
  settingsApplicationTitle: 'Application Configuration',
  settingsApplicationSubtitle:
    'Enable your billing labels and configure your categories to enhance your reports.',
  tagsConfigurationSubtitle:
    'Enable your FinOps tags to use them within our application.',
  categoriesConfigurationSubtitle:
    'Create custom categories and associate your products with them to personalize your reports.',
  applicationFooterTitle: 'Have you make a modification?',
  applicationFooterSubtitle:
    'Your data will be automatically refreshed within the next 30 minutes to integrate your changes.',
  settingsConnectorsTitle: 'My connectors',
  settingsConnectorsSubtitle:
    'Add and configure the connection to the different providers.',

  settingsNewConnectorTitle: 'Create a new connection',
  settingsNewConnectorSubtitle: 'Connect your Supplier to our application.',

  connectorManagementTitle: 'Manage your connectors',
  connectorManagementSubtitle: 'Edit or delete your existing connectors.',

  settingsDataSubTitle:
    'Consult the list of data imports performed on this account.',

  scopeTitle: 'My scopes',
  scopeSubtitle: 'View, edit, and delete application scopes.',
  profileTitle: 'My Profile',
  profileSubtitle: 'Configure your user & company profile.',
  jiraSetupTitle: 'Jira Integration Configuration',
  jiraSetupSubtitle:
    'Connect your Jira projects with our tool to share recommendations and more.',
  jiraProjectIntegrationsTitle: 'My Jira projects',
  jiraProjectIntegrationsSubtitle:
    'List of projects connected to our application.',
  jiraInstallationDocumentationTitle: 'Integrate a Jira project',
  jiraInstallationDocumentationSubtitle:
    "Jira's integration with our solution enables recommendations to be dispatched directly to the teams in charge of implementing them.",
  jiraWorkflowDocumentationTitle: 'How it works ?',
  jiraWorkflowDocumentationSubtitle:
    'Understand the mechanisms and interactions of our application with your Jira projects.',
  projectKey: 'Project key',
  projectName: 'Project name',
  createdDate: 'Created date',
}
