import moment from 'moment'

const hasJiraProjectValidation = (state) => {
  if (state.jira_project.id) {
    return state.jira_assignee.id && state.jira_priority.id
  }
  return true
}
const state = () => ({
  description: '',
  priority: null,
  impact: null,
  status: 'running',
  assignee: {},
  jira_project: {},
  jira_assignee: {},
  jira_priority: {},
  due_at: moment().endOf('month').format('Y-MM-DD'),
  recommendation_category_id: null,
  savingRate: 0,
  resourcesSelection: [],
  resources_names: [],
  isConfirmationDeleteDialogOpen: false,
})

const getters = {
  recommendation_description: (state) => {
    return state.description
  },
  recommendation_jira_assignee: (state) => {
    return state.jira_assignee
  },
  recommendation_jira_priority: (state) => {
    return state.jira_priority
  },
  recommendation_priority: (state) => {
    return state.priority
  },
  recommendation_impact: (state) => {
    return state.impact
  },
  recommendation_status: (state) => {
    return state.status
  },
  recommendation_assignee: (state) => {
    return state.assignee
  },
  recommendation_jira_project: (state) => {
    return state.jira_project
  },
  recommendation_potential_savings: (state, getter) => {
    return getter.recommendation_resources_selection.length
      ? Math.round(
          getter.recommendation_resources_selection.reduce(
            (acc, cur) => (acc += cur.cost * (cur.savingRate / 100)),
            0,
          ) * 100,
        ) / 100
      : 0
  },
  recommendation_due_at: (state) => {
    return state.due_at
  },
  recommendation_category_id: (state) => {
    return state.recommendation_category_id
  },
  recommendation_target: (state, getter) => {
    return JSON.stringify({
      resources: getter.recommendation_resources_selection,
    })
  },

  recommendation_saving_rate: (state) => {
    return state.savingRate
  },

  recommendation_resources_selection: (state) => {
    return state.resourcesSelection.map((e) => {
      let savingRate = e.savingRate || state.savingRate
      return {
        ...e,
        savingRate,
        estimateSaving: Math.round(e.cost * (savingRate / 100) * 100) / 100,
        cost: Math.round(e.cost * 100) / 100,
      }
    })
  },
  recommendation_is_valid: (state) => {
    return (
      state.assignee != null &&
      state.assignee.id &&
      state.impact != null &&
      state.due_at != null &&
      state.recommendation_category_id != null &&
      state.priority != null &&
      hasJiraProjectValidation(state)
    )
  },
  recommendation_confirmation_delete_dialog_open: (state) => {
    return state.isConfirmationDeleteDialogOpen
  },

  recommendation_to_create: (state, getters) => {
    return {
      ...state,
      potential_savings: getters.recommendation_potential_savings,
      target: getters.recommendation_target,
      assignee_id: getters.recommendation_assignee.id,
      assignee_account_id:
        getters.recommendation_jira_assignee.accountId || null,
      issue_channel_id: getters.recommendation_jira_project.id,
    }
  },
}

const actions = {
  resetSelection({ commit }) {
    commit('RESET_SELECTION')
  },
  setJiraProject({ commit }, payload) {
    commit('SET_JIRA_PROJECT', payload)
  },
  setJiraAssignee({ commit }, payload) {
    commit('SET_JIRA_ASSIGNEE', payload)
  },
  setJiraPriority({ commit }, payload) {
    commit('SET_JIRA_PRIORITY', payload)
  },
  setRecommendationProperty({ commit }, payload) {
    commit('SET_RECOMMENDATION_PROPERTY', payload)
  },
  addResourceToSelection({ commit }, payload) {
    commit('ADD_RESOURCE_TO_SELECTION', payload)
  },

  removeResourceFromSelection({ commit }, payload) {
    commit('REMOVE_RESOURCE_FROM_SELECTION', payload)
  },
  modifyResourceFromSelection({ commit }, payload) {
    commit('MODIFY_RESOURCE_FROM_SELECTION', payload)
  },
  clearRecommendation({ commit }) {
    commit('CLEAR_RECOMMENDATION')
  },
  toggleConfirmationDeleteDialog({ commit }) {
    commit('TOGGLE_DELETE_DIALOG')
  },
}

const mutations = {
  SET_JIRA_PROJECT: (state, jira_project) => {
    state.jira_project = jira_project
  },
  SET_JIRA_PRIORITY: (state, jira_priority) => {
    state.jira_priority = jira_priority
  },
  SET_JIRA_ASSIGNEE: (state, jira_assignee) => {
    state.jira_assignee = jira_assignee
  },
  TOGGLE_DELETE_DIALOG: (state) => {
    state.isConfirmationDeleteDialogOpen = !state.isConfirmationDeleteDialogOpen
  },

  SET_RECOMMENDATION_PROPERTY: (state, { property, value }) => {
    state[property] = value
  },
  ADD_RESOURCE_TO_SELECTION: (state, resource) => {
    state.resourcesSelection.unshift(resource)
  },

  MODIFY_RESOURCE_FROM_SELECTION: (state, { resourceId, property, value }) => {
    const index = state.resourcesSelection.findIndex(
      (i) => i.resourceId === resourceId,
    )
    state.resourcesSelection[index][property] = value
  },

  RESET_SELECTION: (state) => {
    state.resourcesSelection = []
  },

  REMOVE_RESOURCE_FROM_SELECTION: (state, resource) => {
    const index = state.resourcesSelection.findIndex(
      (i) => i.resourceId === resource.resourceId,
    )
    return index > -1 && state.resourcesSelection.splice(index, 1)
  },
  CLEAR_RECOMMENDATION: (state) => {
    Object.assign(state, {
      description: '',
      priority: null,
      impact: null,
      status: 'running',
      assignee: {},
      due_at: moment().endOf('month').format('Y-MM-DD'),
      recommendation_category_id: null,
      savingRate: 0,
      resourcesSelection: [],
    })
  },
}

export default {
  namespaced: false,
  state,
  getters,
  actions,
  mutations,
}
