import Vue from 'vue'
import Format from './format'
import moment from 'moment'

Vue.filter('trend', (value) => {
  return Format.trend(value, 2)
})

Vue.filter('cost', (value, currency) => {
  return Format.cost(value, currency)
})

Vue.filter('monthAndYear', (value) => {
  return Format.monthAndYear(value)
})

Vue.filter('dateFromParts', (year, month) => {
  return Format.dateFromParts(year, month)
})

Vue.filter('localeDateString', (string) => {
  return Format.dateStringToLocale(string)
})

Vue.filter('date', (string) => {
  return moment(string).format('Do MMM YYYY')
})

Vue.filter('resourceId', (string) => {
  return Format.resourceId(string)
})

Vue.filter('usageQuantity', (string) => {
  return Format.usageQuantity(string)
})

Vue.filter('usageUnit', (string) => {
  return Format.usageUnit(string)
})

Vue.filter('ucFirst', (string) => {
  return Format.ucFirst(string)
})

Vue.filter('tag', (string) => {
  return string.replaceAll(`'`, '')
})
