module.exports = {
  name: 'Name',
  startDate: 'Start Date ',
  endDate: 'End Date ',
  periodicity: 'Periodicity',
  allocatedCost: 'Allocated Cost',
  realizedAndAllocatedCost: 'Realized / allocated cost',
  realizedCost: 'Realized Cost',
  reforecastedCost: 'Reforecasted Cost',
  status: 'Status',
  progress: 'Progress',
  search: 'Search',
  modify: 'Modify',
  delete: 'Delete',
  addToGroup: 'Add to a group',
  renew: 'Renew this budget',
  draft: 'Draft',
  paused: 'Paused',
  doing: 'Doing',
  done: 'Done',
  warning: 'Warning',
  confirmDelete: 'Do you really want to delete the budget',
  cancel: 'Cancel',
  close: 'Close',
  save: 'Save',
  newBudget: 'New budget',
  modifyBudget: 'Modify budget',
  moreOptions: 'More options',
  saveAsDraft: 'Save as draft',
  budgetScope: 'Budget scope',
  filterScope: 'Filter scope',
  searchFilter: 'Search a filter',
  budgetCostAllocation: 'Budget Cost Allocation',
  PredefinedBudgetCostAllocation: 'Predefined Budget Cost Allocation',
  targetAllocatedCost: 'Target Allocated Cost',
  periodAllocatedCost: 'Allocated Cost',
  budgetAllocationDescription:
    'By default, your budget allocation for each period is proportional to the number of periods.',
  periodTableDescription:
    'Select a row in the table to update the allocated budget for a period.',
  finalAllocatedCost: 'Final Allocated Cost',
  dayPeriodicity: 'Daily',
  monthPeriodicity: 'Monhtly',
  yearPeriodicity: 'Yearly',
  validateAllAllocatedCost: 'Validate all periods',
  allocatedCostTargetDiff: ' vs target',
  realizedCostVsAllocatedCost: 'Realized vs Allocated',
  periodTitle: 'Budget adjustment per periods',
  period: 'Period',
  detailTableTitle: 'Periods detail',
  enableNotifications: 'Notify me by e-mail of the updates of the budget',
  budgetBalance: 'Realized vs target up to now',
  forcatedExpenses: 'Forcasted final amount ',
  budgetTrend: 'Budget trend',
  forecastedCosts: 'Forecasted costs',
  ended: 'ended',
  budgetTotallyConsumedIn: 'The budget will be totally consumed in ',
  budgetTotallyConsumedOn: 'The budget will be totally consumed on ',
  monthEarlier: 'month earlier',
  daysEarlier: 'days earlier',
  inProgress: 'in progress',
  upcoming: 'up coming',
  description: 'Description',
  addDescription: 'Add a description',
  seeInExplorer: 'See in explorer',
  modifyDescription: 'Modify the description',
  shareBudget: 'Share this budget',
  firstname: 'First name',
  lastname: 'Last name',
  email: 'E-mail',
  copy: 'Copy to Clipboard',
  copied: 'has been copied to clipboard!',
  failedCopy: 'Copy to clipboard failed',
  authorizedUsers: 'Authorized users list',
  allowReading: 'Allow reading for some users ',
  readOnly: 'Read only',
  createANewViewer: 'Create a new user',
  noAuthorizedUsers: 'No authorized users',
  nameIsRequired: 'Name is required',
  emailIsRequired: 'E-mail is required',
  emailMustBeValid: 'E-mail must be valid',
  viewerNotCreated: 'Viewer has not been created',
  emailAlreadyInUse: 'The email is already in use',
  filterMonth: 'Filter by month',
  trend: 'Trend',
  from: 'From',
  to: 'To',
  year: 'Year',
  responsible: 'Responsible',
  noUserFound: 'No user found',
  modifyResponsible: 'Modify responsible',
  dateCantBeUpdated:
    'The start and end dates as well as the periodicity of the budget cannot be modified after its creation.',
  exportYourBudgets: 'Export your budgets',
  exportOptions: 'Export Options',
  exportBudgetsOnly: 'Export budgets only (CSV)',
  exportWithPeriods: 'Export periods only (CSV)',
  exportWithFilters: 'Export with filters',
  delimiter: 'Delimiter',
  decimalSeparator: 'Decimal Separator',
  dot: 'Dot .',
  comma: 'Comma ,',
  tilde: 'Tilde ~',
  semicolon: 'Semicolon ;',
  warningOption:
    'Warning, you have chosen the same symbol as delimiter and decimal separator !',
  currentMonth: 'This month',
  lastMonth: 'Last month',
  lastThreeMonth: '3 months',
  lastSixMonth: '6 months',
  thisYear: 'This year',
  lastYear: 'Last year',
  nextYear: 'Next year',
  last2Years: 'Last two years',
  selectTimeRange: 'Select some dates',
  noBudgets: "You don't have a budget as of now.",
  noBudgetGroups: "You don't have a budget group yet",
  loadingBudgets: 'Loading budgets...',
  loadingGroups: 'Loading groups...',
  loadingText: 'Loading...',
  budgets: 'Budgets',
  scopeBudgets: 'scoped budgets',
  dimensionBudgets: 'dimension budgets',
  budgetsGroups: 'Budgets Groups',
  periodSettings: 'Periods settings',
  reforecastPeriods: 'Reforecast periods',
  reforecastedAt: 'Budget reforecasted on ',
  createdAt: 'Budget created on ',
  afterReforecast: 'after reforecast',
  OK: 'All green',
  WATCH: 'Budget to watch',
  ALERT: 'Budget overspending!',
  consumption: 'Total consumption',
  negativeDifference: 'Remaining amount',
  positiveDifference: 'Exceeding of',
  negativeDifferenceDescription: 'to be consumed until',
  positiveDifferenceDescription: 'until',
  percentageDifferenceDescription:
    'Enter a percentage difference between the first and the last period',
  on: 'on',
  optimizableDescription: 'Optimizable',
  metricDescrition: 'with metric',
  allocated: 'allocated',
  responsibleTitle: 'Select a responsible user.',
  responsibleDescription:
    'The responsible person will receive an email inviting him to take this budget in charge.',
  autoFilter: '*Automatically created according to selected filters ',
  filter: 'Filter',
  dateDescription: 'select a start and end date for your budget.',
  periodicityDescription:
    'The periodicity is used to calculate the number of periods associated with your budget.',
  descriptionDescription: 'You can add a description to your budget.',
  filterName: 'Filter name',
  summary: 'Budget summary',
  dates: 'Dates',
  shareText:
    'By sharing this budget to other users in your company, they will be able to access it and see the budget. However, this does not allow them to modify it.',
  edit: 'Click to edit',
  percentageDescription:
    ' Allocate a percentage of the overall budget to be smoothed over the periods',
  customCursorChartDescription: 'Adjust periods by dragging chart columns',
  checkBoxCustomCart: 'Respect the total allocated amount',
  modificationsDone: 'Modifications done',
  valueIsRequired: 'Value is required',
  invalidNumber: 'Invalid value',
  periodDescription: 'Click on an allocated cost to modify it',
  consommationDescription: 'Consumption',
  vsAllocated: 'Targeted vs allocated',
  exceeding: 'Exceeding',
  remaining: 'Remaining',
  forecasted: 'Forecasted',
  budgetsGroup: 'Budgets group',
  budgetsGroupDescription: 'You can add this budget to a budgets group',
  noBudgetsGroup: 'No budgets group selected',
  groupName: 'Group name',
  renewalProfilCheckbox: 'Keep parent budget spend profile',
  or: 'Or',
  budgetProfile: 'choose a reference budget',
  noBudgetProfile: '',
  budgetStart: 'Start of the budget',
  budgetEnd: 'End of the budget',
  noScopeSelected: 'No filter selected',
  budget: 'budget',
  selected: 'selected',
  profils: 'Profils',
  linear: 'Linear',
  upward: 'Upward',
  downward: 'Downward',
  linearProfile: 'Linear profile',
  customizableProfile: 'Customizable profile',
  existingBudget: 'From another budget',
  registeredBudget: 'Registered budget!',
  targetBudget: 'Target budget',
  budgetSelect: 'Please select a budget',
  getBudgetProfile: 'Do you want to retrieve the budget profile',
  allocatedAmount: 'Allocated amount',
  budgetSummary: 'Budget summary',
  budgetSelected: 'Budget selected',
  budgetsSelected: 'Budgets selected',
  budgetSave: 'Budget recording in progress',
  linkBudget:
    'Keep the budget filter or replace it with the dimension filter ?',
  origin: 'Origin',
  dimension: 'Dimension',
}
