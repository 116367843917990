module.exports = {
  _01: 'january',
  _02: 'february',
  _03: 'march',
  _04: 'april',
  _05: 'may',
  _06: 'june',
  _07: 'july',
  _08: 'august',
  _09: 'september',
  _10: 'october',
  _11: 'november',
  _12: 'december',
}
