module.exports = {
  unattached_disk: 'Unattached disk',
  old_snapshot: 'Old snapshot',
  unused_load_balancer: 'Unused load balancer',
  unused_security_group: 'Unused security group',
  unused_vm: 'Unused Virtual Machine',
  reservable_instance: 'Reservable instance',
  unused_nat_gateway: 'Unused NAT Gateway',
  oversized_cpu:
    'Maximum CPU usage is lower than 40% in average over the last 30 days',
  unused_cpu:
    'Maximum CPU usage is lower than 5% for 95% of the measures over the last 30 days',
  unattached_disk_description: 'Apply to a disk found as Unattached.',
  old_snapshot_description: 'Apply to any snapshot older than 3 months.',
  unused_load_balancer_description:
    'Apply to a Load Balancer found as Unattached.',
  unused_security_group_description:
    'Apply to a Security Group found as Unattached. ',
  unused_vm_description:
    'Apply to a Virtual Machine using less than 5% of CPU in average over the last 30 days.',
}
