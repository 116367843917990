const state = () => ({
  themes: [],
  selectedTheme: {},
})

const getters = {
  themes: (state) => state.themes,
  selectedTheme: (state) => state.selectedTheme,
}

const actions = {
  setThemes({ commit }, themes) {
    commit('SET_THEMES', themes)
  },
  selectTheme({ commit }, theme) {
    commit('SET_SELECTED_THEME', theme)
  },
}

const mutations = {
  SET_THEMES(state, themes) {
    state.themes = themes
  },
  SET_SELECTED_THEME(state, theme) {
    state.selectedTheme = theme
  },
}

export default {
  state,
  getters,
  mutations,
  actions,
}
