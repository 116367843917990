module.exports = {
  1: 'Total amount of budget : {A} has exceeded',
  2: 'Total amount of budget : {A} has exceeded {B}',
  3: 'Allocated amount of budget : {A} for the period has exceeded',
  4: 'Budget : {A} consumes faster than expected ',
  5: 'Current period for budget : {A} consumes faster than expected',
  6: 'Tag {A} has exceeded {B}',
  7: "Resources with tag {A} don't have tag {B}",
  8: "Some resources don't have tag {A}",
  day: 'today',
  week: 'this week',
  month: 'this month',
  year: 'this year',
}
