import Vue from 'vue'
import Highcharts from 'highcharts'
import xrange from 'highcharts/modules/xrange'
import treemap from 'highcharts/modules/treemap'
import heatmap from 'highcharts/modules/heatmap'
import treegraph from 'highcharts/modules/treegraph'
import loadMap from 'highcharts/modules/map.js'
import ExportingHighcharts from 'highcharts/modules/exporting'
import Organization from 'highcharts/modules/organization'
import draggablePoint from 'highcharts/modules/draggable-points'
import HighchartsMore from 'highcharts/highcharts-more'
import HighchartsVue from 'highcharts-vue'
import Sankey from 'highcharts/modules/sankey'

Vue.use(HighchartsVue, { tagName: 'charts' })

xrange(Highcharts)
treemap(Highcharts)
treegraph(Highcharts)
heatmap(Highcharts)
loadMap(Highcharts)
ExportingHighcharts(Highcharts)
draggablePoint(Highcharts)
HighchartsMore(Highcharts)
Sankey(Highcharts)
Organization(Highcharts)
