module.exports = {
  compute: 'Compute',
  database: 'Database',
  storage: 'Storage',
  network: 'Network',
  services: 'Provider Services',
  others: 'Others',
  applications: 'Applications',
  web: 'Web Hosting',
  categoriesConfiguration: 'Categories configuration',
  categoriesUpdate: 'Run update categories',
}
