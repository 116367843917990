module.exports = {
  tagKey: 'Key',
  tagValue: 'Value',
  provider: 'Provider',
  product: 'Product',
  resourceId: 'Resource ID',
  cost: 'Cost Without Tax',
  resourceCount: 'Resources count',
  tagList: 'Tags list',
  noTag: 'No tag',
  tagCost: 'Tagged cost',
  noTagCost: 'Untagged cost',
  taggedResources: 'Tagged resources',
  noTagResources: 'Untagged resources',
  tagCovering: 'Tag covering',
  seeAllTags: 'See all',
  inDetail: 'tags in detail',
  listChartTitle: 'Cost distribution',
  configuration: 'Configuration',
  enable: 'Enable',
  disable: 'Disable',
  availableTags: 'Available tags',
  enabledTags: 'Enabled tags',
  tagEnabledDescription:
    'The enabled tags are the ones you want to appear in your billing. If no tag is activated, the application will display all tags by default. As soon as you have enabled one or more tags, you will only see the activated tags in your data.',
  tagAvailableDescription:
    'The enabled tags are the ones you can enable to appear in our application. If no tag is activated, the application will display all tags by default. As soon as you have enabled one or more tags, you will only see the activated tags in your data.',
  noEnabledTagText: 'No tag is enabled for the moment',
  noAvailableTagText: 'No tag is available for the moment',
  searchPlaceHolder: 'Search',
  loadingText: 'Loading in progress...',
  noResultsText: 'No results for ',
  pageTitle: 'enable / disable tags',
  updateData: 'Update',
  mustUpdateData:
    'To apply the new configuration, we must update your data. It must be done within a few hours.',
  dataSuccessfullyUpdated: 'Your data has been successfully updated !',
  noAvailableTags: 'No available tag',
  enableSelectedTags: 'Enable selected tags',
  disableSelectedTags: 'Disable selected tags',
  caseSensitiveSearch: 'case sensitive',
  available: 'Tags Disponibles',
  enabled: 'Tags Activés',
  refreshDatabase: 'Refresh your data',
  dataUpToDate: 'data is up-to-date',
  dashboardTitle: 'Summary',
  tagsCount: 'Tags count',
  taggedResourceCount: 'Tagged resources',
  notTaggedResourceCount: 'Not tagged resources',
  mediumCoveringRate: 'Medium covering rate',
  coveringRate: 'Covering rate',
  top5ExpensesPerTag: 'Top 5 expenses per tag',
  newTags: 'New tags',
  firstSeen: 'Appeared',
  since: 'Since',
  resourcesCount: 'Resources count',
  totalExpenses: 'Total expenses',
  appearedOn: 'Appeared on',
  tagAlerts: 'Not dismissed alerts',
  detail: 'Tag detail',
  mediumCovering: 'Medium coverage',
  evolutionOfExpenses: 'evolution of expenses',
  listOfResources: 'List of resources',
  listOfUsages: 'List of usages',
  distribution: 'Tags Distribution',
  from: 'from',
  to: 'to',
  allTags: 'All the tags',
  filteredTags: 'Only following tags',
  taggedCost: 'Tagged cost',
  notTaggedCost: 'Not tagged cost',
  coverageTarget: 'Coverage target',
  averageCoverage: 'Average coverage',
  coverage: 'Tag coverage',
  list: 'Tag list',
  sumOfExpenses: 'Sum of expenses',
  coverageRate: 'Coverage Rate',
  filters: 'Filters',
  mustSelect: 'You must select at least one filter',
  noTagPeriod: 'No tagged cost for this period',
  tagsConfiguration: 'Tags configuration',
  tagsUpdate: 'Run tags update',
  activateByTagKey: 'Activate tags by key',
  autoEnableTag: 'Enable all values',
  noExpenses: 'This tag has no expenses for the selected period',
  percentTagsCoverage: 'Tagged spends',
  percentTagsNotCoverage: 'Untagged spends',
  insufficientTagsCoverage: 'Your tagging coverage rate is insufficient :',
  excellentTagsCoverage: 'Your tagging coverage rate is excellent :',
  averageCoveringRatePerMonths: 'Tagging coverage rate per month',
  loadingCoveringChart: 'Loading your tagging coverage chart...',
  internalTags: 'Internal tags',
  providersTags: 'Provider tags',
  strategies: 'Strategies',
  dateUndefined: 'Undefined',
  strategyDeleted: 'The strategy was successfully deleted.',
  strategyDeletedError: 'An error occurred while deleting the strategy.',
  strategyName: 'Name of your strategy',
  name: 'Name',
  activityPeriod: 'Activity period',
  definePeriod: 'Define a period when your strategy will be active.',
  strategyStart: 'Start of strategy',
  strategyEnd: 'End of strategy',
  noPeriod:
    "If you don't set a period, your strategy will be active all the time.",
  selectResource: 'Please select at least one resource.',
  scope: 'Scope',
  scopeSubtitle: 'Choose a scope to target your resources.',
  resources: 'Resources',
  resourceSubtitle: 'Select the resources that will integrate your strategy.',
  path: 'Path',
  createLotaTag: 'Create a Lota tag',
  lotaTags: 'Your Lota Tags',
  lotaTagsSubtitle: 'View and organize your internal tags.',
  strategyCreate: 'Create a strategy',
  key: 'Key',
  value: 'Value',
  tagDeleted: 'The tag was successfully deleted.',
  tagDeletedError: 'An error occurred while deleting the tag.',
  strategyCreated: 'The strategy was successfully created.',
  lotaTagCreated: 'The Lota tag was successfully created.',
  lotaTagCreate: 'Create a Lota tag',
  fieldRequired: 'This field is required.',
  tagsProvider: 'Your tags provider',
  tagsProviderSubtitle: 'View and organize your provider tags.',
  undefinedProvider: 'Undefined',
  updateResources: 'Update resources',
  fromTo: 'Strategy active from',
  strategyFrom: 'Strategy active from',
  strategyTo: 'Strategy active to',
  strategyPermanent: 'Strategy permanently active',
  updatedResources: 'The resources were successfully updated.',
  tagManagement: 'Tag management',
  tagManagementSubtitle: 'Create, organize your tags and strategies.',
  goFurther: 'Go further?',
  goFurtherSubtitle:
    'Consult our best practices guide to learn more about managing your tags.',
  errorUpdate: 'An error occurred while updating.',
  selectedResources: 'Selected resources',
  resource: 'Resource',
}
