module.exports = {
  savings: 'Réduction en ',
  type: 'Type de recommandation',
  potentialSavings: 'Estimation des économies réalisables',
  summaryPotentialSavings: 'Estimation des économies réalisables',
  description: 'Description',
  descriptionDescription:
    'Indiquez une description pour améliorer la prise en charge de votre recommandation.',
  otherParameters: 'Autres paramètres',
  otherParametersDescription:
    "Indiquez la priorité et estimez l'impact pour améliorer la prise en charge de votre demande et son exécution.",
  targetResources: 'Sélection des ressources (facultatif)',
  targetResourcesDescription:
    'Sélectionnez des ressources pour indiquer à la personne assignée les ressources impactées par la recommandation. Vous pouvez ajouter une note qui apparaîtra avec votre recommandation une fois celle-ci transmise.',
  dueDate: "Date d'échéance",
  dueDateDescription:
    "La date d'échéance est la date limite à laquelle vous souhaitez faire appliquer une recommandation. Lorsqu'une recommandation n'est pas appliquée ou traitée dans les délais impartis, vous recevez une notification par e-mail.",
  assignee: 'Assigné(e)',
  assigneeTitle: 'Sélectionnez un utilisateur assigné.',
  assigneeDescription:
    "La personne assignée va recevoir un e-mail l'invitant à prendre en charge cette recommendation. Elle sera notifiée des changements impactant la recommandation tant que celle-ci n'a pas été appliquée. Par ailleurs, un e-mail vous sera envoyé si la personne assignée change le statut de la recommandation.",
  activities: {
    recent: 'Activités récentes',
    recommendation_create: 'a créé la recommandation #**ID**',
    recommendation_delete: 'a supprimé la recommandation #**ID**',
    recommendation_update: 'a modifié la recommandation #**ID**',
    recommendation_status_update:
      'a changé le statut de la recommandation #**ID** à **PROPERTY**',
    recommendation_assignee_update: 'a réassigné la recommandation #**ID** ',
    recommendation_priority_update:
      'a changé la priorité de la recommandation #**ID** à **PROPERTY**',
    recommendation_check: 'a vérifié la recommandation #**ID**',
    recommendation_impact_update:
      "a changé l'impact de la recommandation #**ID** à **PROPERTY**",
    'recommendation_due-date_update':
      "a changé la date d'échéance de la recommandation #**ID** à **PROPERTY**",
  },
  toAddNewResourcesYouMustCreateNewRecommendation:
    'Pour ajouter une ressource à la liste, nous vous invitons à créer une nouvelle recommandation.',
  applied: 'Appliquée',
  appliedRecommendations: 'Appliquées',
  checked: 'Vérifiée',
  checkedRecommendations: 'Vérifiées',
  postponed: 'Repoussée',
  rejected: 'Rejetée',
  running: 'En cours',
  normal: 'Normale',
  important: 'Importante',
  urgent: 'Urgente',
  low: 'Bas',
  medium: 'Moyen',
  high: 'Haut',
  critical: 'Critique',
  list: 'Liste de vos recommandations',
  potentialEarns: 'Gain mensuel estimé',
  finalEarns: 'Gain mensuel final',
  currentlyRunning: "En cours d'application",
  deleteDialogText: 'Voulez-vous supprimer cette recommandation ?',
  deleteDialogSubText:
    'Les données de cette recommandation ainsi que son activité seront définitivement supprimés.',
  groupDeleteDialogText: 'Voulez-vous supprimer ce groupe ?',
  groupDeleteDialogSubText:
    'les recommendations du groupe ne seront pas supprimées',
  resources: 'Ressources et services concernés',
  createdAt: 'Créée le',
  createdAtText: '**DATE** par',
  status: 'Statut',
  impact: 'Impact',
  priority: 'Priorité',
  savingRate: 'Réduction',
  resourceName: 'Nom de la ressource',
  notes: 'Notes',
  cost: 'Coût sur la période',
  estimateSaving: 'Économies potentielles',
  author: 'Auteur(e)',
  category: 'Catégorie',
  group: 'Groupes de recommandations',
  name: 'Nom',
  groupName: 'Nom du groupe',
  groupCreatedAt: 'Créé le',
  groupRecommendations: 'Recommandations du groupe',
  detach: 'Retirer du groupe',
  groupNameDescription: 'Indiquez le nom du groupe de recommandations.',
  saveGroup: 'Enrgistrer le groupe',
  saveGroupDescription:
    'Cliquez pour enregistrer le groupe de recommandations.',
  chose: 'Choix des recommandations',
  choseDescription:
    'Sélectionnez les recommandations que vous souhaitez ajouter au groupe.',
  attach: 'Ajouter à un groupe',
  delete: 'Supprimer',
  noGroup: "Vous n'avez pas encore de groupes de recommandations.",
  check: 'Vérifier',
  finalSavings: 'Économies finales',
  authorOnly: "*uniquement disponible l'auteur de cette recommandation",
  shareText:
    "En partageant cette recommandation avec d'autres utilisateurs de votre entreprise, ils pourront y accéder et voir la recommandation. Cependant, cela ne leur permet pas de la modifier.",
  recommendationTableSubtitleStart:
    'Pour ajouter une recommandation, utilisez la page',
  optimizationPage: 'Optimisation',
  recommendationTableSubtitleEnd: 'et sélectionnez les ressources à optimiser.',
  verifiedRecommendations: 'Recommandations vérifiées',
  notAppliedRecommendations: 'Recommandations non appliquées',
  summary: 'Synthèse',
  selectGroup:
    'Séléctionnez un groupe dans le tableau ci-dessous et cliquez sur enregistrer.',
  jiraIntegration: 'Integration Jira',
  jiraIntegrationDescription:
    "Selectionnez votre projet Jira, et choisissez les utilisateurs qui recevront le ticket de recommendation. L'état de la recommendation sera automatiquement mis à jour avec l'état du ticket Jira. ",
  project: 'Projet',
  jiraUser: 'Utilisateur Jira',
  jiraStatus: 'Statut Jira',
  cancelled: 'Annulée',
}
