module.exports = {
  select: 'Veuillez selectionner un groupe',
  addTheBudget: 'Voulez-vous ajouter le budget ',
  toTheGroup: 'au groupe ',
  modifyName: 'Modifier le nom du groupe',
  revokeText: 'Voulez vous retirer le budget :',
  ofGroup: 'du groupe :',
  globalTrend: 'tendance globale',
  budgetList: 'Liste des budgets associés',
  revokeFromGroup: 'Retirer du groupe',
  summary: 'Récapitulatif',
  newBudgetGroup: 'nouveau groupe de budgets',
  name: 'Nom du groupe',
  budgetList: 'Liste des budgets',
  selectTwo: 'Sélectionnez au moins 2 budgets pour créer un groupe.',
  selectedBudgets: 'Budgets selectionnés',
  deleteText: 'Voulez-vous supprimer ce groupe de budget ?',
  deleteSubText: "Les budgets à l'intérieur ne seront pas supprimés.",
  deleteButton: 'Supprimer ce groupe',
  chartTitle: 'Repartition des budgets',
  chartTitlePeriods: 'Evolution du groupe par periodes',
  periodicityWarningTitle: ' Le budget ne peut être sélectionné.',
  allocatedCost: 'Montant alloué',
  realizedCost: 'Montant dépensé',
  usedUp: 'Consommé',
  someNotReforecast:
    "* Le groupe contient des budgets dont le montant n'as pas été réévalué (le montant alloué est pris en compte à la place).",

  periodicityWarningText:
    'Seuls les budgets à périodicité mensuelle peuvent être groupés',
}
