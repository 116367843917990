module.exports = {
  dimensions: {
    discover: 'Découvrir les Dimensions',
    discoverDescription:
      "Qu'est-ce que les Dimensions ? Pourquoi les utiliser ? Découvrez cette fonctionnalité innovante à travers notre article.",
    create: 'Créer une dimension',
    createDescription:
      "Comment créer une dimension ? Comment associer un filtre ? Laissez-vous guider par notre guide pas-à-pas en moins d'une minute.",
  },
}
