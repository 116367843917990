module.exports = {
  _action_succeed: 'Action realized successfuly',
  _actions: 'Actions',
  _alert_level: 'Scan',
  _alert_list: 'Alerts list',
  _alert_name: 'Alert name',
  _alert_rule: 'Alert rule',
  _alert_value: 'Gap',
  _back: 'Back',
  _category: 'Category',
  _checkboxMail: 'Recieve my alerts by mail',
  _create: 'Create',
  _create_alert: 'Create alert',
  _defineADate: 'Please define an expiration date for your alert',
  _delete: 'Delete',
  _doing: 'Doing',
  _done: 'Done',
  _end_date: 'End date',
  _filters: 'Filters',
  _keepAlertUp: 'Clic if you want to define an expiration date',
  _location: 'Target',
  _manage_alert: 'Manage alerts',
  _modify: 'Modify',
  _modify_alert: 'Modify alert',
  _period: 'End',
  _picker_in_menu: 'Pick date',
  _product: 'Product',
  _provider: 'Provider',
  _region: 'Region',
  _resource: 'Resource',
  _scope: 'Scope',
  _scope_value: 'Target Search',
  _search: 'Research an alert',
  _standby: 'Stand By',
  _status: 'Status',
  _update: 'Update',
  alertMe: 'Alert me when the cost of',
  alertMeDay: 'over one day exceeds',
  alertMeMonth: 'over the week exceeds',
  alertMeWeek: 'over the month exceeds',
  alertNameMobile: 'Alert name : ',
  alertRuleMobile: 'Alert rule : ',
  alertRuleValueMobile: 'Superior to',
  alertStatusMobile: 'Alert status : ',
  alertTargetMobile: 'target : ',
  alertValueMobile: 'Gap : ',
  deleteAlert: 'Delete the alert',
  doing: 'Doing',
  done: 'Done',
  editAlert: 'Modify the alert',
  endDateMobile: 'End date : ',
  expired: 'Expired',
  explorer: 'Create alert on cost explorer',
  graph: 'Show chart',
  graphNoData: 'No data for the moment',
  graphTarget: 'Alert',
  graphTitle: 'Evolution',
  graphValue: 'Value',
  moreOption: 'More options',
  pauseAlert: 'Pause',
  rule_percentage: 'Superior (Percentage)',
  rule_superior: 'Superior (Value)',
  scan: 'Scan',
  scheduled: 'Scheduled',
  start: 'Start',
  startAlert: 'Activate the alert',
  superior: 'Monthly higher amount',
  superiorDay: 'Daily higher amount',
  superiorWeek: 'Weekly higher amount',
}
