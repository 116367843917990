module.exports = {
  dimensions: {
    discover: 'Discover the Dimensions',
    discoverDescription:
      'What are the Dimensions? Why use them? Discover this innovative feature through our article.',
    create: 'Create a dimension',
    createDescription:
      'How to create a dimension? How to use filters ? Follow our step-by-step guide and get started within a minute.',
  },
}
