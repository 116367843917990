import HttpClient from '@/services/base'

export default class DataScope extends HttpClient {
  constructor() {
    super()
  }

  async list(source = null) {
    return await this.request({
      url: '/data-scopes?source=' + (source ? `${source}` : 'cloud'),
      method: 'GET',
      data: null,
    })
  }

  async get(id) {
    return await this.request({
      url: `/data-scopes/${id}`,
      method: 'GET',
      data: null,
    })
  }

  async create(payload) {
    return await this.request({
      url: `/data-scopes`,
      method: 'POST',
      data: payload,
    })
  }

  async delete(id) {
    return await this.request({
      url: `/data-scopes/${id}`,
      method: 'DELETE',
      data: null,
    })
  }

  async update(id, payload) {
    return await this.request({
      url: `/data-scopes/${id}`,
      method: 'POST',
      data: payload,
    })
  }

  async assignToUser(scopeId, userId) {
    return await this.request({
      url: `/data-scopes/${scopeId}/assignToUser/${userId}`,
      method: 'POST',
      data: null,
    })
  }
}
