module.exports = {
  data_view: 'Dépenses du ',
  budget: 'Budget du ',
  recommendation: 'Recommandation',
  BudgetTile: 'Budget',
  RecommendationTile: 'Recommandation',
  AlertTile: 'Alerte',
  ExplorerTile: 'Explorateur',
  SectionTile: 'Section',
  BudgetConsumptionTrend: 'Consommation du budget (montant)',
  BudgetDifferenceTrend: 'Différence entre le montant réalisé et alloué',
  BudgetDonutTrend: 'Consommation du budget (camembert)',
  BudgetPercentageTrend: 'Consommation du budget (pourcentage)',
  ExplorerDataTable: 'Tableau de dépenses (top 10)',
  ExplorerTimeChart: 'Évolution des dépenses',
  ExplorerTotal: 'Coût total (hors taxe)',
  ExplorerDistributionChart: 'Répartition des dépenses',
  ExplorerMapChart: 'Répartition géographiques des dépenses',
  ExplorerTagCoveringSummary: 'Indices de couverture des étiquettes',
  ExplorerMultiscopeChart: 'Répartition des dépenses',
}
