module.exports = {
  name: {
    cluster_id: 'cluster',
    namespace: 'espace de nom',
    pod: 'pod',
    container: 'conteneur',
    labels: 'labels',
    controller_name: 'contrôleur',
    controller_kind: 'type de contrôleur',
  },
  header: {
    explorer: 'Explorateur',
  },
  chart: {
    title: 'Coût par',
    search: 'Rechercher un',
  },
  table: {
    title: 'Liste des',
    headers: {
      name: 'Nom',
      clusterId: 'Nom du cluster',
      namespace: 'Espace de nom',
      labels: 'Labels',
      pod: 'Pod',
      container: 'Conteneur',
      cost: 'Coût',
    },
  },
  kpi: {
    clustersAndPodsCount: 'Nombre de clusters / pods',
    averageNodesUtilization: 'Utilisation moyenne des nœuds',
    averageCostPerPods: 'Coût moyen par pod',
  },
  filters: {
    createFilterTitle: 'Choisir les critères de filtrage',
  },
}
