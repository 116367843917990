module.exports = {
  thisMonthRange: 'ce mois-ci',
  lastMonthRange: 'le mois dernier',
  last7DaysRange: 'les 7 derniers jours',
  thisWeekRange: 'cette semaine',
  lastWeekRange: 'la semaine dernière',
  last6MonthsRange: 'les 6 derniers mois',
  thisYearRange: 'cette année',
  last30DaysRange: 'les 30 derniers jours',
  customizedRange: 'personnalisée',
  short_thisMonthRange: 'Mois en cours',
  short_lastMonthRange: 'Mois dernier',
  short_last7DaysRange: '7 derniers jours',
  short_thisWeekRange: 'Semaine en cours',
  short_lastWeekRange: 'Semaine dernière',
  short_last6MonthsRange: '6 derniers mois',
  short_thisYearRange: 'Année en cours',
  short_last30DaysRange: '30 derniers jours',
  short_lastYearRange: "L'année dernière",
  short_nextYearRange: "L'année prochaine",
  short_nextMonthRange: 'Le mois prochain',
  short_customizedRange: 'Personnalisée',
  update_charts: 'Mise à jour des données',
  from: 'Du',
  to: 'au',
}
