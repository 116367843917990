module.exports = {
  alert: 'Gestion des alertes',
  budget: 'Administration de budgets',
  buy: 'Souscrire',
  byLota: 'Par lota.cloud',
  control: 'Pack Control',
  comparator: 'Comparateur',
  connector: 'Connecteurs Cloud illimités',
  buyDone: 'Pack Starter activé',
  price: 'Prix Hors Taxe, soit 58.80 € TTC.',
  starter: 'Starter',
  viewer: 'Viewer',
  free: 'Gratuit',
  control_excl: '200 € H.T.',
  control_incl: '* 240 € T.T.C.',
  starter_excl: '100 € H.T.',
  starter_incl: '* 120 € T.T.C.',
  dashboard: 'Tableau de bord',
  disctionary: "Gestion d'un disctionnaire produit",
  explorer: 'Explorateur',
  billing: 'Historisation des données',
  share: "Partager l'explorateur",
  starter: 'Pack Starter',
  tag: 'Etiquettes',
  description: {
    starter:
      'Découvrez et utilisez les principaux outils du FinOps. Recherchez et Visualisez vos données Cloud en quelques cliques. Créer une vue simplifiée et partagez cette expérience avec les métiers.',
    control:
      "Maitrisez l'ensemble des outils FinOps en modifiant les schémas de pensées traditionnels. Imposez une pratique de référence pour les utilisateurs de Cloud.",
  },
  price: {
    starter: 'Prix Hors Taxe, soit 120 € TTC.',
    control: 'Prix Hors Taxe, soit 240 € TTC.',
  },
}
