module.exports = {
  1: 'Le montant total alloué au budget: {A} est dépassé',
  2: 'Le montant total alloué au budget : {A} a dépassé {B}',
  3: 'Le montant alloué au budget : {A}  pour la période a été dépassé',
  4: 'Le budget : {A} consomme plus vite que prévu.',
  5: 'La période en cours du budget {A} consomme plus vite que prévu.',
  6: 'L’étiquette {A} a dépassé le montant {B}',
  7: "Les ressources portant l'étiquette {A} ne portent pas l'étiquette {B}",
  8: "Des ressources ne portent pas l'étiquette {A}",
  day: 'ce jour',
  week: 'cette semaine',
  month: 'ce mois',
  year: 'cette année',
}
