module.exports = {
  savings: 'Savings',
  type: 'Recommendation type',
  potentialSavings: 'Estimate of possible savings',
  summaryPotentialSavings: 'Estimate of possible savings',
  description: 'Description',
  descriptionDescription: 'Define a description',
  groupDeleteDialogText: 'Do you want to delete this group?',
  groupDeleteDialogSubText: "related recommendation won't be deleted.",
  otherParameters: 'Other parameters',
  otherParametersDescription:
    'Indicate the priority and estimate the impact to improve the handling of your request and its execution.',
  targetResources: 'Resource selection (optional)',
  targetResourcesDescription:
    'Select resources to tell the assignee which resources are impacted by the recommendation. You can add a note that will appear with your recommendation once it has been sent.',
  dueDate: 'Due date',
  dueDateDescription:
    'The due date is the deadline by which you want to enforce a recommendation. When a recommendation is not implemented or addressed within the specified time, you will receive an email notification.',
  assignee: 'Assignee',
  assigneeTitle: 'Select an assigned user',
  assigneeDescription:
    'The assigned person will receive an email inviting them to support this recommendation via a limited interface. They will be notified of changes impacting the recommendation as long as it has not been applied. In addition, an e- email will be sent to you if the assigned person changes the status of the recommendation.',
  activities: {
    recent: 'Recent activities',
    recommendation_create: 'created the recommendation #**ID**',
    recommendation_delete: 'deleted the recommendation #**ID**',
    recommendation_update: 'modified the recommendation #**ID**',
    recommendation_status_update:
      'changed the status of the recommendation #**ID** to **PROPERTY**',
    recommendation_assignee_update:
      'changed the assignee of the recommendation #**ID** ',
    recommendation_priority_update:
      'changed the priority of the recommendation #**ID** to **PROPERTY**',
    recommendation_check: 'checked the recommendation #**ID**',
    recommendation_impact_update:
      'changed the impact of the recommendation #**ID** to **PROPERTY**',
    'recommendation_due-date_update':
      'changed the due date of the recommendation #**ID** to **PROPERTY**',
  },
  toAddNewResourcesYouMustCreateNewRecommendation:
    'To add a resource to the selection, you must create a new recommendation.',
  applied: 'Applied',
  appliedRecommendations: 'Applied',
  checked: 'Checked',
  checkedRecommendations: 'Checked',
  postponed: 'Postponed',
  rejected: 'Rejected',
  running: 'Running',
  normal: 'Normal',
  important: 'Important',
  urgent: 'Urgent',
  low: 'Low',
  medium: 'Medium',
  high: 'High',
  critical: 'Critical',
  list: 'List of your recommendations',
  potentialEarns: 'Monthly potential savings',
  finalEarns: 'Monthly final savings',
  currentlyRunning: 'Running',
  deleteDialogText: 'Do you want to remove this recommendation?',
  deleteDialogSubText:
    'The data of this recommendation as well as its activity will be permanently deleted.',
  resources: 'Resources and services concerned',
  createdAt: 'Created at',
  createdAtText: 'Created at **DATE** by',
  status: 'Status',
  impact: 'Impact',
  priority: 'Priority',
  savingRate: 'Savings in %',
  resourceName: 'Resource name',
  notes: 'Notes',
  cost: 'Cost on the period',
  estimateSaving: 'Potential savings',
  author: 'Author',
  category: 'Category',
  group: 'Recommendation groups',
  name: 'Name',
  groupName: 'Group name',
  groupCreatedAt: 'Created at',
  groupRecommendations: 'Recommendations of the group',
  detach: 'Detach from group',
  groupNameDescription: 'Choose a name for the recommendation group',
  saveGroup: 'Save the group',
  saveGroupDescription: 'Click here to save the group.',
  chose: 'Recommendation choice',
  choseDescription:
    'Select the recommendation you want to attach to the group.',
  attach: 'Attach to a group',
  delete: 'Delete',
  noGroup: "You don't have any group yet.",
  check: 'Check',
  finalSavings: 'Final savings',
  authorOnly: '*Only available for the author of the recommendation.',
  shareText:
    'By sharing this recommendation to other users in your company, they will be able to access it and see the recommendation. However, this does not allow them to modify it.',
  recommendationTableSubtitleStart: 'To create a recommendation, go to page',
  optimizationPage: 'Optimization',
  recommendationTableSubtitleEnd: 'and select the resources to optimize.',
  verifiedRecommendations: 'Verified recommendation',
  notAppliedRecommendations: 'Not applied recommendations',
  summary: 'Summary',
  selectGroup: 'Select a group in the list below and click on save.',
  jiraIntegration: 'Jira Integration',
  jiraIntegrationDescription:
    'Select your Jira project, and choose the users who will receive the recommendation ticket. The status of the recommendation will automatically update with the status of the Jira issue.',
  project: 'Project',
  jiraUser: 'Jira user',
  jiraStatus: 'Jira status',
  cancelled: 'Cancelled',
}
