module.exports = {
  sentry: {
    userFeedback: {
      buttonLabel: 'Donner un avis',
      submitButtonLabel: 'Envoyer un avis',
      cancelButtonLabel: 'Annuler',
      formTitle: 'Que pensez-vous de cette page ?',
      nameLabel: 'Nom',
      namePlaceholder: 'Votre nom',
      emailLabel: 'Email',
      emailPlaceholder: 'Votre email',
      messageLabel: 'Description',
      messagePlaceholder: 'Votre avis à propos de cette fonctionnalité...',
      successMessageText: 'Merci pour votre avis !',
    },
  },
}
