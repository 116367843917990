module.exports = {
  select: 'Select a group',
  addTheBudget: 'Do you want to add the budget ',
  toTheGroup: 'to the group ',
  modifyName: 'Modify the name of the group',
  revokeText: 'Do you want to revoke budget :',
  ofGroup: 'from groupe :',
  globalTrend: 'Global trend ',
  budgetList: 'List of associated budgets ',
  revokeFromGroup: 'Revoke from group',
  summary: 'Summary',
  newBudgetGroup: 'new budget group ',
  name: 'Group name',
  budgetList: 'list of the budgets',
  selectTwo: 'Select at least 2 budgets to create a group',
  selectedBudgets: 'selected budgets',
  deleteText: 'Do you want to delete this budget group ?',
  deleteSubText: 'The budgets inside will not be deleted.',
  deleteButton: 'delete this group',
  chartTitle: 'Distribution of budgets',
  chartTitlePeriods: 'Group evolution per periods',
  periodicityWarningTitle: "the budget can't be selected",
  allocatedCost: 'Allocated cost',
  realizedCost: 'Realized cost',
  usedUp: 'Used up',
  someNotReforecast:
    '* The group contain some budgets where the amount was not reforecasted (the allocated cost is used instead).',
  periodicityWarningText:
    'Seuls les budgets à périodicité mensuelle peuvent être groupés',
}
