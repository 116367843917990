module.exports = {
  and: 'et',
  dashboard: 'Tableaux de bord',
  dashboardSubtitle:
    'Les tableaux de bord vous permettent de suivre, informer et analyser vos dépenses Cloud de manière structurée.',
  addNewTile: 'ajouter une tuile',
  deleteDashboard: 'supprimer ce tableau de bord',
  dashboardName: 'Nom du tableau de bord',
  dashboardNameDescription:
    "Nommez votre tableau de bord pour mieux l'identifier :",
  dashboardDescription: 'Description',
  dashboardCreatedBy: 'Créé par',
  dashboardDescriptionDescription:
    'Ajoutez une description pour définir le contenu de votre tableau de bord (facultatif) :',
  emptyDashboardTitle: 'Votre tableau de bord est vide ?',
  emptyDashboardDescription:
    'Cliquez sur le bouton ci-dessous pour ajouter votre première tuile.',
  emptyDashboardActionTitle: 'Ajouter une tuile',
  saveDashboard: 'Enregistrer le tableau de bord',
  saveDashboardDescription:
    'Sauvegarder le tableau de bord avant de pouvoir y ajouter du contenu :',
  createNewTile: 'Créer une nouvelle tuile',
  createNewDashboard: 'Créer un tableau de bord',
  noDashboardFound: "Vous n'avez pas encore de tableau de bord ?",
  noResultSearch: 'Aucun résultat trouvé pour votre recherche.',
  noDashboardDescription: 'Pas de description',
  confirmDashboardDeletion:
    'Voulez-vous vraiment supprimer ce tableau de bord ?',
  noDashboardFoundDescription:
    'Cliquez sur le bouton ci-dessous pour créer un tableau de bord.',
  selectGranularity: 'Sélectionnez la granularité',
  selectGroupBy: 'Sélectionnez le regroupement',
  selectGroupByDescription:
    'Choisissez la clef sur laquelle regrouper vos données :',
  selectFilter: 'Sélectionnez un ou plusieurs filtres',
  selectFilterDescription:
    'Choisissez un ou plusieurs de vos filtres ou créez-en un nouveau :',
  selectBudget: 'Sélectionnez un budget',
  selectBudgetDescription:
    'Choisissez un de vos budgets dans la liste suivante :',
  selectRecommendation: 'Sélectionnez une recommandation',
  selectRecommendationDescription:
    'Choisissez une de vos recommandations dans la liste suivante :',
  selectAlert: 'Sélectionnez une alerte',
  selectAlertDescription:
    'Choisissez une de vos alertes dans la liste suivante :',
  selectDataToDisplay: 'Sélectionnez les données',
  selectDataToDisplayDescription:
    'Choisissez les valeurs à afficher dans la liste suivante :',
  selectRepresentationMode: 'Sélection la représentation',
  selectRepresentationModeDescription:
    'Choisissez un mode de représentation parmis les choix suivants :',
  selectPeriodicity: 'Sélectionnez la périodicité',
  selectPeriodicityDescription:
    'Définissez la période sur laquelle sera calculée la tuile',
  preview: 'Prévisualisation',
  previewDescription:
    "Vérifiez l'affichage et les informations de la tuile avant le l'ajouter à un tableau de bord :",
  addToDashboards: 'Ajouter au(x) tableau(x) de bord',
  addToDashboardsDescription:
    'Choisissez un ou plusieurs tableaux de bord dans lesquels ajouter cette tuile',
  currentBudgetPeriod: 'Période en cours',
  previousBudgetPeriod: 'Période précédente',
  totalBudgetPeriod: 'Toutes les périodes',
  selectSection: 'Sélectionnez une section',
  selectSectionDescription:
    'Sélectionnez un type de section que vous souhaitez inclure dans votre tableau de bord',
  sectionWithTitle: 'Titre et texte',
  selectSectionColor: 'Définissez la couleur de la section',
  selectSectionColorDescription:
    'Personnalisez votre tableau de bord en définissant une couleur pour cette section',
  sectionTitlePlaceholder: 'Titre de la section',
  sectionDescriptionPlaceholder: 'Description de la section',
  sectionTitle: 'Saisissez un titre',
  sectionTitleDescription: 'Renseignez un titre pour la section (obligatoire)',
  sectionDescription: 'Ajoutez une description',
  sectionDescriptionDescription:
    'Renseignez une description pour la section (optionnel)',
  name: 'Nom du dashboard',
  nameDescription:
    "Donnez un nom à votre nouveau tableau de bord afin de mieux l'identifier",
  tileName: 'Nom de la tuile',
  tileNameDescription:
    "Donnez un nom à votre nouvelle tuile afin de mieux l'identifier :",
  tileDescription: 'Description de la tuile',
  tileDescriptionDescription: 'Décrivez le contenu de votre tuile (facultatif)',
  saveTile: 'Enregistrer la tuile',
  saveTileDescription:
    'Cliquez sur le bouton ci-dessous pour sauvegarder la tuile, elle sera automatiquement ajoutée aux tableaux de bords sélectionnés.',
  searchDashboards: 'Rechercher un tableau de bord',
  totalCost: 'Coût total (HT)',
  tagCoveringLabel: 'Couverture des étiquettes',
  totalCostWithTag: 'Dépenses étiquetées',
  totalCostWithoutTag: 'Dépenses non étiquetées',
  countResourcesIdWithTags: 'Nombre de ressources étiquetées',
  countResourcesIdWithoutTags: 'Nombre de ressources non étiquetées',
  percentTagsCovered: 'Pourcentage de couverture',
  percentTagsUncovered: 'Pourcentage non couvert',
  trendUp: 'Vos dépenses sont en hausse par rapport au mois précédent ',
  trendDown: 'Vos dépenses sont en baisse par rapport au mois précédent',
  trendNone: 'Voici un aperçu de vos dépenses cumulées',
  homeTotalTitle: 'Dépenses totales',
  yourOptimizations: "L'optimisation de vos ressources",
  yourOptimizationsDescription:
    "Faîtes des économies dès aujourd'hui en utilisant nos scanners",
  yearlySavings: 'Économies potentielles sur 12 mois',
  unusedResources: 'Ressources inutilisées',
  oversizedResources: 'Ressources surdimensionnées',
  reservableResources: 'Ressources à réserver',
  totalResources: 'Total',
  availableSoon: 'Bientôt disponible !',
  from: 'Du',
  to: 'au',
  periodicity: {
    this_month: 'Mois en cours',
    last_month: 'Le mois dernier',
    last_7_days: 'Les 7 derniers jours',
    last_30_days: 'Les 30 derniers jours',
    last_3_months: 'Les 3 derniers mois',
    last_6_months: 'Les 6 derniers mois',
    this_year: 'Cette année',
    last_year: "L'année dernière",
    total: 'Total',
  },
  groupBy: {
    provider: 'Par fournisseur',
    category: 'Par catégorie',
    product: 'Par produit',
    usage_account_id: 'Par compte',
    region: 'Par région',
    tags: 'Par étiquette',
    resource_id: 'Par ressource',
    usage_type: 'Par consommation',
  },
  granularity: {
    day: 'Par jour',
    week: 'Par semaine',
    month: 'Par mois',
    quarter: 'Par trimestre',
    year: 'Par année',
  },
  chartTypes: {
    lineChart: 'Ligne',
    columnChart: 'Colonne',
    areaChart: 'Aire',
  },
  maxFilters: 'Vous avez atteint le nombre maximum de filtres',
  noData: 'Aucune donnée disponible',
  cost: 'Coût',
}
