module.exports = {
  unattached_disk: 'Disque',
  old_snapshot: 'Sauvegarde / Snapshots',
  reservable_instance: 'Instance réservable',
  unused_load_balancer: 'Load balancer',
  unused_security_group: 'Groupe de sécurité',
  unused_nat_gateway: 'NAT Gateway inutilisé',
  oversized_cpu:
    'Utilisation maximale du CPU en moyenne inférieure à 40% sur les 30 derniers jours',
  unused_cpu:
    'Utilisation maximale du CPU inférieure à 5% pour 95% des mesures sur les 30 derniers jours',
  unused_vm: 'Machine virtuelle',
  unattached_disk_description: 'Appliqué à un disque non attaché.',
  old_snapshot_description:
    'Appliqué à une sauvegarde vieille de plus de 3 mois.',
  unused_load_balancer_description: 'Appliqué à un Load Balancer non attaché.',
  unused_security_group_description:
    'Appliqué à un groupe de sécurité non attaché. ',
  unused_vm_description:
    "Appliqué à une machine virtuelle dont le CPU ne dépasse pas les 5% d'utilisation maximale moyenne sur les 30 jours passés.",
}
