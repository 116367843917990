const recommandationRoutes = [
  {
    name: 'recommendations',
    path: 'recommendations',
    component: () => import('../views/RecommendationsLayout.vue'),
    children: [
      {
        name: 'recommendations-list',
        path: '',
        meta: { permission: 'recommendation.index' },
        component: () =>
          import('../components/recommendations/RecommendationsList.vue'),
      },
      {
        name: 'recommendations-create',
        path: 'create',
        meta: { permission: 'recommendation.store' },
        component: () =>
          import('../components/recommendations/RecommendationsCreate.vue'),
      },
      {
        name: 'recommendation',
        path: ':id',
        meta: { permission: 'recommendation.show' },
        component: () =>
          import('../components/recommendations/Recommendation.vue'),
      },
      {
        name: 'recommendation-group',
        path: 'group/:id',
        meta: { permission: 'recommendation.index' },
        component: () =>
          import('../components/recommendations/RecommendationGroup.vue'),
      },
    ],
  },
]

export default recommandationRoutes
