module.exports = {
  thisMonthRange: 'This month',
  lastMonthRange: 'Last month',
  last7DaysRange: 'Last 7 days',
  thisWeekRange: 'This week',
  lastWeekRange: 'Last week',
  last6MonthsRange: 'Last 6 months',
  thisYearRange: 'This year',
  last30DaysRange: 'Last 30 days',
  customizedRange: 'Customized',
  short_thisMonthRange: 'Month to date',
  short_lastMonthRange: 'Last Month',
  short_last7DaysRange: '7 days',
  short_thisWeekRange: 'W',
  short_lastWeekRange: 'Last week',
  short_last6MonthsRange: 'Last 6 Months',
  short_thisYearRange: 'Year to date',
  short_lastYearRange: 'Last year',
  short_nextYearRange: 'Next year',
  short_nextMonthRange: 'Next month',
  short_last30DaysRange: '30 days',
  short_customizedRange: 'Customized',
  from: 'From',
  to: 'to',
}
