import moment from 'moment'

const locale = localStorage.getItem('lang') || 'fr'
moment.locale(locale)

const dateFormat = 'YYYY-MM-DD'
const fullDateFormat = 'dddd Do MMMM YYYY'

const state = () => ({
  isMiniVariant: true,
  drawer: false,
  currencies: ['USD', 'EUR', 'GBP', 'INR'],
  parameters: {
    dashboard: {
      categories: {
        visible: true,
      },
      tags: {
        visible: true,
      },
      products: {
        visible: true,
      },
    },
  },
  timeRange: {
    startDate: moment().startOf('months').format(dateFormat),
    endDate: moment().endOf('months').format(dateFormat),
    title: 'thisMonthRange',
  },
  customizedRange: {
    startDate: null,
    endDate: null,
    title: 'customizedRange',
  },
  thisMonthRange: {
    startDate: moment().startOf('month').format(dateFormat),
    endDate: moment().endOf('month').format(dateFormat),
    title: 'thisMonthRange',
  },
  lastMonthRange: {
    startDate: moment()
      .subtract(1, 'months')
      .startOf('month')
      .format(dateFormat),
    endDate: moment().subtract(1, 'months').endOf('month').format(dateFormat),
    title: 'lastMonthRange',
  },
  last7DaysRange: {
    startDate: moment().subtract(7, 'days').format(dateFormat),
    endDate: moment().format(dateFormat),
    title: 'last7DaysRange',
  },
  last30DaysRange: {
    startDate: moment().subtract(1, 'months').format(dateFormat),
    endDate: moment().format(dateFormat),
    title: 'last30DaysRange',
  },
  lastWeekRange: {
    startDate: moment().subtract(7, 'days').startOf('week').format(dateFormat),
    endDate: moment().subtract(7, 'days').endOf('week').format(dateFormat),
    title: 'lastWeekRange',
  },
  last6MonthsRange: {
    startDate: moment().subtract(6, 'months').format(dateFormat),
    endDate: moment().format(dateFormat),
    title: 'last6MonthsRange',
  },
  thisYearRange: {
    startDate: moment().startOf('year').format(dateFormat),
    endDate: moment().format(dateFormat),
    title: 'thisYearRange',
  },
  lastYearRange: {
    startDate: moment().subtract(1, 'year').startOf('year').format(dateFormat),
    endDate: moment().subtract(1, 'year').endOf('year').format(dateFormat),
    title: 'lastYearRange',
  },
  today: moment().format(dateFormat),
})

const getters = {
  drawer: (state) => state.drawer,
  isMiniVariant: ({ isMiniVariant }) => {
    return isMiniVariant
  },
  startDateFormatted: ({ timeRange }) => {
    return moment(timeRange.startDate).format(fullDateFormat)
  },
  endDateFormatted: ({ timeRange }) => {
    return moment(timeRange.endDate).format(fullDateFormat)
  },
  startDate: ({ timeRange }) => {
    return timeRange.startDate
  },
  endDate: ({ timeRange }) => {
    return timeRange.endDate
  },
  dateDifference: ({ timeRange }) => {
    return moment(timeRange.startDate).diff(timeRange.endDate, 'days')
  },
  timeRanges: (state) => {
    return [
      state.last30DaysRange,
      state.last7DaysRange,
      state.lastWeekRange,
      state.lastMonthRange,
      state.thisMonthRange,
      state.last6MonthsRange,
      state.thisYearRange,
      state.lastYearRange,
    ]
  },
  timeRange: (state) => {
    return state.timeRange
  },
}

const actions = {
  setDrawer({ commit }, payload) {
    commit('setDrawer', payload)
  },
  setTimeRange: ({ commit }, timeRange) => {
    commit('setTimeRange', timeRange)
  },
  setTimeRangeByTitle: ({ commit, getters }, title) => {
    // set default range to current month when budgets are loaded
    const timeRangeIndex = getters.timeRanges.findIndex(
      (tr) => tr.title === title,
    )

    const timeRange =
      getters.timeRanges[timeRangeIndex > -1 ? timeRangeIndex : 0]

    // mutate state
    commit('setTimeRange', timeRange)
  },
  setStartDate: ({ commit }, payload) => {
    commit('setStartDate', payload)
  },
  setEndDate: ({ commit }, payload) => {
    commit('setEndDate', payload)
  },
  toggleParameter: ({ commit }, { page, parameter }) => {
    commit('TOGGLE_PARAMETER', page, parameter)
  },
}

const mutations = {
  setDrawer: (state, payload) => {
    state.drawer = payload
  },
  TOGGLE_PARAMETER: (state, page, parameter) => {
    state.parameters[page][parameter] = !state.parameters[page][parameter]
  },
  toggleMiniVariant(state) {
    state.isMiniVariant = !state.isMiniVariant
  },
  setTimeRange(state, timeRange) {
    state.timeRange = timeRange
  },
  setStartDate(state, startDate) {
    state.timeRange.startDate = startDate
    state.timeRange.title = 'customizedRange'
  },
  setEndDate(state, endDate) {
    state.timeRange.endDate = endDate
    state.timeRange.title = 'customizedRange'
  },
  setTimeRangeTitle(state, timeRangeTitle) {
    state.timeRange.title = timeRangeTitle
  },
  resetTimeRange(state) {
    state.timeRange = {
      startDate: moment().subtract(30, 'days').format(dateFormat),
      endDate: moment().format(dateFormat),
      title: 'customizedRange',
    }
  },
}

export default {
  namespaced: false,
  state,
  getters,
  actions,
  mutations,
}
