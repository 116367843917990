module.exports = {
  data_view: 'Expenditure of the ',
  budget: 'Budget of the ',
  recommendation: 'Recommendation',
  BudgetTile: 'Budget',
  RecommendationTile: 'Recommendation',
  AlertTile: 'Alert',
  ExplorerTile: 'Cost Explorer',
  SectionTile: 'Section',
  BudgetConsumptionTrend: 'Budget consumption (amount)',
  BudgetDifferenceTrend: 'Difference between realised and allocated amount',
  BudgetDonutTrend: 'Budget consumption (pie chart)',
  BudgetPercentageTrend: 'Budget consumption (percentage)',
  ExplorerDataTable: 'Expenditure table (top 10)',
  ExplorerTimeChart: 'Expenditure trends',
  ExplorerTotal: 'Total cost (excluding tax)',
  ExplorerDistributionChart: 'Breakdown of expenditure',
  ExplorerMapChart: 'Geographical distribution of expenditure',
  ExplorerTagCoveringSummary: 'Label coverage indices',
  ExplorerMultiscopeChart: 'Breakdown of expenses',
}
