module.exports = {
  _01: 'janvier',
  _02: 'février',
  _03: 'mars',
  _04: 'avril',
  _05: 'mai',
  _06: 'juin',
  _07: 'juillet',
  _08: 'août',
  _09: 'septembre',
  _10: 'octobre',
  _11: 'novembre',
  _12: 'décembre',
}
