export default {
  namespaced: false,
  state: () => ({
    message: '',
    color: '',
    snackbar: false,
    icon: '',
  }),
  getters: {
    message: (state) => state.message,
    color: (state) => state.color,
    snackbar: (state) => state.snackbar,
    icon: (state) => state.icon,
  },
  mutations: {
    setMessage: (state, message) => {
      state.message = message
    },
    setColor: (state, color) => {
      state.color = color
    },
    setIcon: (state, icon) => {
      state.icon = icon
    },
    setSnackbar: (state, snackbar) => {
      state.snackbar = snackbar
    },
  },
  actions: {
    pushMessage: (
      { commit },
      { message, color = 'primary', icon = 'mdi-information' },
    ) => {
      commit('setColor', color)
      commit('setMessage', message)
      commit('setIcon', icon)
      commit('setSnackbar', true)
    },
  },
}
