module.exports = {
  'as-south': 'Asie du sud',
  'pa-jp': 'Pacific Japan',
  'na-east': 'North East America',
  'pa-au': 'Pacific Australia',
  'sa-east': 'South East America ',
  'as-southeast': 'South East Asia',
  'na-central': 'Central North America',
  'as-east': 'East Asia',
  'na-west': 'North West America',
  'eu-central': 'Central Europe',
  'eu-west': 'West Europe',
  'cn-north': 'North China',
  'eu-north': 'North Europe',
  'cn-southeast': 'South East China',
  'af-south': 'South Africa',
  'as-west': 'West Asia',
  'na-south': 'South North America',
  'sa-west': 'South West America',
  'ca-ca': 'Canada',
  'eu-east': 'East Europe',
}
