module.exports = {
  alerts: 'Alerts',
  alertReport: 'Alert report',
  awsIndex: 'Amazon Web Services',
  billing: 'Billing',
  comparator: 'Comparator',
  create: 'Create a connector',
  dashboard: 'Home',
  tags: 'Tags',
  'inventory-empty-view': 'Resources inventory',
  'inventory-detail': 'Resource details',
  'tags-coverage': 'My tags',
  'tags-dashboard': 'Summary',
  'tags-configuration': 'Configuration',
  'tags-distribution': 'Tags distribution',
  'tags.list': 'Tags list',
  'tags.configuration': 'Tags setup',
  'recommendations-list': 'Recommendations',
  recommendations: 'Recommandations',
  recommendation: 'Recommendation details',
  'recommendation-group': 'Recommendation group details',
  optimization: 'Optimization',
  'optimization-overview': 'Optimization',
  'unused-resources': 'Unused resources',
  'oversized-resources': 'Oversized resources',
  'reserved-instances': 'Reserved instances',
  days: 'd',
  demo: 'Create an account',
  googleCloud: 'Google Cloud Platform',
  error: 'Error',
  explorer: 'Explorer',
  budget: 'Budget',
  home: 'Home',
  theme: 'Theme :',
  offerAdmin: 'Admin offer',
  offerExpire: 'Your trial period ended',
  offerExpireXs: 'Offer expired',
  report: 'Report',
  settings: 'Settings',
  share: 'Share',
  subscription: 'Subscription',
  trialEndsIn: 'Trial: ',
  downloadImportStatus: 'Importing your data...',
  normalizeImportStatus: 'Normalizing your data...',
  aggregateImportStatus: 'Aggregating your data...',
  doneImportStatus: 'Data is updated !',
  checkImportStatus: 'Checking your data...',
  errorImportStatus: 'An issue occurred while checking data.',
  catalogConfig: 'Products configuration',
  catalog: 'Price list',
  'catalog-public-cloud': 'Public Cloud Catalog',
  new: 'New !',
  'budget-table': 'My budgets',
  'budget-detail': 'Details of one budget',
  'custom-dashboard': 'Custom dashboards',
  'settings-application': 'My application',
  'settings-connectors': 'My connectors',
  'settings-data': 'My data',
  'settings-user': 'My users',
  'create-user': 'Invite a user',
  'show-user': 'User informations',
  'coming-soon': 'Coming soon',
  inventory: 'Inventory',
  overview: 'Overview',
  periods: 'Periods',
  optimizations: 'Optimizations',
  parameters: 'Parameters',
  from: 'From',
  to: 'To',
  AllocatedExpenses: 'Allocated expenses',
  achievedExpenses: 'Achieved expenses',
  targetedExpenses: 'Targeted expenses',
  budgetsGroup: 'Select a budgets group',
  selectFilterDescription: 'Choose one of your filters or create a new one',
  waitingForRenewal: 'waiting for renewal',
  waitingForUpdate: 'waiting for update',
  cancelCurrentActions: 'Cancel current actions',
  currentActions: 'Current actions',
  yourTags: 'Management',
  strategies: 'Strategies',
  BestPractices: 'Good practices',
  strategy: 'Strategy',
  clickOnCategory: 'click on a category to apply it ',
  at: 'at the',
  atx: 'at the',
  productsSelected: 'selected products',
  productSelected: 'selected product',
  kubernetes: 'Kubernetes',
  'explorers-cloud': 'Cloud Explorer',
  'explorers-kubernetes': 'Kubernetes',
  'explorers-carbon': 'Carbon',
  explorers: 'Explorers',
  dimension: 'Dimension',
  dimensions: 'Dimensions',
  'dimensions-type': 'My dimensions',
  'dimension-overview': 'Dimension > Overview',
  'dimension-subdimensions': 'Dimension > Subdimensions',
  'dimension-explorers': 'Dimension > Explorers',
  'dimension-budgets': 'Dimension > Budgets',
  'dimension-alerts': 'Dimension > Alerts',
  'dimension-filters': 'Dimension > Filters',
  'dimension-users': 'Dimension > Users',
  'dimension-edit': 'Dimension > Edit',
  'dimension-budget-create': 'Dimension > Budget > Create',
  notAvailable: 'Not available',
}
