module.exports = {
  print: 'Print',
  title: 'Print graph, table or both',
  action: 'Download PDF',
  close: 'Close',
  checkbox: 'Chart',
  checkboxTable: 'Table',
  titleDocumentPdf: 'Report of expenses from',
  productCostReport: 'Cost per product',
  resourceIdCostReport: 'Cost per resource ID',
}
