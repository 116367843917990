import UserService from '@/services/user'

const service = new UserService()

const state = () => ({
  id: null,
  active: null,
  address: '',
  admin: null,
  billing: null,
  company: '',
  company_id: null,
  connector_count: null,
  email: '',
  firstname: '',
  lang: '',
  last_login_date: '',
  lastname: '',
  offer: null,
  permissions: [],
  roles: [],
  offerDayRemaining: null,
  pinned_dimension_id: null,
})

const getters = {
  user: (state) => {
    return state
  },
  hasPermission: (state) => (permission) => {
    return state.permissions.includes(permission)
  },
  hasRole: (state) => (role) => {
    return state.roles.find((r) => r.name === role)
  },
}

const mutations = {
  setUserInfo: (state, { info, value }) => {
    state[info] = value
  },
  setUserId: (state, payload) => {
    state.id = payload
  },
  setUserDimensionId: (state, id) => {
    state.pinned_dimension_id = id
  },
  setUserLang: (state, payload) => {
    state.lang = payload
  },
}

const actions = {
  async getUser({ commit }) {
    const response = await service.getInfos()
    if (response.code === 200) {
      Object.keys(UserStore).forEach((k) => {
        this.$store.commit('setUserInfo', { info: k, value: UserStore[k] })
      })
    }
    commit('setUserInfo', response.data)
  },
  setUserDefaultDimension: ({ commit }, id) => {
    commit('setUserDimensionId', id)
  },
  setUserLang: ({ commit }, lang) => {
    commit('setUserLang', lang)
  },
}

export default {
  namespaced: false,
  state,
  getters,
  mutations,
  actions,
}
