/**
 * Format Helper ---------------------
 * This class contains helper functions
 * made to format any type of data LotA may
 * want to display ------------------------
 */

import Icons from '@/icons'
import moment from 'moment'

// set global local moment
const locale = localStorage.getItem('lang') || 'fr'
moment.locale(locale)

class Format {
  locale = ''

  constructor() {
    this.locale = locale
    this.icons = Icons
  }

  /**
   * Format a number into a currency with correct symbol
   *
   * @example ```js
   * Format.cost(12.98) // -> 12.98 $US
   * Format.cost(12.98, 'EUR') // -> 12.98 €
   * Format.cost(-12.98) // -> -12.98 $US
   * ```
   * @param { number } value
   * @param { string } currency
   * @returns { string }
   */
  cost(value, currency = 'USD', dec = 2) {
    return Intl.NumberFormat(this.locale, {
      style: 'currency',
      currency,
      maximumFractionDigits: dec,
      minimumFractionDigits: dec,
    }).format(value || 0)
  }

  /**
   * Return a formatted percentage with certain amount of fraction digits
   *
   * @param { number } value
   * @param { number } dec
   * @returns { string }
   */
  trend(value, dec = 2) {
    return Intl.NumberFormat(this.locale, {
      style: 'percent',
      maximumFractionDigits: dec,
      minimumFractionDigits: dec,
    }).format(value / 100)
  }

  /**
   * Get date formatted as `month YYYY`.
   *
   * @example ```js
   * Format.monthAndYear('2021') // -> 2021
   * Format.monthAndYear(new Date()) // -> january 2021
   * ```
   * @param { string | date | undefined } dateISO
   * @returns { string }
   */
  monthAndYear(dateISO = new Date()) {
    if (dateISO.length === 4) {
      return dateISO
    }

    return new Intl.DateTimeFormat(this.locale, {
      year: 'numeric',
      month: 'long',
    }).format(new Date(dateISO))
  }

  /**
   * Get date formatted as `month YYYY` from parts
   *
   * @example ```js
   * Format.dateFromParts() // -> jun 2021
   * Format.dateFromParts(2022) // -> jun 2022
   * Format.dateFromParts(null, 2) // -> january 2021
   * ```
   * @param { number } year
   * @param { number } month
   * @param { number } day
   * @returns { string }
   */
  dateFromParts(year = null, month = null) {
    const d = new Date()

    let date = new Date(
      year || d.getFullYear(),
      month - 1 || d.getMonth(),
      d.getDay(),
    )

    return this.monthAndYear(date)
  }

  /**
   * Transform a date into a specific format according to the input
   *
   * @param { string } string
   * @returns { string }
   */
  dateStringToLocale(string) {
    if (typeof string === 'number') {
      string = Math.abs(string).toString()
    }

    if (string.match(/^\d{4}-\d{2}-\d{2}$/)) {
      return moment(string).format(`Do MMM YYYY`)
    } else if (string.match(/^\d{4}-\d{2}$/)) {
      return moment(string).format(`MMMM YYYY`)
    }

    return string
  }

  /**
   * Trim and clear resourceId
   *
   * @param { string } value
   * @returns { string }
   */
  resourceId(value = '') {
    if (typeof value !== 'string') {
      return value
    }

    if (value.indexOf(':') >= 0) {
      let split = value.split(':')
      value = split.pop()
    } else if (value.indexOf('/') >= 0) {
      let split = value.split('/')
      value = split.pop()
    }

    return value.replaceAll("'", '')
  }

  /**
   * Format a number to 6 fraction digits
   *
   * @example ```js
   * Format.usageQuantity(100.123456789) // -> 100.123456
   * ```
   * @param { number } value
   * @returns { number }
   */
  usageQuantity(value) {
    return value ? value.toFixed(6) : value
  }

  /**
   * Split every space character and remove last element
   *
   * @param { string } string
   * @returns { string }
   */
  usageUnit(string = '') {
    const split = string.split(' ')
    return split.pop()
  }

  /**
   * Capitalize a string
   *
   * @example ```js
   * Format.urFirst('hello') // -> Hello
   * ```
   * @param { string } string
   * @returns { string }
   */
  ucFirst(string = '') {
    return string.charAt(0).toUpperCase() + string.slice(1)
  }
}

export default new Format()
