module.exports = {
  service_closure: 'Service closure',
  'right-sizing': 'Right-sizing',
  instance_modifying: 'Instance modifying',
  use_alternative: 'Use of alternative',
  reserved_instance: 'Reserved instance',
  cloud_gouvernance: 'Cloud Gouvernance',
  descriptionById: {
    1: 'Shutting down unused services and resources (ex: snapshots and residual disks)',
    2: 'Instance right-sizing (resizing)',
    3: 'Modification of instance models for the Compute / Storage part (ex: use a t3.small instead of a t2.medium at AWS)',
    4: 'Use of an alternative service with change of infrastructure (ex: use S3 Glacier where necessary)',
    5: 'Instance reservation (ex: reserve such type of machine to save up to 30%)',
    6: 'Cloud governance: labeling, naming, service opening policy, etc.',
  },
}
