module.exports = {
  access: 'Insufficient access',
  auth: 'Check your credentials',
  basic: 'Check this field',
  company: 'Create company',
  demo: 'Action limited with Demo',
  empty: 'This input must be set',
  forbidden: 'Unauthorized access',
  forbiddenMessage: 'Failed or expired',
  incomplete: 'Incomplete',
  input: 'Check your informations',
  invalidConfirmCode: 'The code entered is not valid',
  invalidEmail: 'Please enter a valid mail address',
  limit: 'Limited access (Redirect)',
  loginFirst: 'Login first',
  noData: 'Data not found or acquisition in progress',
  notificationMessage: 'A problem has occurred',
  notificationTitle: 'Error',
  offer: 'Check your offer',
  settingFirst: 'Create a valid connector',
  subscribeFirst: 'Update your offer now',
  success: 'Successful action',
  warn: 'Warning',
  share: {
    create: 'Cannot create a share',
    update: 'Cannot update your share',
    delete: 'Cannot delete this share',
  },
  title400: 'Warning',
  text400: 'Bad request',
  title401: 'Unauthorized',
  text401: 'You must be logged in to continue',
  title403: 'Forbidden',
  text403: "You don't have access to this feature",
  title404: 'Not found',
  text404: "This resource doesn't exist",
}
