import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'
import loginRoutes from '@/routes/login'
import dimensionRoutes from '@/routes/dimension'
import settingRoutes from '@/routes/setting'
import budgetRoutes from '@/routes/budget'
import recommandationRoutes from '@/routes/recommandation'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: () => import('../layouts/MainLayout.vue'),
    children: [
      {
        name: 'dashboard',
        alias: ['/', '/home'],
        path: 'dashboard',
        component: () => import('../views/HomeView.vue'),
        meta: { permission: 'home.index' },
      },
      {
        name: 'custom-dashboard',
        path: '/custom-dashboard',
        component: () => import('../views/Dashboards.vue'),
        meta: { permission: 'dashboard.index' },
        children: [
          {
            path: '',
            name: 'custom-dashboard-table',
            meta: { permission: 'dashboard.index' },
            component: () =>
              import('../components/dashboard/DashboardList.vue'),
          },
          {
            path: ':id',
            name: 'custom-dashboard-show',
            meta: { permission: 'dashboard.show' },
            component: () =>
              import('../components/dashboard/DashboardContainer.vue'),
          },
        ],
      },
      {
        name: 'inventory',
        path: '/inventory',
        component: () => import('../views/InventoryView.vue'),
        children: [
          {
            name: 'inventory-empty-view',
            path: '',
            component: () =>
              import('../components/layouts/inventory/InventoryEmptyView.vue'),
          },
          {
            name: 'inventory-detail',
            path: ':id',
            component: () =>
              import('../components/layouts/inventory/InventoryDetailView.vue'),
          },
        ],
      },
      {
        name: 'explorer',
        path: '/explorer',
        component: () => import('../views/ExplorerView.vue'),
        meta: {
          miniNavBar: true,
          permission: 'explorer.index',
          deprecated: true,
        },
      },
      {
        name: 'explorers',
        path: '/explorers',
        component: () => import('../views/ExplorersLayout.vue'),
        children: [
          {
            name: 'explorers-cloud',
            path: 'cloud',
            component: () => import('../views/explorers/CloudExplorer.vue'),
            meta: { icon: 'cloud', color: 'primary' },
          },
          {
            name: 'explorers-kubernetes',
            path: 'kubernetes',
            component: () =>
              import('../views/explorers/KubernetesExplorer.vue'),
            meta: { icon: 'kubernetes', color: 'red' },
          },
          {
            name: 'explorers-carbon',
            path: 'carbon',
            component: () => import('../views/explorers/CarbonExplorer.vue'),
            meta: { icon: 'molecule-co2', color: 'green' },
          },
        ],
        meta: { permission: 'explorer.index' },
      },
      {
        name: 'catalog',
        path: '/catalog',
        component: () => import('../views/CatalogView.vue'),
        meta: { miniNavBar: true, permission: 'explorer.index' }, // TODO: add permissions to API
      },
      {
        name: 'alerts',
        path: '/alerts',
        component: () => import('../views/Alerts.vue'),
        meta: { disabled: true, permission: 'alert.index' },
      },
      {
        name: 'alertReport',
        path: '/alert-report/:id',
        component: () => import('../views/AlertReport.vue'),
        meta: { disabled: true, permission: 'alert.show' },
      },
      {
        name: 'tags-dashboard',
        path: '/tags-dashboard',
        component: () => import('../views/TagsDashboard.vue'),
        meta: { permission: 'tags-dashboard.index' },
      },
      {
        name: 'tags-distribution',
        path: '/tags-distribution',
        component: () => import('../views/TagsDistribution.vue'),
        meta: { permission: 'tags-dashboard.index' },
      },
      {
        name: 'tags-configuration',
        path: '/tags-configuration',
        redirect: '/internal',
        component: () => import('../views/TagsConfiguration.vue'),
        meta: { permission: 'tags-dashboard.index' },
        children: [
          {
            name: 'internal',
            path: '/internal',
            component: () =>
              import('../components/tags/lotaTags/InternalTagsTable.vue'),
          },
          {
            name: 'external',
            path: '/external',
            component: () =>
              import('../components/tags/lotaTags/ProvidersTagsTable.vue'),
          },
          {
            name: 'strategies',
            path: '/strategies',
            component: () =>
              import(
                '../components/tags/lotaTags/strategy/StrategiesTable.vue'
              ),
          },
          {
            name: 'best-practices',
            path: 'best-practices',
            component: () => import('../components/tags/BestPractices.vue'),
          },
        ],
      },
      {
        name: 'strategy',
        path: '/strategy/:id',
        component: () => import('../views/StrategyView.vue'),
        children: [
          {
            name: 'strategy-overview',
            path: '',
            component: () =>
              import(
                '../components/tags/lotaTags/strategy/StrategyViewTable.vue'
              ),
          },
          {
            name: 'strategy-update',
            path: 'resources',
            component: () =>
              import(
                '../components/tags/lotaTags/strategy/TagsStrategyResources.vue'
              ),
          },
        ],
        redirect: { name: 'strategy-overview' },
      },
      {
        name: 'tags-coverage',
        path: '/tags-coverage',
        component: () => import('../views/TagsList.vue'),
        meta: { permission: 'tags-dashboard.index' },
      },
      {
        name: 'azure',
        path: '/azure',
        component: () => import('../views/SettingsView.vue'),
        meta: { permission: 'connector.store' },
      },
      {
        name: 'googleCloud',
        path: '/google',
        component: () => import('../views/SettingsView.vue'),
        meta: { permission: 'connector.store' },
      },
      {
        name: 'OracleCloud',
        path: '/oracle',
        component: () => import('../views/SettingsView.vue'),
        meta: { permission: 'connector.store' },
      },
      {
        name: 'Optimization',
        path: '/optimization',
        component: (resolve) =>
          require(['../views/OptimizationLayout.vue'], resolve),
        children: [
          {
            name: 'optimization-overview',
            path: '',
            component: (resolve) =>
              require(['../components/optimization/OverviewPage.vue'], resolve),
          },
          {
            name: 'optimization-setup',
            path: 'setup',
            component: (resolve) =>
              require(['../components/optimization/Setup.vue'], resolve),
          },
          {
            name: 'unused-resources',
            path: 'unused-resources',
            component: (resolve) =>
              require(['../views/UnusedResources.vue'], resolve),
          },
          {
            name: 'oversized-resources',
            path: 'oversized-resources',
            component: (resolve) =>
              require(['../views/OversizedResources.vue'], resolve),
          },
          {
            name: 'reserved-instances',
            path: 'reserved-instances',
            component: (resolve) =>
              require(['../views/ReservedInstances.vue'], resolve),
          },
          {
            name: 'benefits',
            path: 'benefits',
            component: (resolve) =>
              require(['../views/BenefitsView.vue'], resolve),
          },
          {
            name: 'catalog',
            path: 'catalog',
            component: () => import('../views/CatalogView.vue'),
          },
        ],
        meta: { role: 'user' },
      },
      {
        name: 'setPassword',
        path: '/setPassword',
        component: () => import('../views/SetPassword.vue'),
        meta: { menu: false, navbar: false },
      },
      {
        name: 'loginSuccess',
        path: '/loginsuccess',
        component: () => import('../views/LoginSuccess.vue'),
        meta: { menu: false, navbar: false, noAuth: true },
      },
      ...dimensionRoutes,
      ...settingRoutes,
      ...budgetRoutes,
      ...recommandationRoutes,
      ...loginRoutes,
    ],
  },
  {
    name: 'viewer',
    path: '/viewer',
    component: () => import('../layouts/ViewersLayout.vue'),
    children: [
      {
        name: 'viewer-dashboard',
        path: '/viewer',
        component: () => import('../views/viewers/DashboardView.vue'),
      },
      ...dimensionRoutes,
      ...budgetRoutes,
      ...recommandationRoutes,
      ...loginRoutes,
    ],
  },
  {
    name: 'forbidden',
    path: '/forbidden',
    component: () => import('../views/Forbidden.vue'),
    meta: { menu: false, navbar: false },
  },
  {
    name: 'error',
    path: '*',
    component: () => import('../views/Error.vue'),
    meta: { menu: false, navbar: false },
  },
]

const allRoutes = [...routes]

const router = new VueRouter({
  mode: 'history',
  routes: allRoutes,
  scrollBehavior(to, from, savedPosition) {
    return { top: 0 }
  },
})

router.beforeEach(async (to, from, next) => {
  const token = localStorage.getItem('api_token')
  const user = store.getters.user

  if (!token && to.path === '/forbidden') {
    return next({ path: '/login', query: { redirect: to.query.redirect } })
  }

  if (!token && to.path.startsWith('/login')) {
    return next()
  }

  const isViewer = user?.roles?.some((role) => role.name === 'viewer')

  if (token && to.path.startsWith('/login')) {
    if (isViewer) {
      return next({ name: 'viewer-dashboard' })
    } else {
      return next('/dashboard')
    }
  }

  if (token && to.path === '/dashboard' && isViewer) {
    return next({ name: 'viewer-dashboard' })
  }

  const permissions = user?.permissions || []
  if (
    to.meta.permission &&
    !permissions.includes(to.meta.permission) &&
    to.path !== '/forbidden'
  ) {
    return next({ name: 'forbidden', query: { redirect: to.path } })
  }

  next()
})

export default router
