module.exports = {
  action: 'Action',
  category: 'Catégorie',
  cost: 'Coût',
  creationDate: 'Date de création',
  currency: 'Devise',
  delete: 'Supprimer',
  disabled: 'désactivé',
  edit: 'Modifier',
  enabled: 'activé',
  explorerSearchLabel: 'Rechercher un produit, une catégorie, une ressource...',
  loadingData: 'Chargement en cours... Veuillez patienter.',
  name: 'Nom',
  username: 'Nom',
  searchUser: 'Rechercher un utilisateur',
  searchReport: 'Rechercher un import',
  searchViewer: 'Rechercher un viewer',
  firstName: 'Prenom',
  lastName: 'Nom',
  company: 'Entreprise',
  email: 'Email',
  noData: "Vous n'avez pas encore ajouté de connecteur",
  noResults: 'Aucun résultat trouvé pour ',
  product: 'Produit',
  provider: 'Fournisseur',
  type: 'Type',
  state: 'Etat',
  tags: 'Tags',
  total: 'Total',
  totalExpensesForSelectedPeriod:
    'Total des dépenses pour la période sélectionnée',
  resourceId: 'ID de ressource',
  region: 'Région',
  showResourceLabel: 'Affichage des ressources',
  usageAccountId: 'ID de compte',
  numberPerPage: "Nombre d'élèments par page",
  others: 'autres',
  displayTimeGranularity: 'afficher les dépenses par',
  day: 'jour',
  week: 'semaine',
  month: 'mois',
  quarter: 'trimestre',
  year: 'année',
  totalCostWithoutTaxes: 'Coût total HT',
  percentage: '% du coût total',
  noResourceAttached: "Aucune ressource n'est attachée à cette dépense",
  lastSeen: 'Dernière connexion',
  startDate: 'Début de période',
  endDate: 'Fin de période',
  importRecords: 'Lignes',
  importStatus: 'Status',
  importProvider: 'Fournisseur',
  file: 'Fichier',
  discoveredOn: 'Date de découverte',
  size: 'Taille',
  status: 'Statut',
  done: 'Terminé',
  error: 'Erreur',
  pending: 'En attente',
}
