module.exports = {
  carousel_next: 'Suivant',
  carousel_prev: 'Précédent',
  carousel_done: 'Terminé',
  carousel_contact: 'Contacter le service client',
  carousel_news: 'Rester informé des nouveautés',
  carousel_create: 'Créer un compte',
  message_0:
    'Vous etes gestionnaire d’un ou plusieurs comptes Cloud pour votre entreprise et vous vous posez des questions pour en améliorer sa gestion ? Notre plateforme peut vous aider.<br /><br />Ce guide vous permettra de mieux comprendre les différentes fonctionnalités disponibles sur Lota.cloud.<br /><br />Promis, ce sera très rapide : seulement quelques clics pour bien prendre en main notre outil. Vous pourrez ensuite naviguer librement sur la plateforme.<br /><br />Les données utilisées sur cette plateforme sont fictives et vous permettent uniquement de mieux appréhender nos fonctionnalités.<br /><br />Bonne découverte !',
  message_1:
    'C’est la page principale de notre plateforme. Lorsque vous vous connectez, vous arrivez directement sur cet écran. Elle vous permet d’effectuer <b>une analyse en un clin d’oeil de votre situation</b>.<br /><br />Vous pouvez obtenir une vue de vos dépenses à 7, 14, 30, 60, 90 jours en cliquant sur le <b>sélectionneur de date</b> en haut à droite de l’écran.<br /><br />Vous trouvez également la <b>variation de votre consommation</b>, ainsi que la <b>répartition de vos dépenses par fournisseur/catégorie/produit</b> en bas de page.',
  message_2:
    'Ici, vous pourrez comprendre vos principaux postes de dépense, détecter les anomalies et identifier les solutions adaptés à vos écarts de consommation.<br /><br />Vous pouvez ici <b>filtrer vos coûts</b> en fonction de plusieurs critères, obtenir un <b>graphique dynamique</b> et plonger plus profondément dans la granularité de vos données.',
  message_3:
    'Sur cet écran, vous pourrez <b>retrouver toutes vos factures en quelques clics seulement</b>. Vous pouvez filtrer en fonction du fournisseur, du mois et de l’année concernée.<br /><br />Cette fonctionnalité vous permet de <b>gagner en sérénité</b> : nous conservons tout votre historique de facturation, sans limitation dans le temps. Un doûte : Rendez-vous dans votre onglet facturation Lota.cloud !',
  message_4:
    'Vous souhaitez encore plus de sérénité ? Oubliez l’anxiété de la visite quotidienne de votre Dashboard FinOps : grâce à notre système d’alerting, vous êtes <b>averti en temps réel du moindre dépassement de budget</b>.<br /><br />Mettez en place vos alertes de manière <b>personnalisée</b> et recevez des <b>alertes par mail ou directement sur la plateforme</b>.<br /><br />Vous pouvez définir le <b>seuil maximal à ne pas dépasser, la période et le périmètre à contrôler</b>. Un graphique vous permet également de visualiser l’évolution de votre consommation en fonction de votre seuil.',
  message_5:
    "On vous avez prévenu : <b>après seulement quelques clics, vous voila prêt à dompter vos dépenses Cloud !</b><br /><br />Vous venez de balayer les principales fonctionnalités de notre plateforme. Nous espérons que ce rapide tour du propriètaire vous aura permis de comprendre le fonctionnement de notre outil 100% FinOps. Il est dorénavant l’heure de vous lancer dans l’aventure Lota.cloud !<br /><br />Maintenant, il ne vous reste plus qu’à connecter vos données à notre plateforme pour commencer à optimiser vos dépenses de manière concrète !<br /><br />Sachez que nous développons des nouvelles fonctionnalités en continu. Vous pouvez vous inscrire à notre newsletter 'Les chroniques du Cloud' pour ne rien rater de l’évolution de notre outil !<br /><br />Bonne utilisation !",
  title_0: 'Bonjour et bienvenue',
  title_1: 'Le tableau de bord',
  title_2: 'L’explorateur',
  title_3: 'Vos factures',
  title_4: 'Système d’Alerting',
  title_5: 'Merci',
}
