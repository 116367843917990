module.exports = {
  action: 'Action',
  category: 'Category',
  cost: 'Cost',
  creationDate: 'Creation date',
  currency: 'Currency',
  delete: 'Delete',
  disabled: 'disabled',
  edit: 'Edit',
  enabled: 'enabled',
  explorerSearchLabel: 'Search a product, a category, a resource...',
  loadingData: 'Loading... Please wait.',
  name: 'Connector name',
  username: 'Name',
  searchUser: 'Search a user',
  searchViewer: 'Search a viewer',
  firstName: 'First name',
  lastName: 'Last name',
  company: 'Company',
  email: 'Email',
  noData: 'You do not have added a connector yet',
  noResults: 'No results found for ',
  product: 'Product',
  provider: 'Provider',
  type: 'Type',
  state: 'State',
  tags: 'Tags',
  total: 'Total',
  totalExpensesForSelectedPeriod: 'Total expenses for the selected range',
  resourceId: 'Resource ID',
  region: 'Region',
  showResourceLabel: 'Show resources',
  usageAccountId: 'Account ID',
  explorerSearchLabel: 'Search a product, a category, a resource...',
  others: 'others',
  displayTimeGranularity: 'display costs per',
  day: 'day',
  week: 'week',
  month: 'month',
  quarter: 'quarter',
  year: 'year',
  totalCostWithoutTaxes: 'Total cost without taxes',
  percentage: '% of total cost',
  noResourceAttached: 'No resource attached',
  lastSeen: 'Last seen',
  file: 'File',
  discoveredOn: 'Discovered on',
  size: 'Size',
  status: 'Status',
  done: 'Done',
  error: 'Error',
  pending: 'Pending',
}
