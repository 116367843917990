<template>
  <v-col xs12 sm10 md8 align="center" align-self="center">
    <p
      :class="
        whiteText === true
          ? 'font-weight-medium white--text'
          : 'font-weight-medium'
      "
    >
      {{ $lang.message[langMessageKey] }}
    </p>

    <img
      src="@/assets/image/login.png"
      alt=""
      style="max-width: 100%; height: auto"
    />
  </v-col>
</template>

<script>
export default {
  name: 'PageInfo',
  props: {
    langMessageKey: { type: String, default: 'errorNotFound' },
    whiteText: { type: Boolean, default: false },
  },
}
</script>
