module.exports = {
  and: 'AND',
  or: 'OR',
  cost: 'Cost without taxes',
  providers: 'Providers',
  provider: 'Provider',
  products: 'Products',
  product_code: 'Products',
  product: 'Product',
  accounts: 'Accounts',
  account: 'Account',
  regions: 'Regions',
  region: 'Region',
  categories: 'Categories',
  category: 'Category',
  tags: 'Tags',
  tag: 'Tag',
  resources: 'Resources',
  usage: 'Usages',
  costCenter: 'Cost Center',
  cost_center: 'Cost Center',
  resourceId: 'Resource ID',
  resource_id: 'Resource ID',
  resourceName: 'Resource Name',
  resource_name: 'Resource Name',
  resourceTypes: 'Resource types',
  resourceType: 'Resource type',
  usageTypes: 'Usage types',
  usageType: 'Usage type',
  usageAccountId: 'Accounts',
  usage_account_id: 'Accounts',
  resourceCount: 'Resource count',
  percentage: 'Percentage of total cost',
  showAll: 'Show all',
  totalCostWithoutTaxes: 'Total cost (excl. VAT)',
  period: 'Period',
  dayLabel: 'Show cost per day',
  monthLabel: 'Show cost per month',
  yearLabel: 'Show cost per year',
  weekLabel: 'Show cost per week',
  quarterLabel: 'Show cost per quarter',
  loadingData: 'Loading you data...',
  yAxisChartTitle: 'Costs in ',
  dayGranularity: 'day',
  monthGranularity: 'month',
  yearGranularity: 'year',
  weekGranularity: 'week',
  quarterGranularity: 'quarter',
  resourceIdGroupLabel: 'Group by resources',
  resourceNameGroupLabel: 'Group by resource name',
  productGroupLabel: 'Group by products',
  categoryGroupLabel: 'Group by categories',
  usageAccountIdGroupLabel: 'Group by accounts',
  regionGroupLabel: 'Group by regions',
  serviceGroupLabel: 'Group by services',
  providerGroupLabel: 'Group by providers',
  costCenterGroupLabel: 'Group by cost center',
  tagGroupLabel: 'Group by tags',
  usageGroupLabel: 'Group by usages',
  showLegend: 'Show legend',
  averageCost: 'Average cost',
  otherChartOptions: 'options',
  detailedView: 'Detailed view',
  share: 'Share',
  usages: 'Services usages',
  taxes: 'Taxes',
  discounts: 'Discounts',
  fees: 'Fees',
  itemType: 'Type',
  usageDescription: 'Description',
  usageQuantity: 'Quantity',
  usageUnit: 'Unit',
  usageCost: 'Cost',
  allModeLabel: 'All expenses',
  noDataText: 'No results found',
  caseSensitiveSearch: 'Case sensitive',
  caseInsensitiveSearch: 'Case insensitive',
  compareTo: 'Compare to',
  service: 'Services',
  chartTitle: 'Costs in %c per %k and per %g',
  EUR: 'euros',
  INR: 'indian roopie',
  USD: 'dollars',
  xAxisChartTitle: 'Time ',
  inOperator: 'Include values',
  ninOperator: 'Exclude values',
  eqOperator: 'Equal to',
  neOperator: 'Not equal to',
  noScopeSaved: "You don't have filters yet ?",
  noScopeSavedDescription: 'Click on the button below to add a new one.',
  values: 'Value(s)',
  addMoreBlocks: 'Add a new block',
  operator: 'Operator',
  column: 'Column',
  removeOperator: 'Remove',
  removeBlock: 'Remove Block',
  dragAndDropZoneText: 'Drag and drop filters here and select an operator.',
  dragMoreFiltersText: 'Drag more filters or replace existing one.',
  resetFilters: 'Reset filters',
  refresh: 'Refresh',
  refreshAndClose: 'Refresh and close',
  saveScope: 'Save filter',
  scopeName: 'Filter name',
  showAnomalies: 'Show anomalies',
  showWeekend: 'Show weekends',
  showWeekendDescription:
    'Weekends will be represented on the daily expense charts by lightly shaded bands.',
  showWeekendsLabel: 'Only displays when data is daily aggregated',
  optimizeRequest: 'Optimize requests',
  optimizeRequestDescription:
    'Automatically display results by month when the period exceeds 60 days',
  optimisticRequestDescription:
    'Automatically set the time granularity when the period exceed 60 days to monthly',
  logarithmicMode: 'Logarithmic Scale',
  logarithmicModeDescription:
    'A logarithmic scale is particularly suitable for reporting orders of magnitude in charts.',
  untaggedMode: 'Display only non-tagged costs',
  notSavedFilter: 'Filter not saved',
  editAResource: 'Edit a resource',
  name: 'Name',
  value: 'Value',
  origin: 'Origin',
  external: 'External',
  identifier: 'Identifier',
  description: 'Description',
  addOrEditTag: 'Add or edit a tag',
  optimizationPossible: 'This resource could be optimized',
  relatedServices: 'Related services',
  noServices: 'No services associated to this resources',
  setAdditionalInformation: 'Set additional information',
  getOptimizations: 'Get optimizations for this resource',
}
