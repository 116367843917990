module.exports = {
  tabs: {
    summary: 'Sommaire',
    optimizations: 'Optimisations',
    metrics: 'Métriques',
    settings: 'Paramètres',
  },
  chips: {
    optimizable: 'Optimisable',
  },
  date: '%s dépensés entre le %s et le %s',
  empty: 'Sélectionnez une ressource pour afficher les détails',
  metrics: {
    noData: 'Aucune donnée disponible pour la période sélectionnée',
    cpuTitle: 'Utilisation du CPU en pourcentage',
    networkTitle: 'Utilisation du réseau en bytes',
    basedDays: 'Basé sur les 30 derniers jours',
    maximum: 'Maximum',
    average: 'Moyenne',
  },
  optimizations: {
    noData: "Il n'y a aucune recommandation pour cette ressource.",
  },
  search: {
    resource: 'Rechercher une ressource',
    title: 'Paramètres de recherche',
    dateLabel: 'Période',
    onlyOptimizable: 'Ressources optimisables',
    onlyMetrics: 'Ressources avec métriques',
    metrics: 'Métriques',
    optimizations: 'Optimisables',
  },
  settings: {
    descriptionTitle: 'Description de la ressource',
    descriptionLongTitle:
      "Parfois, un ID de ressource n'est pas suffisant pour l'identifier. Ajouter une description vous aidera à l'identifier.",
    description: 'Description',
    descriptionHint:
      'Une courte description de la ressource, idéalement inférieure à 50 caractères',
    save: 'Save',
    descriptionSaved: 'La description a été sauvegardée',
    descriptionError:
      "Une erreur s'est produite lors de la sauvegarde de la description",
    externalTags: 'Tags externes',
    externalTagsLongTitle:
      'Les tags externes sont fournis par votre fournisseur de cloud. Nous ne sommes pas en mesure de les modifier directement depuis Lota.cloud. Ce tableau affiche tous les tags, même ceux qui ne sont pas activés.',
    key: 'Clé',
    value: 'Valeur',
  },

  summary: {
    serviceTitle: 'Services et usages',
    information: 'Informations',
    description: 'Description',
    resourceId: 'ID de la ressource',
    resourcePath: 'Chemin de la ressource',
    provider: 'Fournisseur',
    yAxisTitle: 'Coût en %s',
  },
  table: {
    noResults: 'Aucune ressource ne correspond à votre recherche',
    noMoreResults: 'Vous avez atteint la fin de la liste',
  },
}
