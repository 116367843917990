module.exports = {
  demo: 'Demo mode.',
  visitOurWebsite: 'Visit our website.',
  privacyPolicy: 'Privacy Policy.',
  generalConditionsOfUse: 'General conditions of Use.',
  createAGravatar: 'Personalize my Gravatar',
  gravatar: 'https://en.gravatar.com/',
  cgu: 'Download our GUC',
  costAndUsageAzure:
    'https://docs.microsoft.com/en-us/azure/cost-management-billing/understand/download-azure-daily-usage',
}
