module.exports = {
  access: 'Augmentez votre offre pour accéder à cette section',
  alertDeleteConfirm: 'Êtes-vous sûr(e) de vouloir supprimer cette alerte ?',
  alertDeleteError:
    "Une erreur est survenue lors de la suppression de l'alerte.",
  alertDeleteSuccess: "L'alerte a bien été supprimée.",
  badMethod:
    "Mauvause méthode, utilisez seulement 'get', 'post', 'put' ou 'delete'",
  billNotFound: "Aucune facture n'a été trouvée",
  comingSoon: 'Disponible très prochainement',
  connectorDeleteConfirm:
    'Êtes-vous sûr(e) de vouloir supprimer ce connecteur ?',
  connectorDeleteError:
    'Une erreur est survenue lors de la suppression du connecteur.',
  connectorDeleteSuccess: 'Le connecteur a bien été supprimé',
  connectorEditError:
    'Une erreur est survenue lors de la modification du nom du connecteur.',
  connectorEditSuccess: 'Le nom du connecteur a bien été modifié.',
  connectorRename: 'Renommer un connecteur',
  errorNotFound: "Cet élément n'existe pas",
  errorEncountered: 'Une erreur est survenue',
  hello: 'Bonjour',
  modalOfferOutdated:
    "Il semble que votre période d'essai a expiré dernièrement, pour pouvoir accéder de nouveau à nos services, veuillez souscrire une offre",
  modalOfferOutdatedTitle: 'Il y a un soucis',
  notificationConnector:
    'Connecteur créé, les données seront disponibles dans quelques heures',
  notificationMessage: 'Action réussie',
  notificationTitle: 'Succès',
  passwordRecoverySuccess:
    "Vous allez recevoir un e-mail vous permettant d'obtenir un nouvel accès à nos services.",
  passwordRecoveryTitle: 'E-mail envoyé 👌',
  paymentSuccess:
    'Nous avons bien enregistré votre paiement, votre compte sera très prochainement activé',
  settingLoading: 'Chargement des connecteurs en cours...',
  settingLoadingOver: 'Sélectionner un fournisseur pour créer un connecteur.',
  updateAction: 'Action nécessaire',
  updateLang: 'Validez ce formulaire pour appliquer le changement',
  updatePassword: 'Modifier votre mot de passe',
  warning: 'Attention !',
  info: 'Information',
  incorrectPassword: 'Mot de passe incorrect !',
  share: {
    updateOK: 'Informations modifiées avec succès !',
    deleteOK: 'Vous avez supprimé votre partage !',
  },
  usageChartHiddenCost:
    'Pour votre confort, les consommations dont le coût est inférieur à 5% des dépenses totales de la ressource sont masquées. Cliquez sur la légende pour les réactiver.',
  shareNotFound:
    'Rendez-vous sur notre explorateur pour créer un nouveau partage.',
  itemsPerPage: 'Éléments par page',
  billingCurrencyExplanation:
    'Les montants présentés sur chaque facture sont affichés dans la devise de votre fournisseur. Nous ne convertissons pas les données sur l\'aspect "facturation" pour permettre aux factures d\'être le plus proche possible de celles des fournisseurs.',
  alertCurrencyExplanation:
    "Nous utilisons la devise d'origine de votre facturation pour la détection des alertes.",
  dashboardCurrencyExplanation:
    "Vous pouvez personnaliser l'affichage de vos dépenses depuis vos paramètres d'utilisateur. Nous convertissons instantanément le coût HT avec les taux d'échange actuels.",
  explorerCurrencyExplanation:
    "Les dépenses affichées dans l'explorateur dans une devise différente de celle que vous avez paramétrée sont instantanément convertis avec les taux d'échange actuels.",
  addUser: 'Ajouter un nouvel utilisateur',
  resetPassword: 'Réinitialiser le mot de passe?',
  updateUser: 'Mise à jour des données utilisateur',
  updateCompany: "Mise à jour des informations de l'entreprise",
  confirmPasswordChange:
    "Êtes-vous sur de vouloir réinitialiser le mot de passe ? Un e-mail sera envoyé à %s pour l'inviter à changer son mot de passe.",
  resetPasswordError: 'Impossible de réinitialiser le mot de passe.',
  resetPasswordSuccess: 'Un e-mail de réinitialisation à été envoyé.',
  resourceNameClipBoard: 'Nom de ressource copié dans le presse-papier !',
  deleteScopeText: 'Êtes-vous sûr(e) de vouloir supprimer ce filtre ?',
  deleteScopeSubText:
    'Tous les modèles associés à ce filtre seront supprimés en cascade (budgets, recommandations, tuiles, etc.).',
  invalid: 'invalide',
  emptyField: ' Tous les champs doivent être remplis',
}
