module.exports = {
  service_closure: 'Fermeture de service',
  'right-sizing': 'Redimensionnement',
  instance_modifying: "Modification d'instance",
  use_alternative: "Utilisation d'alternative",
  reserved_instance: "Reservation d'instance",
  cloud_gouvernance: 'Gouvernance Cloud',
  descriptionById: {
    1: 'Fermeture de services et de ressources inutilisés (ex: snapshots et disques résiduels)',
    2: 'Redimensionnement d’instance (right-sizing)',
    3: 'Modification des modèles d’instances pour la partie Compute / Storage (ex: utiliser une t3.small au lieu d’une t2.medium chez AWS)',
    4: 'Utilisation d’un service alternatif avec changement d’infrastructure (ex: utiliser S3 Glacier là ou cela est nécessaire)',
    5: 'Réservation d’instance sur 1 ou 3 ans (ex: réserver tel type de machine pour économiser jusqu’à 30%)',
    6: 'Gouvernance Cloud : politique d’étiquetage, de nommage, d’ouverture de service, etc.',
  },
}
