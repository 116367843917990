module.exports = {
  unusedResourcesList: 'Liste des ressources inutilisées',
  unusedResourcesClosingList: 'Liste des ressources en cours de fermeture',
  unusedResourcesClosedList: 'Liste des ressources déjà fermées',
  unusedResources: 'Ressources inutilisées',
  unusedResourcesClosing: 'Ressources en cours de fermeture',
  unusedResourcesClosed: 'Ressources déjà fermées',
  createRecommendation: 'Créer une recommandation',
  oversizedResources: 'Ressources surdimensionnées',
  oversizedResourcesList: 'Liste des ressources surdimensionnées',
  oversizedResourcesResizingList:
    'Liste des ressources en cours de redimensionnement',
  oversizedResourcesResizing: 'En cours de redimensionnement',
  oversizedResourcesResizedList: 'Liste des ressources redimensionnées',
  oversizedResourcesResized: 'Ressources redimensionnées',
  virtualMachines: 'Machines virtuelles',
  averageCpuUsage: 'CPU (moyenne)',
  minCpuUsage: 'CPU (min)',
  maxCpuUsage: 'CPU (max) moyen',
  instanceModel: 'Modèle',
  networkIn: 'Réseau (entrant)',
  networkOut: 'Réseau (sortant)',
  storage: 'Stockage',
  database: 'Base de données',
  lastScanOn: 'Dernier scan le',
  selectionDescription: 'Sélectionnez les ressources à fermer et cliquez sur',
  noUnusedResourcesFound:
    "Nous n'avons détecté aucune ressource inutilisée sur votre infrastructure, bravo !",
  noUnusedResourcesClosingFound:
    "Vous n'avez aucune ressource en cours de fermeture.",
  noUnusedResourcesClosedFound: "Vous n'avez aucune ressource fermée.",
  noOversizedResourcesFound:
    "Nous n'avons détecté aucune ressource surdimensionnées sur votre infrastructure, bravo !",
  noOversizedResourcesClosingFound:
    "Vous n'avez aucune ressource en cours de redimensionnement.",
  noOversizedResourcesClosedFound:
    "Vous n'avez aucune ressource redimensionnée.",
  noResults: 'Aucun résultat pour votre recherche',
  featureTitle: 'Optimisation',
  summary: 'Synthèse',
  potentialSavings: 'Économies réalisables',
  actualSavings: 'Économies réalisées',
  searchResources: 'Rechercher une ressource',
  overSizedResources: 'Ressources surdimensionnées',
  reservedInstances: 'Ressources réservées',
  documentation: 'Documentation',
  loadingResources: 'Chargement de vos ressources...',
  savings: 'Économies',
  associatedRecommendations: 'Recommandations associées',
  reasons: 'Raisons',
  monthlyCost: 'Coût mensuel',
  perMonth: '30 jours',
  perQuarter: '3 mois',
  perSemester: '6 mois',
  perYear: '12 mois',
  notAssigned: 'Pas encore assignées',
  assigned: 'Assignées dans une recommandation',
  verified: 'Recommandations vérifiées',
  month: 'mois',
  unusedResourcesRules: "Règles d'optimisation (ressources inutilisées)",
  potentialAndRealizedSavings: 'Économies réalisables et réalisées',
  oversizedResourcesSavingsTitle: 'Calcul des économies réalisables',
  oversizedResourcesSavingsDescription:
    "Les économies sont estimées avec une réduction moyenne de 45% lors d'un redimensionnement d'instance. Nous travaillons sur l'intégration de notre catalogue de prix pour vous suggérer les meilleures alternatives dans le cadre d'un redimensionnement d'instance et vous aider à calculer les économies réalisables de manière plus précise.",
  oversizedResourcesRulesTitle: 'Règle de surdimensionnement',
  oversizedResourcesRulesDescriptionCPU:
    "Notre scanner identifie les ressources dont le CPU est inférieur à 40% en moyenne d'utilisation maximale par jour sur les 30 derniers jours.",
  oversizedResourcesRulesDescriptionNetwork:
    'Notre scanner identifie les ressources dont le réseau est inférieur à 1MB en moyenne maximale par jour sur les 30 derniers jours.',
  instanceReservationAndSavingsPlans:
    'Réservation d’instances et plans d’économies',
}
