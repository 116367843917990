import Vue from 'vue'
import Vuex from 'vuex'
import explorer from './modules/explorer'
import global from './modules/global'
import user from './modules/user'
import viewers from './modules/viewers'
import themes from './modules/theme'
import company from './modules/company'
import scope from './modules/scope'
import recommendation from './modules/recommendation'
import notification from './modules/notification'
import budget from './modules/budget'
import explorers from '@/store/modules/explorers'
import app from '@/store/modules/app'

Vue.use(Vuex)

import createPersistedState from 'vuex-persistedstate'

export default new Vuex.Store({
  modules: {
    explorer,
    global,
    user,
    viewers,
    themes,
    company,
    scope,
    recommendation,
    notification,
    budget,
    explorers,
    app,
  },
  plugins: [
    createPersistedState({
      reducer: (state) => {
        return {
          ...state,
          global: {
            currencies: state.global.currencies,
            drawer: state.global.drawer,
            isMiniVariant: state.global.isMiniVariant,
            parameters: state.global.parameters,
          },
        }
      },
    }),
  ],
  strict: false,
})
