module.exports = {
  usageHours: "Heures d'utilisation",
  cost: 'Coût',
  columnSerieName: 'Dépenses totales',
  seriesName: 'représente',
  columnPrices: 'Montant: ',
  yAxisTitle: 'Montant',
  explorerTitleUSD: 'Dépenses totales en DOLLARS US',
  explorerTitleEUR: 'Dépenses totales en EUROS',
  column: 'Colonne',
  bar: 'Barre',
  pie: 'Camembert',
  areaspline: 'Zone',
  spline: 'Ligne',
  dayGranularity: 'Jour',
  weekGranularity: 'Semaine',
  monthGranularity: 'Mois',
  quarterGranularity: 'Trimestre',
  yearGranularity: 'Année',
}
