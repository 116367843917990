<template>
  <v-col cols="12" class="d-flex">
    <v-avatar
      :color="number === '0' ? 'transparent' : 'grey'"
      dark
      size="25"
      class="mx-2 my-5"
    >
      <span class="white--text" v-show="number !== '0'">{{ number }}</span>
    </v-avatar>
    <v-card flat color="transparent" width="100%">
      <slot name="title">
        <v-card-title>
          {{ title }}
        </v-card-title>
      </slot>
      <slot name="description">
        <v-card-subtitle class="pt-0">
          {{ description }}
        </v-card-subtitle>
      </slot>
      <v-container class="text-center">
        <slot name="content">
          <v-sheet height="150" color="grey" width="100%" dark>
            <v-row align="center" justify="center" class="fill-height">
              <v-col>Some content here.</v-col>
            </v-row>
          </v-sheet>
        </slot>
      </v-container>
    </v-card>
  </v-col>
</template>

<script>
export default {
  name: 'FormStep',

  props: {
    title: { type: String, default: 'From step title' },
    description: {
      type: String,
      default: 'Form step description text may come here',
    },
    number: { type: String, default: '1' },
  },
}
</script>
