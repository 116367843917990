<template>
  <v-snackbar color="primary" timeout="-1" bottom v-model="visible">
    <template #default>
      <v-row justify="center" align="center" no-gutters>
        <v-col cols="1">
          <v-icon large>{{ icon }}</v-icon>
        </v-col>
        <v-col>
          <div v-html="text" />
        </v-col>
      </v-row>
    </template>
    <template #action="{ attrs }">
      <v-btn text icon dark v-bind="attrs" @click="dismiss">OK</v-btn>
    </template>
  </v-snackbar>
</template>

<script>
export default {
  name: 'DismissableFooter',
  props: {
    icon: { type: String, default: 'mdi-information-outline' },
    text: { type: String, default: 'Footer text' },
    name: { type: String, required: true },
  },
  data() {
    return {
      visible: localStorage.getItem(`${this.name}`) !== 'false',
    }
  },
  methods: {
    dismiss() {
      localStorage.setItem(`${this.name}`, false)
      this.visible = false
    },
  },
}
</script>
