module.exports = {
  newPassword: 'New password',
  savePassword: 'Save',
  cancel: 'Cancel',
  required: 'Required',
  minChars: 'Min 8 characters',
  minLowercase: 'Must include at least one lowercase letter',
  minUppercase: 'Must include at least one uppercase letter',
  minNumber: 'Must include at least one number',
  newPasswordDescription:
    'Your password must be different to previously used passwords.',
  welcome: 'Welcome 👋',
  welcomeDescription:
    'You have been invited to join Lota.cloud, create your password to access your account.',
}
