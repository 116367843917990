module.exports = {
  alert: 'Alert management',
  budget: 'Budget administration',
  buy: 'Subscribe',
  byLota: 'By lota.cloud',
  control: 'Control Pack',
  comparator: 'Comparator',
  connector: 'Cloud connector unlimited',
  dashboard: 'Dashboard',
  disctionary: 'Management of a product dictionary',
  explorer: 'Explorer',
  billing: 'Data history',
  share: 'Share Explorer',
  starter: 'Starter Pack',
  tag: 'Tags',
  comparator: 'Comparator',
  history: 'Data history',
  alert: 'Alert management',
  connector: 'Cloud connector unlimited',
  buyDone: 'Starter pack actived',
  price: 'Price excluding tax, i.e 58.80 € incl.',
  control: 'Control',
  starter: 'Starter',
  viewer: 'Viewer',
  free: 'Free',
  control_excl: '200 € excl. VAT',
  control_incl: '* 240 € incl. VAT',
  starter_excl: '100 € excl. VAT',
  starter_incl: '* 120 € incl. VAT.',
  description: {
    starter:
      'Discover and use the main FinOps tools. Search and Visualize your Cloud data in just a few clicks. Create a simplified view and share this experience with other collaborators.',
    control:
      'Master the FinOps toolset by modifying traditional thought patterns. Set a benchmark practice for cloud users.',
  },
  price: {
    starter: 'Price excluding tax, i.e 120 € incl.',
    control: 'Price excluding tax, i.e 240 € incl.',
  },
}
