module.exports = {
  _1: 'janv',
  _2: 'fév',
  _3: 'mars',
  _4: 'avril',
  _5: 'mai',
  _6: 'juin',
  _7: 'juillet',
  _8: 'août',
  _9: 'sep',
  _10: 'oct',
  _11: 'nov',
  _12: 'déc',
}
