module.exports = {
  print: 'Imprimer',
  title: 'Imprimer Rapport de dépenses du ou tableau',
  action: 'Télégcharger PDF',
  close: 'Fermer',
  checkbox: 'Graphique',
  checkboxTable: 'Tableau',
  titleDocumentPdf: 'Rapport de dépenses',
  productCostReport: 'Dépenses par produit',
  resourceIdCostReport: 'Dépenses par ID de ressource',
}
