const state = () => ({
  dimensions: [],
  dimension: [],
  dimension_id: null,
  granularity: 'current_month',
})

const getters = {
  dimensions: (state) => state.dimensions,
  dimension: (state) => state.dimension,
  dimension_id: (state) => state.dimension_id,
  dimensionGranularity: (state) => state.granularity,
}

const actions = {
  setDimensions({ commit }, dimensions) {
    commit('SET_DIMENSIONS', dimensions)
  },
  setDimension({ commit }, dimension) {
    commit('SET_DIMENSION', dimension)
  },
  setDimensionId({ commit }, dimensionId) {
    commit('SET_DIMENSION_ID', dimensionId)
  },
  setGranularity({ commit }, granularity) {
    commit('SET_GRANULARITY', granularity)
  },
}

const mutations = {
  SET_DIMENSIONS(state, dimensions) {
    state.dimensions = dimensions
  },
  SET_DIMENSION(state, dimension) {
    state.dimension = dimension
  },
  SET_DIMENSION_ID(state, dimensionId) {
    state.dimension_id = dimensionId
  },
  SET_GRANULARITY(state, granularity) {
    state.granularity = granularity
  },
}

export default {
  state,
  getters,
  actions,
  mutations,
}
