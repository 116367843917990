const state = () => ({
  mode: 'all',
  dialogKey: null,
  dialogValue: null,
  and: false,
  isLogarithmicModeEnabled: true,
})

const getters = {
  granularity: (state) => {
    return state.granularity
  },
  logarithmicMode: (state) => {
    return state.isLogarithmicModeEnabled
  },
}

const actions = {
  setLogarithmicMode({ commit }, state) {
    commit('setLogarithmicMode', state)
  },
}

const mutations = {
  setLogarithmicMode(state, payload) {
    state.isLogarithmicModeEnabled = payload
  },
}

export default {
  namespaced: false,
  state,
  getters,
  actions,
  mutations,
}
