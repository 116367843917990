module.exports = {
  name: 'Name',
  product: 'Product',
  ratio: 'Ratio',
  totalCost: 'Total cost',
  share: 'Share this report',
  exportPDF: 'Export Report To PDF',
  exportCSV: 'Export resources to CSV',
  involvedResources: 'Involved resource list',
  resolution: 'Solving ',
  and: 'and ',
  status: 'Status',
  recommandations: 'recommandations',
  toPause: 'Switch to paused',
  toDelete: 'Delete ',
  toCancel: 'Annuler',
  toReactivate: 'Reactivate',
  acquitted: 'Alarm acquitted on',
  triggered: 'Alarm triggered on',
  solved: 'Alarm solved on',
  paused: 'Alarm paused on',
  cancelled: 'Alarm cancelled on',
  running: 'Alarm run on',
  modifyName: 'Modify the name',
  newName: 'New name',
  noActivity: 'No events on this alert',
}
