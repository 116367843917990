module.exports = {
  welcomeTitle: 'Bonjour',
  welcomeSubtitle: 'Que voulez-vous faire aujourd’hui ?',
  pinModal: {
    title: 'Sélectionner une dimension',
    subText:
      'Votre dimension sera sélectionnée par défaut à votre prochaine connexion',
  },
  editUserModal: {
    title: 'Mon profil',
    subTitle: `Configurez votre profil d'utilisateur`,
    gravatar: 'Personnaliser mon gravatar',
    inputFirstName: 'Prénom',
    inputLastName: 'Nom',
    inputEmail: 'Email',
    selectLangue: 'Langue',
  },
  editLogoModal: {
    tooltip: 'Ajoutez votre logo',
  },
  granularity: {
    lastThirtyDay: 'Les trentes derniers jours',
    lastMonth: 'Le mois dernier',
    thisMont: 'Le mois en cours',
    startYear: `Le début de l'année`,
    label: 'Période',
  },
  charts: {
    btn: 'Sélectionnez une dimension',
    noData:
      'Pas de données disponibles, veuillez changer de dimension ou de temporalité',
    values: 'Valeurs',
  },
  kpi: {
    lastThirtyDay: '30 Derniers',
    lastMonth: 'Mois dernier',
    thisMont: 'Mois en cours',
    startYear: 'Année',
  },
  sectionsTitles: {
    expensesByDayTitle: 'Dépenses par jour',
    dimensions: 'Vos dimensions enfants',
  },
  childDimension: {
    cost: 'Coût total',
    budget: 'Ajouter un budget',
    search: 'Rechercher une dimension',
    filterTitle: 'Filtrer les dimensions',
    filterSelectedTitleSingular: 'Filtre sélectionné',
    filterSelectedTitlePlural: 'Filtres selectionnés',
    filterOptionsOne: 'Récemment consultées',
    filterOptionsTwo: 'Coûts croissants',
    filterOptionsThree: 'Coûts décroissants',
    noChild: 'Aucune dimension enfant',
    noResult: 'Aucun résultat',
  },
}
