module.exports = {
  welcomeTitle: 'Welcome',
  welcomeSubtitle: 'What do you want to do today ?',
  pinModal: {
    title: 'Select a dimension',
    subText:
      'Your dimension will be selected by default the next time you log in',
  },
  editUserModal: {
    title: 'My Profile',
    subTitle: 'Configure your user',
    gravatar: 'Customize my gravatar',
    inputFirstName: 'First Name',
    inputLastName: 'Last Name',
    inputEmail: 'Email',
    selectLangue: 'Language',
  },
  editLogoModal: {
    tooltip: 'Add your logo',
  },
  granularity: {
    lastThirtyDay: 'The last thirty days',
    lastMonth: 'The last month',
    thisMont: 'This month',
    startYear: 'Start of the year',
    label: 'Period',
  },
  charts: {
    btn: 'Select a dimension',
    noData: 'No data available, please change the dimension or the time frame',
    values: 'Values',
  },
  kpi: {
    lastThirtyDay: 'Last thirty days',
    lastMonth: 'Last month',
    thisMont: 'This month',
    startYear: 'Year',
  },
  sectionsTitles: {
    expensesByDayTitle: 'Expenses by day',
    dimensions: 'Your child dimensions',
  },
  childDimension: {
    cost: 'Total cost',
    budget: 'Add budget',
    search: 'Search dimension',
    filterTitle: 'Filter dimensions',
    filterSelectedTitleSingular: 'Selected filter',
    filterSelectedTitlePlural: 'Selected filters',
    filterOptionsOne: 'Recently viewed',
    filterOptionsTwo: 'Ascending cost',
    filterOptionsThree: 'Descending cost',
    noChild: 'No child dimensions',
    noResult: 'No result found',
  },
}
