module.exports = {
  pageDescription:
    "Cette page vous permet de créer des catégories personnalisées et d'y associer des produits parmi ceux que vous consommez.",
  categories: 'Catégories',
  products: 'Produits',
  createCategory: 'créer une catégorie',
  categoryNamePlaceholder: 'Nom de la catégorie',
  save: 'enregistrer les modifications',
  search: 'Rechercher',
  name: 'Nom',
  category: 'Catégorie',
  provider: 'Fournisseur',
  action: 'Actions',
  updateProductTitle: 'Mettre à jour des produits',
  cancel: 'Annuler',
  confirmProductUpdate:
    'Nous allons modifier la liste des produits suivant pour leur associer la catégorie suivante',
  validate: 'Valider',
  defaultCategory: 'Catégorie par défaut',
  deleteCategoryTitle: 'Supprimer une catégorie',
  deleteCategoryDescription:
    "Attention, la catégorie que vous souhaitez supprimer est associée à un ou plusieurs produits. Les produits ci-dessous retrouveront leur catégorie par défaut si vous validez l'opération.",
  deleteCategoryConfirm: 'Êtes-vous sûr de vouloir continuer ?',
  stepOne:
    'Commencez par sélectionner le ou les produits dont vous souhaitez modifier la catégorie.',
  stepTwo:
    "Puis, cliquez sur une catégorie dans le tableau pour l'associer aux produits sélectionnés.",
  createCategoryTitle: 'Créer une catégorie',
  refreshDatabase: 'Agréger les données',
  reloadAggregateDescription:
    "Cliquez-ici pour lancer l'agrégation sur l'ensemble de vos données. Veuillez noter que ce processus peut durer plusieurs minutes et dépend directement du volume de vos données.",
  loadingCategoriesText: 'Vos catégories sont en cours de chargement...',
  loadingProductsText: 'Vos produits sont en cours de chargement...',
  noCategoriesText: 'Aucune catégorie disponible',
  noProductsText: 'Aucun produit disponible',
  catalogTitle: 'Catalogue du Cloud Public',
  catalogDescription:
    'Retrouvez tous les prix des instances du Cloud Public en France et ailleurs.',
  catalogFiltersTitle: 'Filtres',
  catalogFiltersDescription:
    'Affiner votre recherche de prix en filtrant les données.',
  catalogInstancesTitle: 'Instances',
  catalogInstancesDescriptionStart: 'Les prix affichés ont été mis à jour le ',
  catalogInstancesDescriptionEnd: ' par notre partenaire CloudMercato.',
  filterProviders: 'Choisissez un ou plusieurs fournisseurs',
  filterProvidersPlaceholder: 'Filtrez les fournisseurs',
  filterInstanceTypes: "Choisissez un ou plusieurs type d'instance",
  filterInstanceTypesPlaceholder: "Filtrer les type d'instance",
  filterZones: 'Choisissez une ou plusieurs régions',
  filterZonesPlaceholder: 'Filtrez les régions',
  filterCpu: 'Indiquez le nombre de CPU souhaité',
  filterCpuPlaceholder: 'Filtrez le nombre de CPU',
  filterArchitecture: "Indiquez l'architecture CPU souhaitée",
  filterArchitecturePlaceholder: 'Filtrer par architecture',
  filterOs: "Indiquez l'OS souhaité",
  filterOsPlaceholder: "Filtrer par système d'exploitation",
  filterMemory: 'Indiquez la quantité de RAM souhaitée',
  filterMemoryPlaceholder: 'Filtrer la mémoire',
  filterFooterTitle: "Vous souhaitez obtenir les prix d'autres fournisseurs ?",
  filterFooterDescription:
    'Contactez-nous à support@lota.cloud et nous étudierons votre demande.',
  lessFilters: 'Moins de filtres',
  moreFilters: 'Plus de filtres',
  catalogSearchBarPlaceholder:
    "Rechercher par nom d'instance, type, système d'exploitation...",
  pricePerHour: ' par heure',
  pricePerMonth: ' par mois',
  deprecated: 'Déprécié',
  new: 'Nouveau',
  unitPrice: 'Coût unitaire',
  startingFrom: 'à partir de ',
  reservable: 'Réservable',
  reservationStart: ' à ',
  reservationEnd: ' ans',
  technicalDetails: 'Caractéristiques techniques',
  numberOfCpu: 'Nombre de CPU',
  architecture: 'Architecture',
  memory: 'Mémoire',
  includedStorage: 'Stockage inclus',
  bandwidth: 'Bande passante',
  price: 'Tarifs',
  localisation: 'Localisation',
  hourlyPrice: 'Prix par heure',
  monthlyPrice: 'Prix par mois',
  reservationNoDeposit: 'Engagement sans acompte',
  reservationPartialDeposit: 'Engagement avec acompte partiel',
  reservationWithTotalDeposit: 'Engagement avec paiement complet',
}
