module.exports = {
  name: 'Nom',
  product: 'Produit',
  ratio: 'Ratio',
  totalCost: 'Coût total',
  share: 'Partager ce rapport',
  exportPDF: 'Exporter le rapport en PDF',
  exportCSV: 'Exporter les ressources en CSV',
  involvedResources: 'Liste des ressources concernées',
  resolution: 'Résolutions ',
  status: 'Statut',
  and: 'et ',
  recommandations: 'recommandations',
  toPause: 'Mettre en pause',
  toReactivate: 'Réactiver',
  toDelete: 'Supprimer',
  toCancel: 'Annuler',
  acquitted: 'Alarme acquitté le',
  triggered: 'Alarme déclenchée le',
  solved: 'Alarme résolue le',
  paused: 'Alarme mise en pause le',
  cancelled: 'Alarme annulée le',
  running: 'Alarme mise en route le',
  modifyName: 'Modifier le nom',
  newName: 'Nouveau nom',
  noActivity: "Pas d'activités sur cette alerte",
}
