export default {
  namespaced: false,
  state: () => ({
    newBudgets: [],
    refresh: false,
  }),

  getters: {
    getNewBudget: (state) => {
      return state.newBudgets
    },
    refresh: (state) => {
      return state.refresh
    },
  },

  actions: {
    setNewBudget: ({ commit }, payload) => {
      commit('setNewBudget', payload)
    },
    setNewBudgetAtBeginning: ({ commit }, payload) => {
      commit('setNewBudgetAtBeginning', payload)
    },
    setNewBudgetsArray: ({ commit }, payload) => {
      commit('setNewBudgetsArray', payload)
    },
    resetsNewBudgets: ({ commit }) => {
      commit('resetsNewBudgets')
    },
    deleteBudget: ({ commit }, payload) => {
      commit('deleteBudget')
    },
    setRefresh: ({ commit }, payload) => {
      commit('setRefresh', payload)
    },
  },

  mutations: {
    setNewBudget: async (state, payload) => {
      if (state.newBudgets.includes(payload)) {
        return
      }
      state.newBudgets.push(payload)
    },
    setNewBudgetAtBeginning: (state, payload) => {
      if (state.newBudgets.includes(payload)) {
        return
      }
      state.newBudgets.unshift(payload)
    },
    setNewBudgetsArray: (state, payload) => {
      const budgetsForAdd = payload.filter(
        (budget) =>
          !state.newBudgets.some(
            (existingBudget) => existingBudget.id === budget.id,
          ),
      )
      state.newBudgets = state.newBudgets.concat(budgetsForAdd)
    },
    deleteBudget: (state, payload) => {
      const index = state.newBudgets.findIndex(
        (budget) => budget.id === payload,
      )
      state.newBudgets.splice(index, 1)
    },
    resetsNewBudgets: (state) => {
      state.newBudgets = []
    },
    setRefresh: (state, payload) => {
      state.refresh = payload
    },
  },
}
