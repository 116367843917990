module.exports = {
  usEast1: 'US East (N. Virginia)',
  usEast2: 'US East (Ohio)',
  usWest1: 'US West (N. California)',
  usWest2: 'US West (Oregon)',
  afSouth1: 'Africa (Cape Town)',
  apEast1: 'Asia Pacific (Hong Kong)',
  apSouth1: 'Asia Pacific (mumbai)',
  apNortheast2: 'Asia Pacific (Seoul)',
  apSoutheast1: 'Asia Pacific (Singapore)',
  apSoutheast2: 'Asia Pacific (Sydney)',
  apNortheast1: 'Asia Pacific (Tokyo)',
  caCentral1: 'Canada (Central)',
  euCentral1: 'Europe (Frankfurt)',
  euWest1: 'Europe (Ireland)',
  euWest2: 'Europe (London)',
  euSouth1: 'Europe (Milan)',
  euWest3: 'Europe (Paris)',
  euNorth1: 'Europe (Stockholm)',
  meSouth1: 'Middle East (Bahrain)',
  saEast1: 'South America (São Paulo)',
}
