module.exports = {
  form: {
    rules: {
      email: 'Enter a valid e-mail address',
      emailMandatory: 'E-mail address is mandatory',
      password: 'Enter your password',
    },
  },
  or: 'OR',
  dialog: {
    success: {
      title: 'Unauthorized login link.',
      content:
        'A login link has been sent to your email. Please check your inbox and click the link to login.',
    },
    unauthorized: {
      title: 'Unauthorized access',
      content:
        'You are not authorized to receive a login link. Please contact your administrator or support for more information.',
    },
  },
}
