module.exports = {
  aws_bucket: 'Read: s3:ListBucket',
  aws_key: 'Access key ID',
  aws_object: 'Read: s3:GetObject',
  aws_permission: 'Read',
  aws_secret: 'Secret access key',
  box_report_1: '<code>Include resource IDs</code>',
  box_report_2:
    '<code>Automatically refresh your Cost & Usage Report when charges are detected for previous months with closed bills.</code>',
  bucket: 'Bucket',
  config: 'Configuration',
  container: 'Container',
  container_check: 'Access to the container with this string',
  help: 'Help for this step',
  help_bucket_1:
    'To obtain information relating to the bucket, go to your AWS account:',
  help_bucket_2: 'Add or view bucket',
  help_bucket_3:
    'We recommend using <b>us-east-1</b> region (North virginia) because it has the best rates.',
  help_bucket_4:
    'The expected region value (ex: us-east-1) can be found on browser <code>url</code> or in the AWS drop-down <code>Global</code> menu (top right).',
  help_bucket_5:
    'On your <code>S3</code> panel, find your bucket name or use button <b>Create bucket</b>.',
  help_bucket_6:
    'So that our system can find your Bucket, you must indicate the good region value associated on bucket.',
  help_bucket_7:
    'As a reminder, we need a <b>read</b> permission on the <code>S3</code> bucket. Return to the first step in case of error on this part.',
  help_connection_1: 'To obtain a Connection String, go to your Azure account:',
  help_connection_2: 'Add or configure a Cost Management',
  help_connection_3:
    'Our application needs a <b>Cost Management</b> saved in <b>Stockages</b> : create or choose a specifical <code>Container</code> and <b>Directory</v>',
  help_connection_4:
    'When your <b>Cost Management</b> is correctly created, go to your <b>Storage Account</b>. You can find the first <code>connection string</code> on <b>access key</b> section.',
  help_connection_5:
    'When creating your <b>Cost Management</b>, you chose a <code>Container</code> name. We need to know this name. Otherwise, the names are available in <b>Container</b> menu, section <b>BLOB Service</b>.',
  help_connector_1:
    'The connector name is optional. We will use it internally to give you better visibility. This will not impact your account.',
  help_connector_2: 'Before saving this connector, go to your AWS account:',
  help_connector_3: 'Create or configure a report for Lota',
  help_connector_4: 'When creating the report, you <b>must</b> checked this:',
  help_iam_1: 'To obtain information API access, go to your AWS account:',
  help_iam_2: 'Add or configure an user for Lota',
  help_iam_3:
    'Our application needs <b>Access by programming</b> with read <b>permissions/strategies</b>:<br /><code>AmazonS3ReadOnlyAccess</code>',
  help_iam_4:
    'To restrict read access more specifically, the minimum <b>actions</b> to set are :<br /><code>s3:GetObject</code> and <code>s3:ListBucket</code>.',
  help_iam_5: 'Sample bucket strategy with <b>lota.cloud-cur</b>',
  help_iam_6: 'Otherwise for experts, use a',
  help_iam_7:
    "associated with a specific bucket instead of full access 'AmazonS3ReadOnlyAccess'.",
  iam_message:
    'We need at least read access on <code>s3:GetObject</code> and <code>s3:ListBucket</code>.',
  iam_title: 'IAM (User)',
  report: 'Report',
  report_download: 'Download',
  string: 'Connection string',
  string_check: 'String coherence',
  string_message:
    "We need an Azure report. This is saved in an Azure Storage Account. Use an 'EA' account for a best experience with our application.",
  string_title: 'Connection',
  warn_action:
    'Check action IAM (Json): <code>"Action": [ "s3:Get*", "s3:List*" ]</code>',
  warn_resource: 'Check resource IAM (Json): <code>"Resource": "*"</code',
  warn_file:
    'Your documents must be hosted directly on <code>S3</code> (<b>Glacier</b> not supported)',
  createConnector: 'create your connector',
  aws: {
    introductionTitle: 'Introduction',
    introduction:
      'In order to allow our application to connect with your billing data, you will need to activate or set up:',
    introduction1:
      'A storage compartment (or bucket) which will allow the storage and reading of your Cost & Usage Reports.',
    introduction2:
      'An IAM user, with programmatic access to connect to your bucket',
    introduction3:
      'The export of invoicing via the CUR functionality of Amazon',
    introduction4:
      '(These configurations require administrator access on the AWS platform.)',
    bucket: 'Bucket',
    bucketTitle: 'set up your bucket',
    bucketText: 'Go to the AWS console, <b>S3</b> page.',
    bucketText1:
      'Bucket name: remember to write it down because you will be asked for it later.',
    bucketText2:
      'Region: we recommend using the US East region because it is the cheapest on Amazon (region code: us-east-1)',
    bucketTextLine:
      'Click <b>Create Bucket</b>, then fill in the fields in the creation dialog.',
    bucketLine1:
      'Click <b>Next</b>, in the bucket configuration page you can add tags. Add the <b>finops:lota</b> tag to be able to follow the activity of your billing exports and click <b>Next</b>.',
    bucketLine2:
      'In the <b>Block public access</b> section, leave the <b>Block all public access</b> option by default, this will protect your bucket from intrusions!',
    bucketLine3:
      'Click <b>Next</b> to check your bucket information, and click <b>Create bucket</b> to validate the operation.',
    user: 'User',
    userTitle: 'Configure your user',
    userText1:
      'From the AWS console, go to the <b>users</b> page and click <b>add user</b>. Name the <b>lotacloud</b> and check the box <b>programmatic access</b>.',
    userText2:
      'In the <b>Set permissions</b> section, click <b>Attach existing policies directly</b>. This should offer you a list of pre-fabricated policies. In the search bar, type <b>S3</b> and then select in the table the line <b>AmazonS3ReadOnlyAccess</b>. This policy will allow our <b>lotacloud</b> user to connect programmatically to your storage bucket to read your files.',
    userText3:
      'If you want to apply a policy via <b>JSON</b> use, here is what we recommend:',
    userJsonButton: 'Download JSON',
    userLine1:
      "Now click on <b>Next (tags)</b>, in order to configure the cost tags. You can add a <b>finops:lota</b> tag to track our user's usage.",
    userLine2:
      'Finally, click on <b>Next (review)</b> to check the user characteristics. Click on <b>Create user</b> to validate.',
    costReport: 'Cost & Usage Reports',
    costReportTitle: 'Configure your Cost & Usage Reports',
    costReportText1:
      'Go to the AWS console, <b>Cost Management</b> section and click on <b>Cost & Usage Reports</b> on the left. Then click on <b>Create report</b>.',
    costReportText2:
      'Enter a name for your report, call it for example <b>lotacloud</b> then check the <b>include resource IDs</b> box to allow us to obtain a report with a resource detail. Leave the <b>Automatically update your cost report</b> box checked by default. Click on <b>Next</b>.',
    costReportText3:
      'You must configure the S3 compartment in which your report will be saved. Click on <b>configure</b> and select the <i>bucket</i> that you created earlier. Click on <b>Next</b>.',
    costReportText4:
      'In the <b>time granularity</b> section, check the <b>daily</b> box then in the <b>report version management</b> section check the <b>replace report box existing</b>. Leave the compression format in <b>GZIP</b> by default and click on next in order to verify and validate the information of your new report. Finally, click on <b>Review and finish</b>.',
    bucket_name: 'Bucket name',
    bucket_region: 'Bucket region',
    IAM_Access_Key_ID: 'IAM Access Key ID',
    IAM_Secret_Key_ID: 'IAM Secret Key ID',
    CUR: 'Cost & Usage Report name',
    validate: 'Validate',
    validationText:
      'You should now have the necessary elements to add an AWS connector. Please enter below the information collected in the previous steps.',
  },
  google: {
    exportBilling: 'Export to Big Query',
    serviceAccount: 'Service Account Key',
    validate: 'Validation',
    prerequisitesLabel: 'Prerequisites',
    prerequisitesText:
      'Before you start the procedure of exporting your billing data, please check the following items:',
    prerequisite1:
      'You have the permission to <b>create a project</b> to store your billing data on Big Query.',
    prerequisite2:
      'You have the permission to <b>modify / associate the target project</b> with a billing account.',
    prerequisite3:
      'You have the permission to <b>enable Big Query Data Transfer and Cloud Billing</b> APIs.',
    prerequisite4:
      'You have the permission to <b>create a service account</b> for our application.',
    prerequisiteDocs:
      'For more information about exporting your data, the use and billing of Big Query, please refer to the Google Cloud documentation.',
    seeGoogleDocs: 'see google cloud documentation',
    exportBillingLabel: 'Export your billing data',
    exportBillingText:
      'In order to allow you to analyze your expenses at the finest level, we ask you to enable the export of billing data to a Big Query dataset. Here is a summary of the actions you will need to take to enable the export:',
    exportBilling1:
      'Create a project in which the Cloud Billing data will be stored, and activate billing on the project (unless you already did it)',
    exportBilling2:
      'Configure permissions on the project and on the Cloud billing account',
    exportBilling3:
      'Enable the BigQuery Data Transfer Service API (required to export your pricing data)',
    exportBilling4: 'Create a BigQuery dataset in which to store the data.',
    exportBilling5:
      'Enable Cloud Billing export of cost and pricing data to be written to the dataset',
    showGoogleTutorial: 'Export billing data to Big Query',
    exportBigQuery: 'Export to Cloud Storage',
    exportBigQueryLabel: 'Export Big Query data to a file',
    exportBigQueryText:
      'In order to simplify the recovery of your data but also to limit the costs of queries, we require you to create an export of your Big Query data to a Cloud Storage space. We recommend that you create a bucket specifically for this operation. We invite you to follow the procedure described in the following section:',
    exportBigQueryDoc: 'see google cloud documentation',
    serviceAccountLabel: 'Create a service account key',
    serviceAccountText:
      'In order to allow our application to access data previously exported to the Cloud Storage bucket, we ask you to create a service account key that we will use to collect reports. Click on the button below to access the service account management page.',
    serviceAccountDoc: 'see google cloud documentation',
    process: 'Procedure',
    exportBigQuery1: 'Open the <b>BigQuery</b> page in Cloud Console.',
    exportBigQuery2:
      'In the <b>Explorer</b> panel, expand your project and dataset, then <b>select the table that contains your billing data</b>.',
    exportBigQuery3:
      'In the details panel, click on <b>Export</b> and select <b>Export to GCS</b>.',
    exportBigQuery4:
      'For the field <b>Select Google Cloud Storage location</b>, search for the bucket (or compartment) in which you want to export the data. We remind you that it is preferable to create a bucket specifically for the operation.',
    exportBigQuery5:
      'For the field <b>Export format</b>, choose the format of the exported data: <b>JSON</b> (delimited by a line break).',
    exportBigQuery6:
      'In the <b>Compression</b> field, accept the default value (None) or choose <b>GZIP</b>.',
    exportBigQuery7: 'Click <b>Export</b> to export the table.',
    exportBigQueryEndTExt:
      'To check the progress of the task, see at the top of the <b>navigation window</b> the task history for an export task.',
    serviceAccountPage: 'Service account management page',
    serviceAccount1:
      'In the <b>Service account</b> field, select <b>New service account</b>.',
    serviceAccount2:
      'In the field <b>Service account name</b>, enter the name <b>lota</b>.',
    serviceAccount3:
      'In the <b>Role</b> field, select <b>Cloud Storage</b> and then <b>Storage Object Drive</b>.',
    serviceAccount4:
      'For the <b>Key type</b>, select <b>JSON</b> then click <b>Create</b>. Download the file containing the key in JSON format and keep it in a safe place.',
    validateLabel: 'Congratulations !',
    validateText:
      'You have completed the procedure for exporting your Google Cloud billing data. To validate the connection with our application, all that remains is to create your connector. In 3 steps :',
    validateConnectorName: 'Enter the name of your connector :',
    validateBucketName:
      'Enter the name of the Cloud Storage bucket that contains the files exported in step 2 :',
    validateServiceAccount:
      'Download the file containing the service account key that we created in step 3 :',
    uploadJsonHere: 'Upload your JSON file here',
    addGoogleCloudConnector: 'Add a Google Cloud connector',
    success:
      'Your connector has been created! We have tested the connection with your Cloud Storage space and we will proceed to an import of your data in the next few hours.',
    failure:
      'We were not able to connect to your storage space! Please check the information entered and the validity of the service account key. If the problem persists, please contact us at support@lota.cloud.',
    introductionLabel: 'Introduction',
    introductionText:
      'The purpose of this process is to allow you to use your billing data through our platform. In order to get read access to your Cloud Billing data, you will need to <b>activate the export of your billing data</b> to BigQuery and then <b>create an export</b> of this data on a Cloud storage space. Finally, to allow us to log in, you will need to provide us with a <b>service account key</b> that we will use for authentication.',
    serviceAccountKeyFile: 'Your Service Account Key File',
    projectId: 'Project ID',
    privateKeyId: 'Private Key ID',
    clientEmail: 'Client Email',
    clientId: 'Client ID',
    serviceAccountKeyError:
      'The downloaded file is not compliant, please check that it is a JSON file and that it is not corrupted.',
    requiredField: 'This field is required.',
    max26Characters: 'Max 26 characters.',
    starting: 'Get started',
    step: 'Step',
  },
  oracle: {
    addOracleConnector: 'Add a Oracle Cloud connector',
    introductionLabel: 'Introduction',
    introductionText:
      'The purpose of this process is to allow you to use your OCI billing data and to automate the update of your data through Lota.cloud interface. To get your data, we will need to create a user, a group and give them the necessary rights to read the billing file on the Oracle infrastructure.',
    prerequisitesLabel: 'Prerequisites',
    prerequisitesText:
      'Before starting the procedure for exporting your billing data, please check the following points:',
    prerequisite1:
      'You have administrator rights on the Oracle account you want to connect.',
    prerequisite2: 'You have access to Cloud Shell OCI.',
    scriptExecutionLabel: 'Script execution',
    scriptExecutionText:
      'To read your billing data, Lota.cloud connects to the bucket containing your expense reports. We will use an IMA user who will be granted specific rights (read only).',
    scriptExecution1: 'Creating user «finops-lota».',
    scriptExecution2: 'Creating group «finops-lota-group».',
    scriptExecution3: 'Adding user «finops-lota» to the group.',
    scriptExecution4:
      ' Adding policies to the group (reading billing files and monitoring).',
    scriptExecution5:
      ' Sharing the public key as a means of user authentication.',
    scriptExecution6: 'Region selection (home region).',
    scriptExecution7: 'Save and display connection information in the console.',
    downloadScript: 'Download the script',
    scriptContentLabel: 'Script content',
    scriptlaunchLabel: 'Running the script',
    scriptlaunchText:
      'Drag and drop the lota-cloud-oracle-setup file into Cloud Shell previously downloaded and run it by entering the following command in Cloud Shell: bash lota-cloud-oracle-setup',
    enterLoginDetailsLabel: 'Login details',
    enterLoginDetailsText:
      'The previously executed script should return you the connection information saved in the lota.config file. We will use this information to log into the Oracle Cloud billing bucket. ',
    cleaningLabel: 'Cleaning',
    cleaningText:
      'You can delete the lota-cloud-oracle-setup script if you wish, but we recommend that you do not delete the configuration file until your connector is fully active (data downloaded).',
    requiredField: 'This field is required.',
    max26Characters: 'Max 26 characters.',
    validate: 'Validation',
    congratulationLabel: 'Congratulations !',
    congratulationText:
      'You have completed the process of adding an Oracle Cloud Infrastructure connector. Our import system takes over to import and standardize your data. You will be able to consult your data in the next few hours.',
    connectorName: 'Name of the connector',
  },
  connectionString: 'Connection string',
}
