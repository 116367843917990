module.exports = {
  header: {
    title: 'Explorateurs',
    cloud: 'Cloud',
    kubernetes: 'Kubernetes',
    carbon: 'Carbon',
    filters: 'Filtres',
    from: 'Du',
    to: 'au',
  },
  periods: {
    last7Days: '7 derniers jours',
    last30Days: '30 derniers jours',
    last60Days: '60 derniers jours',
    last90Days: '90 derniers jours',
    last6Months: '6 derniers mois',
    last12Months: '12 derniers mois',
    thisYear: 'Cette année',
    lastYear: "L'année dernière",
    lastMonth: 'Le mois dernier',
    select: 'Sélectionnez une période :',
    update: 'Modifier la période',
    manual: 'Ou sélectionnez les dates manuellement :',
  },
  groups: {
    name: 'Nom',
    provider: 'Fournisseur',
    category: 'Catégorie',
    product_code: 'Produit',
    service_code: 'Service',
    region: 'Région',
    resource_id: 'ID de ressource',
    resource_path: 'ID de ressource',
    pricing_model: 'Modèle de tarification',
    usage_account_id: 'Compte',
    usage_type: 'Consommation',
    usage_quantity: 'Quantité consommée',
    usage_unit: 'Unité',
    original_cost: 'Coût HT (avant conversion)',
    conversion_rate: 'Taux de change',
    tags: 'Tags',
    cost_center: 'Centre de coût',
    costCenter: 'Centre de coût',
    cluster_id: 'ID de cluster',
    namespace: 'Espace de nom',
    item_type: 'Type de charge',
    pod: 'Pod',
    container: 'Conteneur',
    controller_king: 'Type de contrôleur',
    controller_name: 'Nom du contrôleur',
    labels: 'Labels',
    cost: 'Coût HT',
    groupCostsBy: 'Regrouper les coûts',
    defaultGroups: 'Groupes par défaut',
    customGroups: 'Groupes personnalisés',
    noResults: 'Aucun résultat.',
    searchGroup: 'Rechercher un groupe',
  },
  chart: {
    title: 'Coût par',
    search: 'Rechercher un',
    subtitle: 'Prix HT affichés en',
    totalCost: 'Coût total HT',
    others: 'Autres',
    othersDescription:
      'Les coûts inférieurs à 1% du montant total sont regroupés dans la catégorie "Autres".',
    organizationChartDescription:
      'Les coûts sont regroupés par sous-dimension. Pour un affichage optimale, nous vous conseillons de ne pas créer plus de 4 niveaux de sous-dimensions.',
    organizationChartEmpty: "Cette dimension n'a pas encore de sous-dimension.",
    options: 'Options du graphique',
    type: 'Type de graphique',
    otherOptions: 'Autres options',
    logarithmic: 'Échelle logarithmique',
    costDisplayedIn: 'Coûts hors taxes affichés en',
    andBy: 'et par',
    granularity: 'Granularité',
  },
  table: {
    unassigned: 'Non assigné',
    options: 'Options du tableau',
    addColumns: 'Ajouter des colonnes',
    costDisplayedIn: 'Coûts hors taxes affichés en',
  },
  export: {
    title: 'Export des données',
    description: 'Vous pouvez exporter les données au format CSV.',
    table: 'Données de tableau',
    chart: 'Données de graphique',
    separator: 'Séparateur de décimale',
    delimiter: 'Délimiteur',
    download: 'Télécharger',
    waitingForDownload:
      'Cliquez sur le bouton ci-dessous pour démarrer le téléchargement du fichier.',
    finishingDownload: 'Votre téléchargement démarre...',
    errorDecimalAndSeparator:
      'Vous ne pouvez pas utiliser le même caractère pour le délimiteur et le séparateur décimal.',
    separatorLabel: 'Séparateur de décimale',
    delimiterLabel: 'Délimiteur',
    exportOptions: "Options d'export",
  },
}
