module.exports = {
  demo: 'Mode Demo.',
  visitOurWebsite: 'Visitez notre site web.',
  privacyPolicy: 'Politique de confidentialité.',
  generalConditionsOfUse: "Conditions générales d'utilisation.",
  createAGravatar: 'Personnaliser mon Gravatar',
  gravatar: 'https://fr.gravatar.com/',
  cgu: 'Télecharger nos CGU',
  costAndUsageAzure:
    'https://docs.microsoft.com/fr-fr/azure/cost-management-billing/understand/download-azure-daily-usage',
}
