module.exports = {
  tabs: {
    summary: 'Summary',
    optimizations: 'Optimizations',
    metrics: 'Metrics',
    settings: 'Settings',
  },
  chips: {
    optimizable: 'Can be optimized',
  },
  date: '%s spent between %s and %s',
  empty: 'Select a resource to view details',
  metrics: {
    noData: 'No data available for the selected date range',
    cpuTitle: 'CPU usage in percentage',
    networkTitle: 'Network usage in bytes',
    basedDays: 'Based on the last 30 days',
    maximum: 'Maximum',
    average: 'Average',
  },
  optimizations: {
    noData: 'There are no recommendations for this resource.',
  },
  search: {
    resource: 'Search for a resource',
    title: 'Search settings',
    dateLabel: 'Date range',
    onlyOptimizable: 'Resources with optimizations',
    onlyMetrics: 'Resources with metrics',
    metrics: 'Metrics',
    optimizations: 'Optimizables',
  },
  settings: {
    descriptionTitle: 'Resource description',
    descriptionLongTitle:
      'Sometimes, a resource ID is not enough to identify a resource. Adding a description will help you to identify it.',
    description: 'Description',
    descriptionHint:
      'A short description of the resource, ideally less than 50 characters',
    save: 'Save',
    descriptionSaved: 'The description has been saved',
    descriptionError: 'An error occurred while saving the description',
    externalTags: 'External tags',
    externalTagsLongTitle:
      'External tags are provided by your cloud provider. Actually, we are not able to edit them directly from Lota.cloud. This table shows all tags, even non-enabled ones.',
    key: 'Key',
    value: 'Value',
  },
  summary: {
    serviceTitle: 'Services and usages',
    information: 'Information',
    description: 'Description',
    resourceId: 'Resource ID',
    resourcePath: 'Resource path',
    provider: 'Provider',
    yAxisTitle: 'Cost in %s',
  },
  table: {
    noResults: 'We could not find any resource matching your search',
    noMoreResults: 'You have reached the end of the list',
  },
}
