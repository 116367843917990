// get the navigator language for chrome and firefox
import store from '@/store'
import defaultEn from '../../lang/default-en.js'
import defaultFr from '../../lang/default-fr.js'

const languages = {
  fr: defaultFr,
  en: defaultEn,
}

export function useNavigatorLanguage() {
  console.info('Using navigator language', store.state.user.lang)
  return navigator.language
}

export function useUserLanguage() {
  console.info('Using user language', store.state.user.lang)
  return store.state.user.lang
}

export function useDefaultLanguage() {
  console.info('Using default language', store.state.user.lang)
  return 'fr'
}

export function useLanguage() {
  return useUserLanguage() || useNavigatorLanguage() || useDefaultLanguage()
}

export function useLocaleSentryOptions() {
  const locale = useLanguage()
  const { userFeedback } = languages[locale].sentry

  return {
    buttonLabel: userFeedback.buttonLabel,
    submitButtonLabel: userFeedback.submitButtonLabel,
    cancelButtonLabel: userFeedback.cancelButtonLabel,
    formTitle: userFeedback.formTitle,
    nameLabel: userFeedback.nameLabel,
    namePlaceholder: userFeedback.namePlaceholder,
    emailLabel: userFeedback.emailLabel,
    emailPlaceholder: userFeedback.emailPlaceholder,
    messageLabel: userFeedback.messageLabel,
    messagePlaceholder: userFeedback.messagePlaceholder,
    successMessageText: userFeedback.successMessageText,
  }
}
