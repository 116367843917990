module.exports = {
  pageDescription:
    'This page allows you to create custom category that you can associate with existing products.',
  categories: 'Categories',
  products: 'Products',
  createCategory: 'create a category',
  categoryNamePlaceholder: 'new category',
  save: 'save changes',
  search: 'Search',
  name: 'Name',
  category: 'Category',
  provider: 'Provider',
  action: 'Actions',
  updateProductTitle: 'Update products',
  cancel: 'Cancel',
  confirmProductUpdate:
    'We are going to update this products with the category',
  validate: 'confirm',
  defaultCategory: 'Default category',
  deleteCategoryTitle: 'Delete a category',
  deleteCategoryDescription:
    'Please note that the category you wish to delete is associated with one or more products. The products below will be returned to their default category if you validate the operation.',
  deleteCategoryConfirm: 'Are you sure ?',
  stepOne:
    'Start by selecting the product(s) whose category you want to change.',
  stepTwo:
    'Then click on a category in the table to associate it with the selected products.',
  createCategoryTitle: 'Create a category',
  refreshDatabase: 'aggregate data',
  reloadAggregateDescription:
    'Click here to start the aggregation on all your data. Please note that this process can take several minutes and depends directly on the volume of your data.',
  loadingCategoriesText: 'Loading categories...',
  loadingProductsText: 'Loading products...',
  noCategoriesText: 'No category found',
  noProductsText: 'No product found',
  catalogTitle: 'Public Cloud Catalog',
  catalogDescription:
    'Find all the prices of the Public Cloud instances in France and elsewhere.',
  catalogFiltersTitle: 'Filters',
  catalogFiltersDescription: 'Refine your price search by filtering the data.',
  catalogInstancesTitle: 'Instances',
  catalogInstancesDescriptionStart: 'Price list has been updated on ',
  catalogInstancesDescriptionEnd: ' by our partner CloudMercato.',
  filterProviders: 'Select one or many providers',
  filterProvidersPlaceholder: 'Filter by provider',
  filterInstanceTypes: 'Select one or many instance types',
  filterInstanceTypesPlaceholder: 'Filter instance types',
  filterZones: 'Select one or many regions',
  filterZonesPlaceholder: 'Filter regions',
  filterCpu: 'Specify the number of CPUs required',
  filterCpuPlaceholder: 'Filter by CPU count',
  filterArchitecture: 'Indicate the desired CPU architecture',
  filterArchitecturePlaceholder: 'Filter by CPU architecture',
  filterOs: 'Indicate the desired OS',
  filterOsPlaceholder: 'Filter by operating systems',
  filterMemory: 'Indicate the desired RAM quantity',
  filterMemoryPlaceholder: 'Filter by memory size',
  filterFooterTitle: 'You wish to obtain prices from other Cloud providers ?',
  filterFooterDescription:
    'Contact us at support@lota.cloud and we will consider your request.',
  lessFilters: 'Less filters',
  moreFilters: 'More filters',
  catalogSearchBarPlaceholder:
    'Search for an instance name, type, operating system...',
  pricePerHour: ' per hour',
  pricePerMonth: ' per month',
  deprecated: 'Deprecated',
  new: 'New',
  unitPrice: 'Unit price',
  startingFrom: 'starting from ',
  reservable: 'Reservable',
  reservationStart: ' to ',
  reservationEnd: ' years',
  technicalDetails: 'Technical details',
  numberOfCpu: 'Number of CPU',
  architecture: 'Architecture',
  memory: 'Memory',
  includedStorage: 'Included storage',
  bandwidth: 'Bandwidth',
  price: 'Price',
  localisation: 'Localisation',
  hourlyPrice: 'Price per hour',
  monthlyPrice: 'Price per month',
  reservationNoDeposit: 'Reservation without deposit',
  reservationPartialDeposit: 'Reservation with partial deposit',
  reservationWithTotalDeposit: 'Reservation with total deposit',
}
