module.exports = {
  addNewConnector: 'Ajouter un connecteur',
  applyFilters: 'Appliquer les filtres',
  back: 'Retourner en arrière',
  cancel: 'Annuler',
  close: 'Fermer',
  continue: 'Continuer',
  createUserShare: "Partager l'explorer",
  demo: 'Tester (Demo)',
  download: 'Télécharger',
  failure: 'Echec',
  getMyPassword: 'Récupérer mon accès',
  changeMyPassword: 'Changer mon mot de passe',
  help: 'Aide',
  helpVideo: "Vidéo d'aide",
  logIn: 'Utiliser un mot de passe',
  next: 'Suivant',
  returnToHomePage: "Revenir à la page d'accueil",
  save: 'Enregistrer',
  selectPicture: 'Sélectionner une photo',
  showInExplorer: "Voir dans l'Explorateur",
  share: 'Partager',
  signUp: "S'inscrire",
  success: 'Succès',
  testConnector: 'Tester le connecteur',
  update: 'Mettre à jour',
  validate: 'Valider',
  costAndUsage: 'Page de rapports, frais et utilisation',
  seeUsages: 'Voir la consommation',
  csvExport: 'Export en CSV',
  pdfExport: 'PDF',
  generateShareLink: 'Generer un lien de partage',
  export: 'exporter',
  addUser: 'Ajouter un utilisateur',
  createBudgetGroup: 'creer un groupe de budget',
  createBudget: 'créer un budget',
  deleteBudget: 'Supprimer ce budget ',
  shareBudget: 'Partager ce budget',
  updateBudget: 'Modifier ce budget ',
  confirm: 'Confirmer',
  delete: 'Supprimer',
  seeInExplorer: "Voir dans l'explorateur",
  seeResources: 'Voir les ressources',
  revoke: 'Retirer',
  exportChart: 'Exporter les données du graphique',
  exportTable: 'Exporter les données du tableau',
  resetPassword: 'Réinitialiser le mot de passe',
  createRecommendation: 'Créer une recommandation',
  deleteRecommendation: 'Supprimer cette recommandation',
  modifyRecommendation: 'Modifier cette recommandation',
  modify: 'Modifier',
  createRecommendationGroup: 'Créer un groupe de recommandations',
  deleteRecommendationGroup: 'Supprimer le groupe de recommandations',
  deleteDashboard: 'supprimer le tableau de bord',
  shareDashboard: 'Partager ce tableau de bord',
  authorize: 'Autoriser',
  shareRecommendation: 'Partager cette recommandation',
  showActivties: 'Activités',
  detachTile: 'Détacher la tuile',
  seeBudget: 'Voir ce budget',
  previous: 'Précédent',
  renew: 'Renouveler',
  renewalBudgets: 'Renouveler des budgets',
  logInSingleSignOn: 'Se connecter avec le SSO',
}
