module.exports = {
  title: 'Liste des partages',
  card: {
    headers: {
      name: 'Nom du partage',
      filters: 'Filtres',
      email: 'E-mail',
      expirationDate: "Date d'expiration",
      actions: 'Actions',
    },
  },
  passwordForm: {
    labelPassword: 'Entrez le mot de passe pour accéder à cette page',
  },
  form: {
    title: 'Remplissez les informations pour partager vos données',
    titleEdit: 'Modifier les données de votre partage',
    filterTitle: 'Les filtres suivants seront appliqués à la page partagée',
    labelName: 'Nom du partage',
    labelEmail: 'E-mail (optionnel)',
    labelExpirationDate: "Date d'expiration du partage",
    fieldRules: {
      name: {
        required: 'le nom est obligatoire',
        length: 'Le nom doit contenir moins de 15 caractères',
      },
      email: {
        isEmail: 'Entrez une adresse mail valide',
      },
    },
    labelPassword: 'Mot de passe',
    url: 'URL',
    message: {
      password:
        "Voici votre partage, conservez bien ces information qui ne seront affichées qu'une seule fois",
    },
  },
  costExplorer: 'Explorateur de dépenses',
  shareFeature: 'Fonctionnalité de partage',
  noFilters: 'Aucun filtre ajouté',
  noExpirationDate: "Pas de date d'expiration",
  noEmail: 'Aucun e-mail ajouté',
  deleteShare: 'Supprimer ce lien',
  goToExplorerToCreateShareLink:
    'Pour créer un nouveau lien, utilisez la fonction "partager" de notre ',
  permanentLink: 'Lien permanent',
  seeInExplorer: "Voir dans l'Explorateur",
  totalCostLast30Days: 'Coût HT 30 Jours',
  nobodyCanBeAssigned: 'Personne ne peut être assignée.',
  nobodyAssigned: "Personne n'est assignée pour le moment.",
  shareDashboard: 'Partager le tableau de bord',
  shareDashboardDescription:
    "Partagez votre tableau de bord avec d'autres utilisateurs",
}
