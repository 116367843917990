module.exports = {
  title: "Share's list",
  card: {
    headers: {
      name: 'Name',
      filters: 'Filters',
      email: 'E-mail',
      expirationDate: 'Expiration date',
      actions: 'Actions',
    },
  },
  passwordForm: {
    labelPassword: 'Enter the password to access to this page',
  },
  form: {
    title: 'Fill the form as you can share the data',
    titleEdit: 'Modify the data of your share',
    filterTitle: 'This filters will be applied to the shared page',
    labelName: "Share's name",
    labelEmail: 'E-mail (optional)',
    labelExpirationDate: "Share's expiration date",
    fieldRules: {
      name: {
        required: 'Name is mandatory',
        length: 'Name must be less than 15 characters',
      },
      email: {
        isEmail: 'Enter a valid mail address',
      },
    },
    labelPassword: 'Password',
    url: 'URL',
    message: {
      password:
        'Here is your share, keep it well it will only be displayed once',
    },
  },
  costExplorer: 'Cost Explorer',
  shareFeature: 'Sharing feature',
  noFilters: 'No filters',
  noExpirationDate: 'No expiration date',
  noEmail: 'No email',
  deleteShare: 'Delete this link',
  goToExplorerToCreateShareLink:
    'To create a new link, use the share feature of our ',
  permanentLink: 'Permanent link',
  seeInExplorer: 'Show in Explorer',
  totalCostLast30Days: 'Total cost without taxes 30 days',
  nobodyCanBeAssigned: 'Nobody can be assigned.',
  nobodyAssigned: 'Nobody is assigned yet.',
  shareDashboard: 'Share dashboard',
  shareDashboardDescription: 'Share your dashboard with other users',
}
