/**
 * Custom icon names
 * use to redefine mdi-icons name with vuejs filters
 * see filters.js and format.js for methods and calls
 */
module.exports = {
  aws: 'mdi-aws',
  azure: 'mdi-azure',
  ovh: 'mdi-plug',
  gcp: 'mdi-google',
}
