module.exports = {
  access: 'Increase your offer to access this section',
  alertDeleteSuccess: 'The alert has been successfully deleted.',
  alertDeleteError: 'An error has occurred during the alert deletion.',
  badMethod: "Bad Method, use only 'get', 'post', 'put' or 'delete'",
  billNotFound: 'No bill has been found',
  comingSoon: 'Coming soon',
  connectorDeleteConfirm: 'Are you sure you want to delete this connector?',
  connectorDeleteError: 'An error has occurred during the connector deletion',
  connectorDeleteSuccess: 'The connector has been successefuly deleted',
  connectorEditError:
    'An error occurred during the modification of the connector name',
  connectorEditSuccess: 'Your connector name has been successfully modified',
  connectorRename: 'Rename a connector',
  errorNotFound: 'This element does not exist',
  errorEncountered: 'An error occured',
  hello: 'Hello',
  modalOfferOutdated:
    'It seems that your trial period has expired lately, in order to access again to our services, please subscribe an offer',
  modalOfferOutdatedTitle: 'There is a problem',
  notificationConnector:
    'Connector created, data will be available in a few hours',
  notificationMessage: 'Successful done',
  notificationTitle: 'Success',
  passwordRecoverySuccess:
    'You are going to recieve an email allowing you to get a brand new access to our services.',
  passwordRecoveryTitle: 'Email sent 👌',
  paymentSuccess:
    'We have successfully registered your payment, your account will be activated very soon',
  settingLoading: 'Loading connectors...',
  settingLoadingOver: 'select a provider to create a connector.',
  updateAction: 'Action required',
  updateLang: 'Validate this form to apply the change',
  updatePassword: 'Change your password',
  warning: 'Warning!',
  info: 'Information',
  incorrectPassword: 'Incorrect Password !',
  share: {
    updateOK: 'Successfully modified informations !',
    deleteOK: 'You have deleted your share !',
  },
  shareNotFound: 'Go to our explorator page to create a new shareable content',
  usageChartHiddenCost:
    'For your convenience, usages with cost lower than 5% of total resource cost are hidden. Click on the legend to enable them.',
  itemPerPage: 'Item per page',
  billingCurrencyExplanation:
    'The amounts shown on each invoice are displayed in your supplier\'s currency. We do not convert the data on the "invoicing" aspect to allow the invoices to be as close as possible to those of the suppliers.',
  alertCurrencyExplanation:
    'We use the original currency of your billing for alert detection.',
  dashboardCurrencyExplanation:
    'You can customize the currency in which your expenses are displayed from your profile settings. We instantly convert the cost excluding VAT with current exchange rates.',
  explorerCurrencyExplanation:
    'Expenses displayed in the explorer in a different currency than the one you set up are instantly converted using the current exchange rates.',
  addUser: 'Add a new user',
  resetPassword: 'Reset the password?',
  updateUser: 'User information update',
  updateCompany: 'Company information update',
  confirmPasswordChange:
    "Are you sure to reset the password?\n We'll send a mail to %s with a link to change the password.",
  resetPasswordError: 'Cannot reset password.',
  resetPasswordSuccess: 'A mail to reset the password has been sent.',
  resourceNameClipBoard: 'Resource name copied to clipboard!',
  deleteScopeText: 'Are you sure you want to delete this filter ?',
  deleteScopeSubText:
    'All the related data will be deleted also (budgets, recommendations, tuiles, etc.).',
  emptyField: 'All fields must be completed',
}
