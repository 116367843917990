module.exports = {
  actions: 'Actions',
  addConnector: 'Add a connector',
  amazonWebServicesDescription: 'Import your Amazon Web Services billing data.',
  amazonWebServicesDescriptionDelete:
    'Delete the connection with your AWS account.',
  amazonWebServicesDescriptionEdit:
    'Edit your AWS account authentication credentials.',
  article: 'Article',
  authenticating: 'Connection',
  authenticating_success: 'Connection succeed',
  available: 'Available',
  availableSoon: 'Available soon',
  awsBill: 'Amazon AWS Invoice',
  awsFullName: 'Amazon Web Services',
  azureFullName: 'Microsot Azure',
  bill: 'Invoice',
  billed_period: 'Billing period',
  categories: 'Categories',
  checkTheFollowingCheckboxes: 'Check the following checkboxes : ',
  company: 'Company',
  connectorList: 'Your connectors list',
  userList: 'Users',
  viewerList: 'Your viewers List',
  connector_list: 'Connector',
  connectorsListCreatedAt: 'Created at : ',
  connectorsListDelete: 'Delete the connector',
  connectorsListEdit: 'Edit the title',
  connectorsListProvider: 'Provider : ',
  cost: 'Cost',
  costDistribution: 'Cost distribution',
  costPerProvider: 'Cost per provider',
  currentMonth: 'This month',
  currentMonthEstimation: 'Current month',
  currentYear: 'This year',
  date: 'Date',
  default: 'Default text to show when nothing comes out',
  deleteConnector: 'Delete a connector',
  doing: 'Doing...',
  editConnector: 'Edit a connector',
  editMyProfile: 'Edit my profile',
  emailValidationSubTitle:
    'Please check your inbox in order to get your activation code.',
  emailValidationTitle:
    'We sent a verification email to the email account you provided :',
  endDate: 'End date',
  expenseEstimation: 'Expense estimation',
  explorerInfoHidden:
    'For your convenience, we have disguised spending less than 1% of total expenses.',
  explorerDialogTitle: 'Resource details',
  forLastXDaysEnd: ' days',
  forLastXDaysStart: 'last ',
  from: 'From',
  gcpFullName: 'Google Cloud Platform',
  googleCloudPlatformDescription:
    'Import your Google Cloud Platform billing data.',
  identity: 'Identity',
  invoiceId: 'Invoice ID',
  itemsPerPage: 'Rows',
  itemsPerPageAll: 'All',
  lastImportDayIs: 'Last import date: ',
  lastMonth: 'Last month',
  logout: 'Log out',
  microsoftAzureDescription: 'Import your Microsoft Azure billing data.',
  oracleDescription: 'Import your Oracle billing data.',
  myAccount: 'My account',
  nextMonthEstimation: 'Next month',
  newScope: 'New filter',
  loadScope: 'Load a filter',
  noDataText: 'No data to display',
  numberOfConnectors: 'Number of connectors: ',
  numberOfImports: 'Number of imports: ',
  of: 'of',
  options: 'Options',
  ourBlog: 'Lota.cloud blog',
  passwordForgotten: 'Password forgotten ?',
  paymentInfo: 'Show payment information',
  pleaseCreateYourAccount: 'Welcome, please create an account',
  pleaseLogin: 'Welcome, please log in',
  successNewPassword: 'Your Password has been updated, please log in',
  pleaseSetupYourCompany: 'Please fill in your company information',
  productAndCategories: 'Products & Categories',
  products: 'Products',
  profile: 'Profile',
  provider: 'Provider',
  resourceUsage: 'Consumption',
  seeMyBills: 'See my bills',
  startDate: 'Start date',
  tags: 'Tags',
  to: 'to',
  topCategories: 'Top 5 categories',
  topCategoriesShortened: 'Top categories',
  topProducts: 'Top 5 products',
  topProductsShortened: 'Top products',
  total: 'Total',
  totalExpenses: 'Total expenses',
  totalExpensesPerProvider: 'Total expenses per provider',
  user_list: 'User',
  canView: 'Can view',
  providerTotalCost: 'Cost per provider',
  top5Expenses: 'Top 5 expenses',
  show: 'Show',
  inExplorer: 'in Explorer',
  inTagExplorer: 'in tags Explorer',
  allExpenses: 'all expenses',
  represents: 'represents',
  ofTheTotalCostTop: 'of the total cost of your 5 most expensive',
  mostExpensive: '.',
  categoryTop: 'categories',
  productTop: 'products',
  connector: {
    aws: {
      accessKeyDescription:
        'Enter the login details of your newly created IAM user',
      accessKeyTitle: 'API access keys',
      accessTypeDescription:
        "Select 'Access by programming' and click 'Next'. In 'Permissions', click on 'Attach existing policies directly'.",
      accessTypeTitle: 'Type of access',
      activateCostAllocationTagsTitle: 'Activate the tags',
      activeCostAllocationTagsDescription:
        "Go to the 'Cost allocation tags' page and click on 'Load your tags' then 'Select' and 'Activate'.",
      associateStrategyDescription:
        "Associate the user 'apilota' to the strategy 'accessapilota'.",
      associateStrategyTitle: 'Associate the strategy to the user',
      bucketDescription: 'Create an S3 Bucket to save your billing reports',
      bucketTitle: 'Save your report on S3',
      checkboxAutoUpdateReport:
        'Automatically update your cost report when charges from previous months related to closed invoices are detected',
      checkboxConfirmStrategy: "I've confirmed that this strategy is correct",
      checkboxIncludeResourceId: 'Include resource IDs',
      configureBucketDescription:
        'We will use this compartment to access your billing reports.',
      configureBucketTitle: 'Configure distribution options',
      confirmStrategyTitle: 'Check the strategy',
      connectorNameDescription:
        'We will use this name internally to give you better visibility. This will not impact your AWS account.',
      connectorNameTitle: 'Name your connector',
      costAllocationTagsDescription:
        'Cost allocation tags make it easier to identify the origin of expenses',
      costAllocationTagsLink: 'COST ALLOCATION TAGS PAGE',
      costAllocationTagsTitle: 'Activating Cost Distribution Tags',
      createIamRoleDescription:
        "In the 'IAM' tab, go to 'Users' and click on 'Add'.",
      createIamRoleLink: 'IAM USERS PAGE',
      createIamRoleTitle: 'Create an IAM role',
      createIamStrategyDescription:
        "Click on 'Create a strategy' in JSON format. Copy and paste the following code:",
      createIamStrategyTitle: 'Create an IAM user strategy',
      createS3BucketDescription:
        'Create an S3 compartment and name it as follows : ',
      createS3BucketTitle: 'Create an S3 Bucket',
      defineS3RegionDescription:
        'Choose a region (we recommend that you choose the US East (Virginia) region which is the cheapest)',
      defineS3RegionTitle: 'Define a region',
      fail: 'Connector created and test failed. Please verify the information entered.',
      iamDescription:
        'This user will allow us to read your billing and usage data via AWS APIs.',
      iamRoleDescription:
        'This user will allow us to connect to compartment S3 and read your billing reports.',
      iamRoleNameDescription: 'Name the IAM user as follows: apilota',
      iamRoleNameTitle: 'Name of the user',
      iamRoleTitle: 'Create an IAM role',
      iamTitle: 'Creating an IAM user',
      reportDescription:
        'Generate a Cost and Usage billing report to allow us to read your billing information',
      reportDocDescription:
        "To create a billing report, go to the 'Billing' section and click on 'Cost&Usage Reports'. Then click on 'Create a report'. Name the report as follows: ",
      reportDocTitle: 'Create a Cost & Usage report',
      reportLinkTitle: 'COST&USAGE REPORTS PAGE',
      reportTitle: 'Create an AWS Billing Report',
      strategyNameDescription: 'Name the strategy as follows: accessapilota',
      strategyNameTitle: 'Name of the strategy',
      success:
        'Connector created and successfully tested. We will send you an email to notify you of the first data import.',
      verifyYourConnector:
        'We need to check the connection information of your new AWS connector. This ensures that we will have access to billing reports once they are generated by Amazon.',
    },
  },
  created_by: 'Created by ',
  on: ' on ',
  reportList: 'Import history',
}
