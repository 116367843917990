import HttpClient from '@/services/base'

export default class User extends HttpClient {
  constructor() {
    super()
  }

  async switchCompany({ userId, companyId }) {
    return await this.request({
      url: `/user/${userId}/${companyId}`,
      method: 'PATCH',
    })
  }

  async checkEmailToken(params) {
    return await this.request({
      url: '/emailToken',
      method: 'POST',
      data: {
        user: params.user,
        token: params.token,
      },
      headers: {
        Authorization: null,
      },
    })
  }

  async logIn(params) {
    return await this.request({
      url: `/system/${params.provider}/login`,
      method: 'POST',
      headers: {
        Authorization: null,
      },
      data: params.data,
    })
  }

  async getId() {
    return await this.request({
      url: '/user',
      method: 'GET',
      data: null,
    })
  }

  async getInfos(id) {
    return await this.request({
      url: `/user/${id}`,
      method: 'GET',
      data: null,
    })
  }

  async list(page = 1) {
    return await this.request({
      url: '/users?page=' + page,
      method: 'GET',
      data: null,
    })
  }

  async addAbility(userId, payload) {
    return await this.request({
      url: `/user/${userId}/abilities`,
      method: 'POST',
      data: payload,
    })
  }

  async removeAbility(userId, payload) {
    return await this.request({
      url: `/user/${userId}/abilities`,
      method: 'DELETE',
      data: payload,
    })
  }

  async listAllowedUsers(params) {
    return await this.request({
      url: '/user/ability/',
      method: 'GET',
      params,
    })
  }

  async search(query, page = 1) {
    return await this.request({
      url: '/users?q=' + query + '&page=' + page,
      method: 'GET',
      data: null,
    })
  }

  async getCompany(id) {
    return await this.request({
      url: `/company/${id}`,
      method: 'GET',
      data: null,
    })
  }

  async addUser(data) {
    return await this.request({
      url: `/user`,
      method: 'POST',
      data,
    })
  }

  async stripePortal(id) {
    return await this.request({
      url: `/user/${id}/stripePortal`,
      method: 'GET',
      data: null,
    })
  }

  async forgotPassword(payload) {
    return await this.request({
      url: `/emailForgotten`,
      method: 'POST',
      data: payload,
    })
  }

  async resetPassword(id) {
    return await this.request({
      url: `/user/${id}/resetPassword`,
      method: 'POST',
      data: null,
    })
  }

  async updateUserPassword(id, token, password) {
    return await this.request({
      url: `/user/${id}`,
      method: 'put',
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: { password: password },
    })
  }

  async update(id, payload) {
    return await this.request({
      url: `/user/${id}`,
      method: 'PUT',
      data: payload,
    })
  }

  async deleteUser(id) {
    return await this.request({
      url: `/user/${id}`,
      method: 'DELETE',
    })
  }

  async assignPermission(payload) {
    return await this.request({
      url: `/permissions/grant`,
      data: payload,
      method: 'POST',
    })
  }

  async SSOLoginSuccess(payload) {
    return await this.request({
      url: `sso/success?key=${payload}`,
      method: 'POST',
    })
  }
}
