module.exports = {
  newPassword: 'Nouveau mot de passe',
  savePassword: 'Enregistrer',
  cancel: 'Annuler',
  required: 'Ce champs est requis',
  minChars: 'Minimum 8 caractères',
  minLowercase: 'Doit inclure au moins une lettre en minuscule',
  minUppercase: 'Doit inclure au moins une lettre en majuscule',
  minNumber: 'Doit inclure au moins un chiffre',
  newPasswordDescription:
    'Votre mot de passe doit être différent de ceux précédemment utilisés.',
  welcome: 'Bienvenue 👋',
  welcomeDescription:
    'Vous avez été invité(e) à rejoindre Lota.cloud, créez votre mot de passe pour accéder à votre espace.',
}
