module.exports = {
  sentry: {
    userFeedback: {
      buttonLabel: 'Give feedback',
      submitButtonLabel: 'Send feedback',
      cancelButtonLabel: 'Cancel',
      formTitle: 'What do you think about this page?',
      nameLabel: 'Name',
      namePlaceholder: 'Your name',
      emailLabel: 'Email',
      emailPlaceholder: 'Your email',
      messageLabel: 'Description',
      messagePlaceholder: 'Your feedback...',
      successMessageText: 'Thank you for your feedback!',
    },
  },
}
