module.exports = {
  carousel_next: 'Next',
  carousel_prev: 'Previous',
  carousel_done: 'Done',
  carousel_contact: 'contact our customer service',
  carousel_news: 'Stay informed of news',
  carousel_create: 'Create an account',
  message_0:
    'You are Cloud manager of one or more accounts for your company and you have questions to improve its management? Our platform can help you.<br /><br />This guide will allow you to better understand the different features available on Lota.cloud.<br /><br />Promised, it will be very fast: only a few clicks to get the tool right. You can then navigate freely on the platform.<br /><br />The data used on this platform is fabricatedand only allows you to better understand our features.<br /><br />Good discovery.',
  message_1:
    'This is the main page of our platform. When you log in, you arrive directly on this screen. It allows you to do a <b>quick analysis</b> of your situation.<br /><br />You can get a view of your expenses at 7, 14, 30, 60, 90 days by clicking on the <b>date picker</b> at the top right of the screen.<br /><br />You can also find the <b>variation in your consumption</b>, as well as the breakdown of your <b>expenses by supplier/category/product</b>. At the bottom of the page.',
  message_2:
    'Here, you will be able to understand your main spending items, detect anomalies and identify solutions adapted to your consumption differences.<br /><br />Here you can <b>filter your costs</b> based on several criteria, get a <b>dynamic graph</b> and delve deeper into the granularity of your data.',
  message_3:
    'On this screen, you can <b>find all your invoices in just a few clicks</b>. You can filter by supplier, month and year.<br /><br />This functionality allows you to <b>gain peace of mind</b>: we keep all your billing history, without time limit. A doubt: Goin your Lota.cloud invoicing tab!',
  message_4:
    'Do you want even more serenity? Forget the anxiety of daily visits to your dashboard: thanks to our alert system, you are <b>notified in real time of any budget overrun</b>.<br /><br />Set up your alerts in a <b>personalized</b> way and <b>receive alerts</b> by email or directly on the platform.<br /><br />You can define the <b>maximum threshold not to be exceeded, the period and the scope to control</b>. A graph also allows you to visualize the evolution of your consumption according to your threshold.',
  message_5:
    "We warned you: <b>after only a few clicks, you are ready to tame your Cloud spending!</b><br /><br />You just hada look aroundthe main features of our platform. We hope that this quick tour of the owner has enabled you to understand how our 100% FinOps tool works. It is now time to embark on the Lota.cloud adventure!<br /><br />Please be aware that we are continuously developing new features. You can subscribe to our newsletter 'the Chronicles of the Cloud' so you are sure tonot miss anything about the evolution of our tool!<br /><br />Good use!",
  title_0: 'Hello and welcome',
  title_1: 'The dashboard',
  title_2: 'Explorer',
  title_3: 'Your invoices',
  title_4: 'Alerting system',
  title_5: 'Thank you',
}
