module.exports = {
  usageHours: 'Usage Hours',
  cost: 'Cost in USD',
  columnSerieName: 'Total expenses',
  seriesName: 'represents',
  columnPrices: 'Value: ',
  yAxisTitle: 'Values($ US)',
  explorerTitleUSD: 'Total cost in US DOLLARS ($)',
  explorerTitleEUR: 'Total cost in EUROS (€)',
  column: 'Column',
  bar: 'Bar',
  pie: 'Pie',
  areaspline: 'Area',
  spline: 'Line',
  dayGranularity: 'Day',
  weekGranularity: 'Week',
  monthGranularity: 'Month',
  quarterGranularity: 'Quarter',
  yearGranularity: 'Year',
}
