module.exports = {
  search: 'Rechercher',
  create: 'Créer une alerte',
  check: 'Consulter mes alertes',
  name: "Nom de l'alerte",
  rule: 'Règle',
  triggeredOn: 'Déclenchée le ',
  status: 'Statut',
  toAcquit: 'Acquitter',
  running: 'en cours',
  paused: 'en pause',
  triggered: 'déclenchée',
  cancelled: 'annulée',
  acquitted: 'acquittée',
  toPause: 'Mettre en pause',
  toModify: 'Modifier',
  toDelete: 'Supprimer définitivement',
  toCancel: 'Annuler',
  alarm: 'Alarme',
  activatedText:
    'Vous recevrez une notification quand cette alarme sera déclenchée',
  cancelledText: 'Vous ne recevrez plus de notification de cette alarme',
  pausedText:
    "Vous ne recevrez plus de notification de cette alarme jusqu'à réactivation",
  acquittedText:
    'Vous ne recevrez plus de notification de cette alarme pendant ',
  sevenDays: '7 jours',
  thirtyDays: '30 jours',
  until: "jusqu'au: ",
  type: "Type d'alerte",
  target: 'Cible(s)',
  periodicity: 'Périodicité',
  range: 'Sélectionnez une période',
  amount: 'Montant',
  from: 'Du',
  to: 'Au',
  sumUp: 'Récapitulatif',
  confirm: "Voulez vous vraiment supprimer l'alerte: %s",
  toReactivate: 'Réactiver',
  showAutoGenerated: 'Afficher les alertes auto-générées',
  createdAt: 'Créée le ',
}
