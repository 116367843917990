module.exports = {
  _action_succeed: 'Action réalisée avec succès',
  _actions: 'Actions',
  _alert_level: 'Analyse',
  _alert_list: 'Liste des alertes',
  _alert_name: "Nom de l'alerte",
  _alert_rule: 'Règle',
  _alert_value: 'Valeur',
  _back: 'Retour',
  _category: 'Catégorie',
  _checkboxMail: 'Recevoir mes alertes par mail',
  _create: 'Créer',
  _create_alert: 'Créer une alerte',
  _defineADate: "Veuillez définir une date d'expiration",
  _delete: 'Supprimer',
  _doing: 'En cours',
  _done: 'Fait',
  _end_date: 'Date de fin',
  _filters: 'Filtres',
  _keepAlertUp:
    "Cliquez si vous souhaitez définir une date d'expiration de l'alerte",
  _location: 'Cible',
  _manage_alert: 'Gérer les alertes',
  _modify: 'Modifier',
  _modify_alert: "Modifier l'alerte",
  _period: 'Fin',
  _picker_in_menu: 'Sélection date',
  _product: 'Produit',
  _provider: 'Fournisseur',
  _region: 'Region',
  _resource: 'Ressource',
  _scope: "Champ d'application",
  _scope_value: 'Recherche (cible)',
  _search: 'Rechercher une alerte',
  _standby: 'En pause',
  _update: 'Mettre à jour',
  alertMe: 'Alertez-moi lorsque le coût de',
  alertMeDay: 'sur une journée dépasse',
  alertMeMonth: 'sur le mois dépasse',
  alertMeWeek: 'sur la semaine dépasse',
  alertNameMobile: "Nom de l'alerte : ",
  alertRuleMobile: 'Règle : ',
  alertRuleValueMobile: 'Supérieur à',
  alertStatusMobile: "Statut de l'alerte : ",
  alertTargetMobile: 'Cible : ',
  alertValueMobile: 'Valeur : ',
  deleteAlert: "Supprimer l'alerte",
  doing: 'En cours',
  done: 'Terminée',
  editAlert: "Modifier l'alerte",
  endDateMobile: 'Date de fin : ',
  expired: 'Expirée',
  explorer: "Créer une alerte via l'explorateur",
  graph: "Voir l'évolution",
  graphNoData: 'Aucune donnée pour le moment',
  graphTarget: 'Alerte',
  graphTitle: 'Evolution',
  graphValue: 'Valeur',
  moreOption: "Plus d'options",
  pauseAlert: 'Mettre en pause',
  rule_percentage: 'Supérieur (Pourcentage)',
  rule_superior: 'Supérieur (Valeur)',
  scan: 'Analyse',
  scheduled: 'Programmée',
  start: 'Début',
  startAlert: "Activer l'alerte",
  superior: 'Montant global supérieur',
  superiorDay: 'Montant journalier supérieur',
  superiorWeek: 'Montant hebdomadaire supérieur',
  name: 'Nom',
}
