module.exports = {
  apiKey: "Clé d'API",
  apiSecret: 'Clé secrète',
  back: 'Retour',
  beaconActivation: 'Activer les balises',
  bucketName: 'Nom du compartiment (Bucket)',
  bucketRegion: 'Région du compartiment',
  categories: 'Catégories',
  category: 'Catégorie',
  company_address: "Adresse de l'entreprise",
  company_billing_address: 'Adresse de facturation',
  company_name: "Nom de l'entreprise",
  company_phone: 'Numéro de téléphone',
  connectorName: 'Nom du connecteur',
  connectorReport: 'Nom du rapport',
  country: 'Pays',
  currency: 'Devise',
  dateRange: 'Période',
  email: 'Adresse de courriel principale',
  email_confirmation: "Confirmation d'e-mail",
  endingDate: 'Date de fin',
  filters: 'Filtres',
  firstname: 'Prénom',
  from: 'du',
  headers: 'Colonnes',
  language: "Langue de l'interface",
  days: 'jours',
  last14days: '14 derniers jours',
  last30days: '30 derniers jours',
  last60days: '60 derniers jours',
  last7days: '7 derniers jours',
  last90days: '90 derniers jours',
  lastMonth: 'Le mois dernier',
  lastname: 'Nom',
  mailConfirmationCode: "Code d'activation",
  month: 'Mois',
  monthHint: 'Rechercher le mois',
  option: 'Option',
  password: 'Mot de passe',
  passwordRecovery:
    'Veuillez indiquer votre adresse e-mail, nous vous ferons parvenir un lien pour changer votre mot de passe.',
  passwordRecoveryTitle: 'Récupération de mot de passe',
  passwordUpdateNewPass: 'Nouveau mot de passe : ',
  password_confirmation: 'Confirmation du mot de passe',
  period: 'Période',
  product: 'Produit',
  products: 'Produits',
  provider: 'Fournisseur',
  providerHint: 'Rechercher un fournisseur',
  providers: 'Fournisseurs',
  region: 'Région',
  usageAccountId: 'ID de compte',
  reportName: 'Nom du rapport',
  resetFilters: 'Réinitialiser les filtres',
  searchConnector: 'Rechercher un connecteur',
  search_a_user: 'Rechercher un utilisateur',
  startingDate: 'Date de début',
  themeDark: 'Mode sombre',
  thisMonth: 'Ce mois-ci',
  thisWeek: 'Cette semaine',
  to: 'au',
  year: 'Année',
  yearHint: "Rechercher l'année",
  groupBy_resourceId: 'Ressource',
  groupBy_product: 'Produit',
  groupBy_category: 'Catégorie',
  groupBy_region: 'Région',
  groupBy_usageAccountId: 'ID de compte',
  groupBy: 'Grouper par',
  resourceId: 'ID de ressource',
  noTags: 'Sans étiquette',
  offer: 'Offre',
  searchByProvider: 'Rechercher par fournisseur',
  welcomeLogin: 'Veuillez vous connecter pour accéder à votre espace.',
  resetPassword: 'Réinitialisation du mot de passe',
  betaMode: 'Mode béta',
  loginError: 'Erreur de connexion, veuillez vérifier vos identifiants.',
}
