import PipelineService from '@/services/pipeline'
import DataScopeService from '@/services/dataScope'

const state = () => ({
  scope: {
    filters: {},
    name: null,
  },
  blocks: [],
  filters: [],
  scopes: [],
})

const getters = {
  scope: (state) => {
    return state.scope
  },
  scopeName: (state) => {
    return state.scope.name
  },
  scopes: (state) => {
    return state.scopes
  },
  scopeAsJson: (state) => {
    return JSON.stringify(state.scope.filters)
  },
  blocks: (state) => {
    return state.blocks
  },
  filters: (state) => {
    return state.filters
  },
  filtersCount: (state) => {
    return state.blocks.length
      ? state.blocks.reduce((acc, cur) => (acc += cur.operators.length), 0)
      : 0
  },
  filtersDescription: (state) => {
    const filters = state.blocks.length
      ? state.blocks.reduce((acc, cur) => {
          let values = cur.operators.reduce((_acc, _cur) => {
            let _values = _cur.values
            if (!Array.isArray(_values)) {
              _values = [_values]
            }
            _acc = _acc.concat(_values)
            return _acc
          }, [])
          acc = acc.concat(values)
          return acc
        }, [])
      : []

    return filters.length ? `${filters.join(', ').substring(0, 25)}...` : null
  },
}

const actions = {
  setScope: ({ commit }, payload) => {
    commit('SET_SCOPE', payload)
  },
  addBlock: ({ commit }, block = null) => {
    if (!block) {
      block = { operator: 'and', operators: [], hidden: false }
    }
    commit('SET_BLOCK', block)
  },
  addBlocks: ({ commit, dispatch }, blocks = null) => {
    if (!blocks) {
      blocks = [{ operator: 'and', operators: [], hidden: false }]
      return commit('SET_BLOCKS', blocks)
    }
    commit('SET_BLOCKS', blocks)
  },
  closeBlocks: ({ commit }) => {
    commit('CLOSE_BLOCKS')
  },
  async fetchFilters({ commit, rootState, getters }, name) {
    const payload = {
      from: getters.startDate,
      to: getters.endDate,
      group: name,
      scope: getters.scopeAsJson,
    }
    const ps = new PipelineService()
    const data = await ps.aggregate('explorer/get_scope', payload)

    commit('SET_FILTERS', data)
  },
  async saveScope({ dispatch }, scope) {
    const ds = new DataScopeService()

    const payload = {
      name: scope.name,
      blocks: scope.blocks,
      type: 'default',
      source: 'cloud',
    }
    // update scope if exists
    if (scope.id) {
      await ds.update(scope.id, payload)
    } else {
      // else create new one
      await ds.create(payload)
    }
    // reload scopes
    await dispatch('getScopes')
  },
  async getScopes({ commit }) {
    const ds = new DataScopeService()
    const data = await ds.list()

    if (data && data.length) {
      commit('SET_SCOPES', data)
    }
  },
  async deleteScope({ commit }, { id }) {
    const ds = new DataScopeService()
    const res = await ds.delete(id)

    if (res) {
      commit('DELETE_SCOPE_BY_ID', id)
    }
  },
  async resetFilters({ dispatch, getters }) {
    await dispatch('addBlocks')
    await dispatch('setScope', {
      filters: getters.blocks,
      name: null,
    })
  },
}

const mutations = {
  SET_SCOPE(state, payload) {
    state.scope = {
      filters: payload.filters,
      name: payload.name,
    }
  },
  SET_SCOPES(state, payload) {
    state.scopes = payload
  },
  SET_BLOCK(state, payload) {
    state.blocks.push(payload)
  },
  SET_BLOCKS(state, payload) {
    state.blocks = payload
  },
  CLOSE_BLOCKS(state) {
    state.blocks = state.blocks.map((b) => ({ ...b, hidden: true }))
  },
  SET_FILTERS(state, payload) {
    state.filters =
      payload && payload.length
        ? payload.map((f, idx) => ({ ...f, idx: idx }))
        : []
  },
  DELETE_SCOPE_BY_ID(state, payload) {
    state.scopes = state.scopes.filter((s) => s.id !== payload)
  },
}

export default {
  namespaced: false,
  state,
  getters,
  actions,
  mutations,
}
