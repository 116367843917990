module.exports = {
  addNewConnector: 'Add a new connector',
  applyFilters: 'Apply filters',
  back: 'Back',
  cancel: 'Cancel',
  close: 'Close',
  continue: 'Continue',
  createUserShare: 'Share explorer',
  demo: 'Test (Demo)',
  download: 'Download data',
  failure: 'Failure',
  getMyPassword: 'Recover my access',
  changeMyPassword: 'Change my password',
  help: 'Help',
  helpVideo: 'Help video',
  logIn: 'Login with a password',
  next: 'Next',
  returnToHomePage: 'Back to home page',
  save: 'Save',
  selectPicture: 'Select a picture',
  showInExplorer: 'Show in Explorer',
  share: 'Share',
  signUp: 'Sign up',
  success: 'Success',
  testConnector: 'Test the connector',
  update: 'Update',
  validate: 'Validate',
  costAndUsage: 'Page report, cost and usage',
  seeUsages: 'See usages',
  csvExport: 'export data to CSV',
  pdfExport: 'export chart to PDF',
  generateShareLink: 'Generate a share Link',
  export: 'export',
  addUser: 'Add a new user',
  createBudgetGroup: 'create budget group',
  createBudget: 'create budget',
  deleteBudget: 'Delete this budget ',
  shareBudget: 'Share this budget ',
  updateBudget: 'Update this budget ',
  confirm: 'Confirm',
  delete: 'Delete',
  seeInExplorer: 'See in explorer',
  seeResources: 'See resources',
  revoke: 'Revoke',
  exportChart: 'Export chart data',
  exportTable: 'Export table data',
  resetPassword: 'Reset password',
  createRecommendation: 'Create a recommendation',
  deleteRecommendation: 'Delete this recommendation',
  modifyRecommendation: 'Modify this recommendation',
  modify: 'modify',
  createRecommendationGroup: 'Create a recommendation group',
  deleteRecommendationGroup: 'Delete this recommendation group',
  deleteDashboard: 'delete this dashboard',
  shareDashboard: 'Share this dashboard',
  authorize: 'Authorize',
  sendByEmail: 'Send by email',
  shareRecommendation: 'Share this recommendation',
  showActivties: 'Activities',
  detachTile: 'Detach this tile',
  seeBudget: 'See this budget',
  previous: 'Previous',
  renew: 'Renew',
  renewalBudgets: 'Renewal budgets',
  logInSingleSignOn: 'Login with SSO',
}
