const state = () => ({
  id: null,
  name: '',
  billing_address: '',
  phone: '',
  currency: localStorage.getItem('currency') || 'EUR',
  importStatus: 'done',
})

const getters = {
  company: (state) => {
    return state
  },
  status: (state) => {
    return state.importStatus
  },
}

const mutations = {
  setCompanyInfo: (state, payload) => {
    state.name = payload.name
    state.billing_address = payload.billing_address
    state.phone = payload.phone
    state.currency = payload.currency
  },
  setCurrency: (state, payload) => {
    state.currency = payload
  },
  SET_IMPORT_STATUS: (state, payload) => {
    state.importStatus = payload
  },
}

const actions = {
  setImportStatus({ commit }, payload) {
    commit('SET_IMPORT_STATUS', payload)
  },
}

export default {
  namespaced: false,
  state,
  getters,
  mutations,
  actions,
}
