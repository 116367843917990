module.exports = {
  unusedResourcesList: 'Unused resources',
  unusedResourcesClosingList: 'Unused resources being closed',
  unusedResourcesClosedList: 'Unused resources closed',
  unusedResources: 'Unused resources',
  unusedResourcesClosing: 'Resources being closed',
  unusedResourcesClosed: 'Closed resources',
  createRecommendation: 'create a recommendation',
  lastScanOn: 'Last scan on',
  selectionDescription: 'Select the resources to close and click on ',
  noUnusedResourcesFound:
    'We have not detected any unused resources on your infrastructure, congratulations!',
  noUnusedResourcesClosingFound: 'You have no resources being closed.',
  noUnusedResourcesClosedFound: 'You have no resources closed.',
  noOversizedResourcesFound:
    'We have not detected any over-sized resources on your infrastructure, congratulations!',
  noOversizedResourcesClosingFound: 'You have no resources being resized.',
  noOversizedResourcesClosedFound: 'You have no resources resized.',
  noResults: 'No result for your search',
  featureTitle: 'Optimization',
  summary: 'Summary',
  potentialSavings: 'Potential savings',
  actualSavings: 'Actual savings',
  searchResources: 'Search a resource',
  overSizedResources: 'Oversized resources',
  reservedInstances: 'Reserved instances',
  documentation: 'Documentation',
  savings: 'Savings',
  associatedRecommendations: 'Associated recommendations',
  reasons: 'Reasons',
  monthlyCost: 'Monthly cost',
  perMonth: '30 days',
  perQuarter: '3 months',
  perSemester: '6 months',
  perYear: '12 months',
  notAssigned: 'Not assigned yet',
  assigned: 'Assigned within a recommendation',
  verified: 'Verified recommendation',
  month: 'month',
  unusedResourcesRules: 'Optimization Rules (unused resources)',
  potentialAndRealizedSavings: 'Achievable and realized savings',
  oversizedResourcesSavingsTitle: 'Savings calculation',
  oversizedResourcesSavingsDescription:
    'Savings are estimated at an average reduction of 45% when resizing an instance. We are working on integrating our price catalog to suggest the best alternatives for resizing an instance, and to help you calculate the savings more accurately.',
  oversizedResourcesRulesTitle: 'Oversize rule',
  oversizedResourcesRulesDescriptionCPU:
    'Our scanner identifies resources whose CPU utilization has averaged less than 40% maximum per day over the last 30 days.',
  oversizedResourcesRulesDescriptionNetwork:
    'Our scanner identifies resources with a maximum average network size of less than 1MB per day over the last 30 days.',
}
