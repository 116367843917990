module.exports = {
  compute: 'Calcul',
  database: 'Base de données',
  storage: 'Stockage',
  network: 'Réseau',
  services: 'Services fournisseurs',
  others: 'Autres',
  applications: 'Applications',
  web: 'Hébergement Web',
  categoriesConfiguration: 'Configuration des catégories',
  categoriesUpdate: 'Mettre à jour les catégories',
}
