module.exports = {
  usEast1: 'USA Est (Virginie du Nord)',
  usEast2: 'USA Est (Ohio)',
  usWest1: 'USA Ouest (Californie du Nord)',
  usWest2: 'USA Ouest (Oregon)',
  afSouth1: 'Afrique (Le Cap)',
  apEast1: 'Asie Pacifique (Hong Kong)',
  apSouth1: 'Asie Pacifique (Mumbai)',
  apNortheast2: 'Asie Pacifique (Séoul)',
  apSoutheast1: 'Asie Pacifique (Singapour)',
  apSoutheast2: 'Asie Pacifique (Sydney)',
  apNortheast1: 'Asie Pacifique (Tokyo)',
  caCentral1: 'Canada (Central)',
  euCentral1: 'Europe (Francfort)',
  euWest1: 'Europe (Irlande)',
  euWest2: 'Europe (Londres)',
  euSouth1: 'Europe (Milan)',
  euWest3: 'Europe (Paris)',
  euNorth1: 'Europe (Stockholm)',
  meSouth1: 'Moyen-Orient (Bahreïn)',
  saEast1: 'Amérique du Sud (São Paulo)',
}
